<template>
  <div>
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(statisticData, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="statistic-title statistic-international-title">
                <div class="homepage-block-title">
                  <a class="top-nav-curr" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != AppConst.PAGE_LANG?'/'+lang:'')+'/statistic'">{{$t('statistic_cat_title')}}</a> | <a v-if="typeof(statisticData.cat) == 'object'" class="top-nav-curr" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != AppConst.PAGE_LANG?'/'+lang:'')+'/database'">{{statisticData.cat.title}}</a>
                </div>
              </div>
          <!--<pre>{{ JSON.stringify(statisticData, null, 2) }}</pre>-->
          <!--<div v-html="htmlcontent"></div>-->
          <pre>{{ JSON.stringify(chartData, null, 2) }}</pre>
          <div>

            <div class="statistic-item col-lg-12" id="">
              <div class="statistic-item-title row">
                  <div class="database-title-wrapper col-lg-12 my-auto">
                      <a href="http://csdl.vietnamtourism.gov.vn/hdv/" class="database-title" target="_blank">Hướng dẫn viên</a>
                        <a href="http://csdl.vietnamtourism.gov.vn/hdv/" class="search-more" target="_blank">Tra cứu</a>
                    </div>
                </div>
              <div class="statistic-item-content row">
                  <div class="statistic-chart col-lg-7 col-md-12 col-sm-12 col-12">
                        <div id="canvas-holder" style="width:100%">
                            <canvas id="chart-area"></canvas>
                        </div>
                    </div>
                    <div class="database-chart-legend legend-con col-lg-5 col-md-12 col-sm-12 col-12" id="chart-hdv-legend">
                    </div>
                    
                </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, getCurrentInstance} from "vue";
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  setup() {
    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    return{
      baseUrl
    }
  },
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      htmlcontent: '',
      statisticyear: 2022,
      yearFilter: [],
      statisticData: [],
      chartData: [],
      statisticCode: '',
      monthList: [],
      sYear: '',
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang')
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  created() {
    //var dataUrl = this.baseUrl+'/StatisticGen/ receipts';
    /*
    var dataUrl = 'http://demogov.leanhtuan.work/DatabaseGen/database';
    var param = { "code": this.statisticCode };
    this.getStatisticData(dataUrl, param);
    */

    //var staticUrl = this.baseUrl+"/getStatistic/ receipts"
    //var staticUrl = "http://demogov.leanhtuan.work/DatabaseGen/database"
    //this.getStatisticAvaiable(staticUrl, param);

    /*
    var dataUrls = [{'url':'http://quanlyluhanh.vn/quanly/data.json'},{'url':'http://www.huongdanvien.vn/quanly/data.json'}];
    this.chartData = this.getDataFromURL(dataUrls);
    */
    
    var dataUrl = this.AppConst.SECOND_API_URL+'DatabaseGen/getData';
    var param = {};
    this.getChartData(dataUrl, param);

  },
  computed: {},
  onMounted() { },
  mounted() {
    
  },
  watch: {
    htmlcontent: {
             handler: function() {
                 this.$nextTick(() => {
                  

                });
           },
            deep: true
       },
     statisticData: {
             handler: function() {
                 this.$nextTick(() => {
                  //var sCode = (this.statisticCode).substring(0,2);
                  this.yearFilter = Object.prototype.hasOwnProperty.call(this.statisticData, "statistic") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic, "statistic") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic.statistic, "years")
                  ?this.statisticData.statistic.statistic.years:[];
                });
           },
            deep: true
       },
      
  },
  methods: {
    getChartData(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.chartData = response.data;
          }
        },
        (error) => {
          this.chartData =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getDataFromURL(urls){
      var data = PublicService.getUrls(urls, {});
      data.then(
        (response) => {
          //console.log('chart data:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            //this.htmlcontent = response.data;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );

    },
    getStatisticData(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.htmlcontent = response.data;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getStatisticAvaiable(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.keys(response.data).length>0) {
            this.statisticData = response.data;
            //var sCode = (this.statisticCode).substring(0,2);
            //var sYear = document.getElementById('statistic-year').value;
            //var sYear = response.data.statistic.statistic.info.year;

            this.sYear = this.statisticData.statistic.statistic.info.year;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    changePeriod(event){
      //var year = document.getElementById('statistic-year').value;
      //var period = document.getElementById('statistic-month').value;
      //var period = event.target.value;
      //alert(this.$router.currentRoute);
      console.log(event);
      //this.$router.push('/database/receipts?year='+year);
      
      var dataUrl = 'http://demogov.leanhtuan.work/DatabaseGen/database';
      //var param = { "code": this.statisticCode, "year": year};
      var param = {};
      this.getStatisticData(dataUrl, param);
    },
    dict_reverse(obj) {
      var new_obj= {}
      var rev_obj = Object.keys(obj).reverse();
      rev_obj.forEach(function(i) { 
        new_obj[i] = obj[i];
      })
      return new_obj;
    },
    naturalCompare(a, b) {
        var ax = [], bx = [];

        a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
        
        while(ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if(nn) return nn;
        }

        return ax.length - bx.length;
    },
    handlePageChange(event, page) {
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if (Object.keys(param).length > 0) {
        if (typeof (param.page) == 'string') {
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if (paramStr.length > 0) {
          paramStr = '&' + paramStr;
        }
      }
      this.$router.push("?page=" + page + paramStr);
      //this.$router.go(this.$router.currentRoute)
      this.$emit('content-page-clicked', page);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
  },
};

</script>

<style>
.statistic-chart
{
	min-height:20vh;
	}
.statistic-item {
    border: 1px solid #c0c0c0;
    margin-bottom: 20px;
    padding: 0px 15px 15px 15px;
}
.statistic-item-title {
    background-color: #227bbf;
    padding: 15px 0px;
}
.statistic-item-title a{
    color:#fff;
	font-size:12pt;
}
.statistic-item-title a:hover{
    color:#fff;
}
.database-chart-legend {
    padding-top: 40px;
}


.canvas-con {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 365px;
  position: relative;
}

.canvas-con-inner {
  height: 100%;
}

.canvas-con-inner, .legend-con {
  display: inline-block;
}

.legend-con {
  font-family: Roboto;
  display: inline-block;
}
.legend-con .legend-title-wrapper {
  list-style: none;
}
.legend-con .legend-title {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.legend-con .legend-title span {
  display: inline-block;
}
.legend-con .legend-title span.chart-legend {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

@media screen and (max-width: 1024px)
{
	
	.apexcharts-legend.position-bottom.center, .apexcharts-legend.position-top.center {
		justify-content: flex-start !important;
		width:auto !important;
	}
}
@media screen and (min-width: 1024px)
{
	.apexcharts-legend {
		padding-left: 50px !important;
	}
}
.search-more
{
	float:right;
	}
.search-more {
    float: right;
    color: #fff !important;
    background-color: #f68d03;
    border-color: #f68d03;
    font-size: 10pt !important;
    border: 1px solid transparent;
    padding: 5px;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 10px;
    -webkit-appearance: button;
    text-decoration: none !important;
}
</style>