<template>
  <div>
    <!--<pre>{{ JSON.stringify(breadCrumb, null, 2) }}</pre>-->
    <div v-if="breadCrumb.length>0" class="container">
      <nav class="page-top-breadcrumb" aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-flat">
          <li class="breadcrumb-item"><a :href="baseUrl+(lang!=this.AppConst.PAGE_LANG? '/' + lang : '')" target="_self">{{$t('breadcrumb_home')}}</a></li>
          <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
          <template v-for="(nav, index) in breadCrumb"
          :key="index"
          >
            <li :class="nav.code==code?'breadcrumb-item active':'breadcrumb-item'">
              <a v-if="typeof(nav.link)=='string' && (nav.link).length>0" :href="nav.link" target="_self">{{nav.title}}</a>
              <a v-else :href="this.genCatLink(nav.type, nav.code)" target="_self">{{nav.title}}</a>
            </li>
          </template>
          <!-- eslint-enable -->
        </ol>
      </nav>
      
      
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";
//import { defineComponent, computed, reactive, ref, getCurrentInstance } from 'vue'
import { defineComponent, ref, getCurrentInstance } from 'vue'
import { reverse } from "lodash";
//import { defineComponent } from 'vue'
//import { computed, reactive } from 'vue'

export default defineComponent({
  //name: "HomeCmp",
  props: ["code", "type"],
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    //console.log(props);
    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    return{
      baseUrl
    }
    //const app= getCurrentInstance()
    //const siteMeta = app.appContext.config.globalProperties.siteMeta
    //const gPro = app.appContext.config.globalProperties;    
    /*
    function genCatLink(type, id) {
      return FunctionService.genCatLink(type, id);
    }
    function genSubCatLink(type, id) {
      return FunctionService.genSubCatLink(type, id);
    }
    function getImages(str, imgUrl, pos) {
      return FunctionService.getImages(str, imgUrl, pos);
    }
    function displayEntity(str) {
      return FunctionService.displayEntity(str);
    }
    function removeHTML(data) {
      return FunctionService.removeHTML(data);
    }
    */

    /*
    function linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    }
    function displayHtmlContent(data) {
      return FunctionService.displayHtmlContent(data);
    }
    function shorten(str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    }
    function paddZero(number) {
      return FunctionService.paddZero(number);
    }
    function postTypeRoute(type) {
      return FunctionService.postTypeRoute(type);
    }
    function displayShortenEntityContent(content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    }
    function formatTime(time, locale) {
      return FunctionService.formatTime(time, locale);
    }
    */

  },
  data() {
    return {
      breadCrumb: [],
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang'),
    };
  },
  created(){
    this.getBreadCrumb(this.code, this.type, []);
    //this.getBreadCrumb(this.code, this.type, {cattype:this.$route.name});
  },
  computed: {},
  onMounted() {},
  mounted() {},
  watch:{
    
  },
  methods: {
    getBreadCrumb(code, type, param) {
      PublicService.getBreadCrumb(code, type, param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.breadCrumb = reverse(response.data);
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    genCatLink(type, id) {
      return FunctionService.genCatLink(type, id);
    },
    /*
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl, pos) {
      return FunctionService.getImages(str, imgUrl, pos);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    */
  },
});
</script>

<style></style>
