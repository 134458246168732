<template>
  <section
    v-if="posts.length"
    class="section-wrapper section-banner-wrapper section-banner-wrapper-v2"
    :style="[
      componentData.data.bgColor.length > 0
        ? { 'background-color': componentData.data.bgColor }
        : {},
    ]"
  >
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.parse(parentData.data)['data'] }}</pre>-->
    <div v-if="componentData.data.headline.length > 0" class="row">
      <div class="col text-center">
        <h1 class="section-wrapper-title section-banner-wrapper-title">
          {{ componentData.data.headline }}
        </h1>
      </div>
    </div>
    <div v-if="posts.length" class="section-banner-container" :style="!this.checkCookie('displayGreetingCookie')?'':'display:none;'">
      <div v-if="posts.length>1" class="home-multiple-greeting-wrapper" :id="'home-multiple-greeting-wrapper-'+componentData.id">
        <section :id="'home-multiple-greeting-'+componentData.id">
          <div
            :id="'owl-list'+componentData.id"
            class="owl-list owl-theme owl-carousel"
            style="height: auto;" 
          >
            <!--<pre>{{ JSON.stringify(post, null, 2) }}</pre>-->
            <BannerItem v-for="(post, index) in posts"
              :postitem="post"
              :key="index"
              @hasVideo="updateHasVideo"
            ></BannerItem>

          </div>
        </section>
        <div class="home-multiple-greeting-close-btt">
          <a href="#" class="home-multiple-greeting-close-btt-link" :id="'home-multiple-greeting-close-btt-link-'+componentData.id" :data-elm="'home-multiple-greeting-wrapper-'+componentData.id">
            <i class="fa fa-times-circle-o" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div v-else>
        <div class="home-single-greeting-wrapper" :id="'home-single-greeting-wrapper-'+componentData.id">
          <div class="home-single-greeting-item" :style="'background-image: url(\''+getImages('',posts[0].images)+'\');'">
            <div class="home-single-greeting-item-content" v-if="(posts[0].content).length">
              <div class="home-single-greeting-item-content-inner" v-html="posts[0].content"></div>
            </div>
            <div class="home-single-greeting-close-btt">
              <a href="#" class="home-single-greeting-close-btt-link" :id="'home-single-greeting-close-btt-link-'+componentData.id" :data-elm="'home-single-greeting-wrapper-'+componentData.id">
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              </a>
            </div>
            <div class="home-single-greeting-not-display">
              <label for="not-display-again"><input type="checkbox" id="not-display-again" class="not-display-again"/> Không hiển thị lại trong ngày hôm nay</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>
import "owl.carousel";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import BannerItem from "./cmpsItem/HomeGreetingItemCmp";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      parentData: [],
      parentBlockData: [],
      hasVideo: 0,
    };
  },
  computed: {},
  watch: {
    hasVideo(){
      //alert(this.hasVideo);
      jQuery(".owl-list").owlCarousel('destroy'); 
    }
  },
  components: {
    BannerItem,
  },
  methods: {
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;
                  this.parentBlockData = typeof(this.parentData.data)=='string'?JSON.parse(this.parentData.data):[];

                  this.$nextTick(function () {
                      this.displayGreeting('displayGreetingCookie', 'displayed', 1);
                      this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case -1:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.displayGreeting('displayGreetingCookie', 'displayed', 1);
                      this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    checkHasVideo(data){
      var hasData = 0;
      if(typeof(data) == 'object'){
        data.forEach(postData => {
          if(typeof(postData.video)=='string' && (postData.video).length > 0){
            hasData = 1;
            return 1;
          }
        });
      }
      return hasData;
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length>0) {
        rs = true;
      }
      return rs;
    },
    setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        //alert(expires);
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    checkCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' '){
              c = c.substring(1,c.length);
              console.log(c);
              if (c.indexOf(nameEQ) == 0){
                //return c.substring(nameEQ.length,c.length);
                return true;
              }
              else{
                //this.setCookie(name,'displayed',30);
              }
            }
        }
        return false;
    },
    eraseCookie(name) {   
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    displayGreeting(name, value, days){
        $('.home-single-greeting-close-btt-link').on('click', function(){
          if($(this).attr('data-elm').length>0){
            var id2close = $(this).attr('data-elm');
            $('#'+id2close).css('display','none');
          }
          //alert($('#not-display-again').is(':checked')?'true':'false');
          //alert($('#not-display-again').length);
          if($('#not-display-again').length>0 && $('#not-display-again').is(':checked')){
            //this.setCookie('displayGreeting','displayed',1);
            var expires = "";
            if (days) {
                var date = new Date();
                var nextDate = new Date();
                nextDate.setDate(date.getDate() + 1);

                let year = nextDate.getFullYear();
                let month = (1 + nextDate.getMonth()).toString().padStart(2, '0');
                let day = nextDate.getDate().toString().padStart(2, '0');
              
                //var nextDateStr = month + '/' + day + '/' + year;
                var nextDateStr = year + '/' + month + '/' + day + " 00:00:01 +0000";
                //alert(Math.round(new Date(nextDateStr).getTime()/1000));
                //var nextGreetingTime = Math.round(new Date(nextDateStr).getTime()/1000);

                var gmtNextDate = new Date((typeof nextDateStr === "string" ? new Date(nextDateStr) : nextDateStr).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}));   

                //var nextGreetingTime = Math.round(new Date(nextDateStr).getTime());
                var nextGreetingTime = Math.round(gmtNextDate.getTime());
                //alert(nextGreetingTime);

                //alert(Math.round(new Date("2013/09/05 15:34:00").getTime()/1000));
                //alert(Math.round(nextDate.getTime()/1000));
                //alert(nextDateStr);
                //date.setTime(date.getTime() + (days*24*60*60*1000));
                //expires = "; expires=" + date.toUTCString();

                //alert(date.getTime());

                var nextDay = new Date();
                nextDay.setTime(nextGreetingTime);
                expires = "; expires=" + nextDay.toUTCString();
            }
            //alert(expires);
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
          }
        });

        $('.home-multiple-greeting-close-btt-link').on('click', function(){
          if($(this).attr('data-elm').length>0){
            var id2close = $(this).attr('data-elm');
            $('#'+id2close).css('display','none');
          }
        });
      //if($(".firework").length && !this.checkCookie('displayGreeting')){
      if($(".firework").length){
        $('.home-single-greeting-wrapper').addClass('home-single-greeting-firework-wrapper');

        // Firework object 
        var $Firework1 = jQuery('.firework1');
        var $Firework2 = jQuery('.firework2');
        var $Firework3 = jQuery('.firework3');
        var $Firework4 = jQuery('.firework4');
        var $Firework5 = jQuery('.firework5');

        const particleCount = 60;

        // Color generate
        const randomValue = (max, min) => Math.floor(Math.random() * (max - min) + min);
        const randomColor = () => 
          `rgba(${randomValue(255, 45)}, ${randomValue(255, 45)}, ${randomValue(255, 45)}, ${randomValue(100, 50)}%)`;

        // Genterate particle
        const randomPosition = (max, min) => ({
          x: `${randomValue(max, min)}%`,
          y: `${randomValue(max, min)}%`
        });
        const createParticle = color => `radial-gradient(circle, ${color} 0.2vmin, #0000 0)`;

        // Genterate particle style
        const particleStyle = (particles, positions) => ({
          backgroundImage: particles.join(','),
          backgroundPositionX: positions.x.join(',').toString(),
          backgroundPositionY: positions.y.join(',').toString()
        });

        // Generate firework
        const createFirework = (firework, n) => {
          var particles = [];
          var positions = {
            x: [],
            y: []
          };
          
          const color = randomColor();
          for(let i = 0; i < n; i++) {
            const {x, y} = randomPosition(100, 0);
            particles.push(createParticle(color));
            positions.x.push(x);
            positions.y.push(y);
          }
          
          firework.css({
            ...particleStyle(particles, positions),
            top: `${randomValue(50, 20)}%`,
            left: `${randomValue(80, 30)}%`
          });
        };

        // Animate settings
        const duration = second => second * 1000;
        const effect = () => ({
          opacity: 1,
          width: '30vmin'
        });
        const reset = () => ({
          width: ''
        });

        const animate = (firework, time) => {
          createFirework(firework, particleCount);
          firework.delay(time).animate(effect(), duration(1.2), 'swing', () => {
            firework.css(reset());
            animate(firework, time);
          })
        };

        animate($Firework1, duration(0));
        animate($Firework2, duration(1));
        animate($Firework3, duration(2));
        animate($Firework4, duration(3));
        animate($Firework5, duration(4));
      }
    },
    initOwl() {

      this.hasVideo = this.checkHasVideo(this.posts);
      //alert(this.hasVideo);

      //console.log(owlCarousel);
      var owl = jQuery(".owl-list");
      if (owl.length > 0) {
        owl.owlCarousel({
          dots: true,
          items: 1,
          loop: true,
          //autoplay: true,
          //autoplayTimeout: 5000,
          //autoplayHoverPause: true,
          //slideSpeed: 300,
          video: true,
          videoWidth: false, // Default false; Type: Boolean/Number
          videoHeight: false, // Default false; Type: Boolean/Number
          nav: true,
          navText: [
            "<button type='button' class='btn btn-info btn-sm'>Previos</button>",
            "<button type='button' class='btn btn-info btn-sm'>Next</button>",
          ],
          animateOut: "fadeOut",
          animateIn: "fadeIn",
        });

        if(!this.hasVideo){
          //alert(this.parentBlockData.data.slideInterval)
          
          if(typeof(this.parentBlockData.data.slideInterval)=='string' && typeof(parseInt(this.parentBlockData.data.slideInterval)=='number')){
            //alert(this.parentBlockData.data.slideInterval);
            owl.data('owl.carousel').options.autoplay = true;
            owl.data('owl.carousel').options.slideSpeed = this.parentBlockData.data.slideInterval;
            owl.data('owl.carousel').options.autoplayTimeout = this.parentBlockData.data.slideInterval;
            owl.data('owl.carousel').options.autoplayHoverPause = true;
            owl.trigger( 'refresh.owl.carousel' );
          }
        }

        owl.on("changed.owl.carousel", function (event) {
          var item = event.item.index - 2; // Position of the current item
          jQuery("h1").removeClass("animated fadeInUp");
          jQuery(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h1")
            .addClass("animated fadeInUp");

          //console.log(event);
          //console.log(item);
          var current = event.item.index;
          /*
          console.log(
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length
          );
          */
          if (
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length > 0
          ) {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }

            var currentIframe = jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find("iframe");
            //console.log(currentIframe);
            if (currentIframe.length > 0) {
              var src = currentIframe.attr("src");
              console.log(src);
              currentIframe.attr("src", src + "&autoplay=1");
              currentIframe.addClass("playing-video");
            }
          } else {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }
          }
        });
      }
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    updateHasVideo(variable){
      this.hasVideo = variable;
    },
    getFormattedDate(date) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
    
      return month + '/' + day + '/' + year;
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
  },
  created() {
    //this.get();
    this.getAPIDataByType(this.componentData);
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>