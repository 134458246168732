import AppConst from '../constant'

/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
class FunctionService {
    occurrences(string, subString, allowOverlapping) {
        string += "";
        subString += "";
        if (subString.length <= 0) return (string.length + 1);

        var n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subString.length;

        while (true) {
            pos = string.indexOf(subString, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }
    setContainerStyle(cfgData) {
        var style = '';
        if (Object.prototype.hasOwnProperty.call(cfgData, 'height')) {
            if ((cfgData.height).length > 0) {
                style += 'height: ' + cfgData.height + ';';
            } else {
                style += 'display: none;';
            }
        }
        if (Object.prototype.hasOwnProperty.call(cfgData, 'minHeight')) {
            style += 'min-height: ' + cfgData.minHeight + ';';
        }
        if (Object.prototype.hasOwnProperty.call(cfgData, 'maxHeight')) {
            style += 'max-height: ' + cfgData.maxHeight + ';';
        }
        return style;
    }
    removeHTML(content) {
        let regex = /(<([^>]+)>)/ig;
        if (typeof(content) == 'string' && content.length > 0) {
            return content.replace(regex, "");
        } else {
            return content;
        }
    }
    displayEntity(str) {
        str = str.replace(/\\/g, '');
        var textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    }
    displayHtmlContent(content, limit = 0) {
        content = this.removeHTML(content);
        content = this.displayEntity(content);
        if (limit) {
            //content = this.shorten(content, limit);
            const truncate = (str, max, suffix) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
            if (content.length > 0) {
                content = truncate(content, limit, '...');
            } else {
                content = truncate(content, limit, '');
            }
        }
        return content;
    }
    displayPostContent(content) {
        //content = this.displayEntity(content);
        //content = AppConst.NO_IMAGE_HOLDER;

        //console.log(this.$store);
        //console.log(this.$router);
        //console.log(this.$AppConst);
        //console.log(AppConst);
        //console.log(this.$store.state.AppConst.CDN_URL_ALLOW);

        var CDN_URL_ALLOW = AppConst.CDN_URL_ALLOW;
        if (CDN_URL_ALLOW) {
            var CDN_URL = AppConst.CDN_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? localStorage.getItem('lang') + '/' : "vn/");
            var BASE_URL = AppConst.BASE_URL;
            //console.log('BASE_URL:');
            //console.log(BASE_URL);
            var temporaryElement = new DOMParser().parseFromString(content, "text/html");

            //var IMG_FILTER = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
            //var FILE_FILTER = [".doc", ".docx", ".pdf", ".xls", ".xlsx", ".mp4", ".flv", ".ogg", ".webm", ".mp3", ".jpg", ".jpeg", ".png", ".gif", ".bmp", ".rar", "zip"];
            var IMG_FILTER = ["jpg", "jpeg", "png", "gif", "bmp"];
            var FILE_FILTER = ["doc", "docx", "pdf", "xls", "xlsx", "mp4", "flv", "ogg", "webm", "mp3", "jpg", "jpeg", "png", "gif", "bmp", "rar", "zip", "mp3", "wav", "ogg"];

            Array.from(temporaryElement.querySelectorAll('img[src]')).forEach((img) => {
                var oldSrc = img.src;
                var oldSrcTmp = oldSrc.toLowerCase();
                if ((this.occurrences(oldSrc, BASE_URL) == 1 || this.occurrences(oldSrc, 'http') == 0) && typeof(oldSrc) == 'string' && oldSrc.length > 0) {
                    //img.removeAttribute('src');
                    // eslint-disable-next-line no-unused-vars
                    IMG_FILTER.forEach(element => {
                        if (this.occurrences(oldSrcTmp, element) > 0) {
                            //img.src = oldSrc.replace(window.location.origin, CDN_URL);
                            //img.src = oldSrc.replace(window.location.origin, CDN_URL);
                            var newImgSrc = oldSrc.replace(window.location.origin, CDN_URL);
                            if (localStorage.getItem('lang') == 'en' && this.occurrences(newImgSrc, 'english') > 0) {
                                img.src = newImgSrc.replace('english/', '/');
                            } else {
                                img.src = newImgSrc;
                            }
                        }
                    });
                    //console.log('oldSrc:' + oldSrc);
                }
            });
            Array.from(temporaryElement.querySelectorAll('a[href]')).forEach((atag) => {
                var oldSrc = atag.href;
                if ((this.occurrences(oldSrc, BASE_URL) == 1 || this.occurrences(oldSrc, 'http') == 0) && typeof(oldSrc) == 'string' && oldSrc.length > 0) {
                    //img.removeAttribute('src');
                    // eslint-disable-next-line no-unused-vars
                    FILE_FILTER.forEach(element => {
                        //atag.href = oldSrc.replace(window.location.origin, CDN_URL);
                        if (this.occurrences(oldSrc, element) > 0) {
                            var newSrc = oldSrc.replace(window.location.origin, CDN_URL);
                            if (localStorage.getItem('lang') == 'en' && this.occurrences(newSrc, 'english') > 0) {
                                atag.href = newSrc.replace('english/', '/');
                            } else {
                                atag.href = newSrc;
                            }
                        }
                    });

                    //console.log('oldSrc: ' + oldSrc);
                }
            });
            content = temporaryElement.documentElement.innerHTML;
        }

        // Responsive table

        /*
        var temporaryElement1 = new DOMParser().parseFromString(content, "text/html");
        Array.from(temporaryElement.querySelectorAll('table')).forEach((tableElm) => {
            alert(tableElm.innerHTML);
            tableElm.innerHTML = '<div class="responsive-table">' + tableElm.innerHTML + '</div>';
        });
        content = temporaryElement1.documentElement.innerHTML;
        */

        return content;
    }
    displayPostTags(tags, tagsView) {
        var tagStr = '';
        if (typeof(tags) == 'string' && tags.length > 0 && typeof(tagsView) == 'string' && tagsView.length > 0) {
            const tagsArray = tags.split(";");
            const tagsViewArray = tagsView.split(";");
            if (typeof(tagsArray) == 'object' && tagsArray.length > 0 && typeof(tagsViewArray) == 'object' && tagsViewArray.length > 0) {
                tagsArray.forEach(function callback(value, index) {
                    tagStr += '<a class="tags-item" href="' + AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/tags/' + value + '">' + tagsViewArray[index] + '</a>, ';
                });
            }
        }
        return tagStr;
    }
    getImages(str, imgUrl, pos = 0) {
        //alert(typeof(imgUrl));
        if (typeof(imgUrl) == 'string' && imgUrl.length > 0) {
            if (this.occurrences(imgUrl, 'http') > 0) {
                return imgUrl;
            } else {
                var imgSrcStr = AppConst.CDN_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? localStorage.getItem('lang') + '/' : "vn/") + imgUrl;
                if (localStorage.getItem('lang') == 'en' && this.occurrences(imgSrcStr, 'english') > 0) {
                    imgSrcStr = imgSrcStr.replace('english/', '/');
                }
                return imgSrcStr;
            }
        } else {
            if (typeof(str) == 'string' && str.length > 0) {
                var matchStr = str.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
                //console.log(typeof(matchStr));
                //console.log(matchStr);
                if (typeof(matchStr) == 'object' && matchStr != null && matchStr.length > 0) {
                    var imgSrc = matchStr.map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
                    var image = typeof(imgSrc[pos]) == 'string' ? imgSrc[pos] : (imgUrl.length > 0 ? imgUrl : AppConst.NO_IMAGE_HOLDER);
                    if (this.occurrences(image, 'http') > 0) {
                        return image;
                    } else {
                        //alert(imgSrc[0]);
                        //console.log(typeof(imgSrc[0]));
                        var imgSrcStr1 = AppConst.CDN_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? localStorage.getItem('lang') + '/' : "vn/") + imgSrc[pos];
                        if (localStorage.getItem('lang') == 'en' && this.occurrences(imgSrcStr1, 'english') > 0) {
                            imgSrcStr1 = imgSrcStr1.replace('english/', '/');
                        }
                        return imgSrcStr1;
                    }
                } else {
                    return AppConst.NO_IMAGE_HOLDER;
                }

            } else {
                return AppConst.NO_IMAGE_HOLDER;
            }
        }
    }
    getVideo(videoUrl, opt = 0) {
        var rs = videoUrl;
        if (typeof(videoUrl) == 'string' && videoUrl.length > 0) {
            if (this.occurrences(videoUrl, 'http') > 0) {
                rs = videoUrl;
            } else {
                if (opt == 0) {
                    rs = AppConst.CDN_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? localStorage.getItem('lang') + '/' : "vn/") + videoUrl;
                    if (localStorage.getItem('lang') == 'en' && this.occurrences(rs, 'english') > 0) {
                        rs = rs.replace('english/', '/');
                    }
                }
            }
        }
        return rs;
    }
    getAudio(audioUrl, opt = 0) {
        var rs = audioUrl;
        if (typeof(audioUrl) == 'string' && audioUrl.length > 0) {
            if (this.occurrences(audioUrl, 'http') > 0) {
                rs = audioUrl;
            } else {
                if (opt == 0) {
                    rs = AppConst.CDN_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? localStorage.getItem('lang') + '/' : "vn/") + audioUrl;
                    if (localStorage.getItem('lang') == 'en' && this.occurrences(rs, 'english') > 0) {
                        rs = rs.replace('english/', '/');
                    }
                }
            }
        }
        return rs;
    }
    shorten(str, maxLen, separator = ' ') {
        str = str.trim();
        if (str.length <= maxLen) {
            return str;
        } else {
            if (str.length > 0) {
                return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
            } else {
                return str.substr(0, str.lastIndexOf(separator, maxLen));
            }
        }
    }
    paddZero(n) {
        return ('0' + n).slice(-2);
    }
    postTypeRoute(postType) {
        //console.log(AppConst.POST_TYPE_LINK);
        var postRoute = 'post';
        if (typeof(AppConst.POST_TYPE_LINK[postType]) == 'string') {
            postRoute = AppConst.POST_TYPE_LINK[postType];
        }
        return postRoute;
    }
    genPostLink(postType, id) {
        var url = "#";
        var route = this.postTypeRoute(postType);
        if (route == 'clicks') {
            url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/' + route + '/' + id + '?type=' + postType;
        } else {
            url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/' + route + '/' + id;
        }
        return url;
    }
    genPostDownloadLink(postType, id, endpoint = '/download') {
        var url = "#";
        var route = this.postTypeRoute(postType);
        url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/' + route + endpoint + '/' + id;
        return url;
    }
    genPrintLink(postType, id, endpoint = '/printer') {
        var url = "#";
        //var route = this.postTypeRoute(postType);
        //url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/' + route + endpoint + '/' + id + '?type=' + postType;
        url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + endpoint + '/' + id + '?type=' + postType;
        return url;
    }
    catTypeRoute(catType) {
        //console.log(AppConst.POST_TYPE_LINK);
        var postRoute = 'cat';
        if (typeof(AppConst.CAT_TYPE_LINK[catType]) == 'string') {
            postRoute = AppConst.CAT_TYPE_LINK[catType];
        }
        return postRoute;
    }
    genCatLink(catType, id) {
        var url = "#";
        var route = this.catTypeRoute(catType);
        url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/' + route + '/' + id;
        return url;
    }
    subCatTypeRoute(catType) {
        //console.log(AppConst.POST_TYPE_LINK);
        var postRoute = 'sub';
        if (typeof(AppConst.SUBCAT_TYPE_LINK[catType]) == 'string') {
            postRoute = AppConst.SUBCAT_TYPE_LINK[catType];
        }
        return postRoute;
    }
    genSubCatLink(catType, id) {
        var url = "#";
        var route = this.subCatTypeRoute(catType);
        url = AppConst.BASE_URL + (localStorage.getItem('lang') && localStorage.getItem('lang') != AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "") + '/' + route + '/' + id;
        return url;
    }
    displayShortenEntityContent(content, limit) {
        content = this.shorten(this.displayEntity(this.removeHTML(content)), limit);
        return content;
    }
    getVideoFromPost(video) {
        var rs = {};
        if (typeof(video) == 'object') {
            if (Object.prototype.hasOwnProperty.call(video, 'videoogg')) {
                rs['video'] = video.videoogg;
                rs['type'] = 'video/ogg';
            }
            if (Object.prototype.hasOwnProperty.call(video, 'videowebm')) {
                rs['video'] = video.videowebm;
                rs['type'] = 'video/webm';
            }
            if (Object.prototype.hasOwnProperty.call(video, 'videoflv')) {
                rs['video'] = video.videoflv;
                rs['type'] = 'video/flv';
            }
            if (Object.prototype.hasOwnProperty.call(video, 'videomp4')) {
                rs['video'] = video.videomp4;
                rs['type'] = 'video/mp4';
            }
        }
        return rs;
    }
    format_time(s, locale = 'vi-VN', tZone = 'UTC') {
        const dtFormat = new Intl.DateTimeFormat(locale, {
            timeStyle: 'medium',
            timeZone: tZone
        });
        return dtFormat.format(new Date(s * 1e3));
    }
    formatTime(time, locale = 'fr-FR') {
        var date = new Date(parseInt(time) * 1000);
        //return date.toLocaleString('fr-FR', {timeStyle: "short", dateStyle: "short"});
        //return date.toLocaleDateString();
        var options = { year: "numeric", month: "2-digit", day: "2-digit" };
        var currentLang = localStorage.getItem('lang');
        if (typeof(currentLang) == 'string' && currentLang.length > 0) {
            currentLang = currentLang.replace(' ', '');
            switch (currentLang) {
                case 'en':
                    locale = 'en-US';
                    break;
                case 'jp':
                    locale = 'ja-JP';
                    break;
                default:
                    break;
            }
        }
        return date.toLocaleString(locale, options);
        //return date;
    }
    formatTimeDetail(time, locale = 'fr-FR') {
        var date = new Date(parseInt(time) * 1000);
        //return date.toLocaleString('fr-FR', {timeStyle: "short", dateStyle: "short"});
        //return date.toLocaleDateString();
        var options = { year: "numeric", month: "2-digit", day: "2-digit" };
        var currentLang = localStorage.getItem('lang');
        if (typeof(currentLang) == 'string' && currentLang.length > 0) {
            currentLang = currentLang.replace(' ', '');
            switch (currentLang) {
                case 'en':
                    locale = 'en-US';
                    options = { year: "numeric", month: "short", day: "2-digit" };
                    break;
                case 'jp':
                    locale = 'ja-JP';
                    break;
                default:
                    break;
            }
        }
        var hour = String(date.getHours()).padStart(2, '0');
        var minute = String(date.getMinutes()).padStart(2, '0');
        var second = String(date.getSeconds()).padStart(2, '0');
        return date.toLocaleString(locale, options) + ' ' + hour + ':' + minute + ':' + second;
        //return date;
    }
    formatNumber(number, locale = 'fr-FR') {
        let numberFormatted = number;
        //en-US
        if (number.length > 0 && (typeof(number) == 'string' || typeof(number) == 'number')) {
            //let nf = new Intl.NumberFormat(locale);
            //numberFormatted = nf.format(parseInt(number));
            //numberFormatted = number.toLocaleString();
            if (locale == 'fr-FR') {
                numberFormatted = String(number).replace(/(.)(?=(\d{3})+$)/g, '$1.');
            } else {
                numberFormatted = String(number).replace(/(,)(?=(\d{3})+$)/g, '$1,');
            }
        }
        return numberFormatted;
    }
    getToDay($t) {
        var stringketqua = "";
        var today = new Date();
        var thu = today.getDay() + 1;
        var ngay = today.getDate();
        var thang = today.getMonth() + 1;
        var nam = today.getFullYear();
        const month = today.toLocaleString('en-US', { month: 'short' });
        switch (thu) {
            case 1:
                {
                    thu = $t("sunday");
                    break;
                }
            case 2:
                {
                    thu = $t("monday");
                    break;
                }
            case 3:
                {
                    thu = $t("tuesday");
                    break;
                }
            case 4:
                {
                    thu = $t("wednesday");
                    break;
                }
            case 5:
                {
                    thu = $t("thursday");
                    break;
                }
            case 6:
                {
                    thu = $t("friday");
                    break;
                }
            case 7:
                {
                    thu = $t("saturday");
                    break;
                }
        }

        var currentLang = localStorage.getItem('lang');
        if (currentLang == 'en') {
            stringketqua = month + " " + ngay + ", " + nam;
        } else {
            stringketqua = thu + ", " + ngay + "/" + thang + "/" + nam;
        }
        return stringketqua;
    }

    toMonthName(monthNumber) {
        var locale = 'vi-VN';
        var currentLang = localStorage.getItem('lang');
        if (typeof(currentLang) == 'string' && currentLang.length > 0) {
            currentLang = currentLang.replace(' ', '');
            switch (currentLang) {
                case 'en':
                    locale = 'en-US';
                    break;
                case 'jp':
                    locale = 'ja-JP';
                    break;
                default:
                    locale = 'vi-VN';
                    break;
            }
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString(locale, {
                month: 'long',
            });
        } else {
            return monthNumber;
        }
    }

    buildParam(param) {
        return Object.entries(param).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
    }
    changeVal(varName, newValue) {
        this[varName] = newValue;
    }
}

export default new FunctionService();