<template>
  <div v-if="typeof(postitem) == 'object' && Object.keys(postitem).length>0 && typeof(morePost) == 'object' && morePost.length>0" class="onepage-post-more-section-container">
    <!--
    <pre>{{ JSON.stringify(postitem, null, 2) }}</pre>
    <pre>{{ JSON.stringify(morePost, null, 2) }}</pre>
    -->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div class="post-related-section">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="post-related-section-title-wrapper">
              <h1 class="post-related-section-title">{{$t('other_label')}}</h1>
              <div v-if="typeof(morePost) == 'object' && morePost.length>=morePostLimit" class="more-post-arrow-wrapper">
                <div @click="loadPrePage()" id="button-prev-small" class="button button-prev arrow-prev disabled-btn"></div>
                <div @click="loadNextPage()" id="button-next-small" class="button button-next arrow-next"></div>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content-related-wrapper post-related-content-wrapper row">
                 <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                <template v-for="(post, index) in morePost" :key="index">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="post-related-item">
                      <div class="image-wrapper"><img :src="getImages(post.summary+post.content, post.images)" /></div>
                      <div class="section-post-item-info section-3-post-item-info">
                        <a class="post-related-item-link" :href="genPostLink(post.type, post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 80)}} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span></a>
                      </div>
                    </div>
                  </div>
                </template>
            </div>
        </div>
        <!-- eslint-enable -->
      </div>
      <div v-if="typeof(morePost) == 'object' && morePost.length>=morePostLimit" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block">
            <div class="post-related-section-title-wrapper bottom-post-related-section-title-wrapper">
              <div class="more-post-arrow-wrapper">
                <div @click="loadPrePage()" id="button-prev-small" class="button button-prev arrow-prev disabled-btn"></div>
                <div @click="loadNextPage()" id="button-next-small" class="button button-next arrow-next"></div>
              </div>
            </div>
        </div>
      </div>     
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";



export default {
  data() {
    return {
      code: this.postitem.id,
      morePost: [],
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
      reachLast: false,
      reachFirst: true,
      morePostLimit: 6,
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  async created(){
    var param = {'type':this.postitem.type, 'limit': this.morePostLimit};
    this.getMorePost(this.postitem.id, param);
  },
  computed: {},
  onMounted() {
    
  },
  mounted() {},
  watch:{
    reachLast(){
      if(typeof(document.getElementsByClassName('button-next'))=='object' && document.getElementsByClassName('button-next')!=null && document.getElementsByClassName('button-next').length>0){
        var btnNext = null;
        if(this.reachLast==true){
          btnNext =  document.getElementsByClassName('button-next');
          Array.prototype.forEach.call(btnNext, function(slide, index) {
              btnNext.item(index).classList.add('disabled-btn');
          });
        }
        else{
          btnNext =  document.getElementsByClassName('button-next');
          Array.prototype.forEach.call(btnNext, function(slide, index) {
              btnNext.item(index).classList.remove('disabled-btn');
          });
        }
      }
    },
    reachFirst(){
      var btnPrev = null;
      if(typeof(document.getElementsByClassName('button-prev'))=='object' && document.getElementsByClassName('button-prev')!=null && document.getElementsByClassName('button-prev').length>0){
        if(this.reachFirst==true){
          btnPrev =  document.getElementsByClassName('button-prev');
          Array.prototype.forEach.call(btnPrev, function(slide, index) {
              btnPrev.item(index).classList.add('disabled-btn');
          });
        }
        else{
          btnPrev =  document.getElementsByClassName('button-prev');
          Array.prototype.forEach.call(btnPrev, function(slide, index) {
              btnPrev.item(index).classList.remove('disabled-btn');
          });
        }
      }
    }
  },
  methods: {
    loadPrePage(){
      if(this.currentPage>1){
        this.currentPage--;
        this.reachFirst = false;
      }
      else{
        this.reachLast = true;
      }
      if(!this.reachFirst){
        this.loadMorePage();
      }
      else{
        this.reachLast = false;
      }
    },
    loadNextPage(){
      if(!this.reachLast){
        this.currentPage++;
        this.loadMorePage();
      }
    },
    loadMorePage(){
      var param = {'type': this.postitem.type, 'offset': (this.currentPage-1) * this.morePostLimit, 'limit':this.morePostLimit}
      this.getMorePost(this.code, param);
      if(this.morePost.length>0){
        this.reachFirst = false;
      }
      if(this.currentPage==1){
        this.reachFirst = true;
      }
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    getMorePost(code, param) {
      PublicService.getMorePost(code, param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.morePost = response.data;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    displayPostTags: function (tags, tagsView) {
      return FunctionService.displayPostTags(tags, tagsView);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
  },
};
</script>

<style></style>
