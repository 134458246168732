<template>
    <section class="section-wrapper section-2-wrapper">
        <!--
        <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
        <pre>{{ JSON.stringify(blockData, null, 2) }}</pre>
        -->
        <!--<pre>{{ blockData}}</pre>-->
        <div class="container-fluid section-hit-counter-container" :style="sectionStyle">
            <div class="row">
              <div class="col-12">
                <div v-if="typeof(hitData)=='object' && Object.keys(hitData).length>0" class="">
                  <div class="section-hit-counter-content">
                    <div class="section-hit-counter-item section-hit-counter-online">{{$t('online_label')}}: {{formatNumber(hitData.online)}}</div>
                    <div class="section-hit-counter-item section-hit-counter-month">{{$t('month_hit')}}: {{formatNumber(hitData.month)}}</div>
                    <div class="section-hit-counter-item section-hit-counter-year">{{$t('year_hit')}}: {{formatNumber(hitData.year)}}</div>
                  </div>
                </div>
              </div>
            </div>

        </div>
    </section>
</template>

<script>

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import "jquery-sticky"

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData", "allData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      hitData: [],
    };
  },
  created(){ 
    var vm = this;
    this.getHitsData(vm);
  },
  mounted(){
    
  },
  computed: {
    sectionStyle() {
      let newStyle = {};
      if (this.componentData.data.bgImg.length > 0) {
        newStyle.backgroundImage = "url(" + this.componentData.data.bgImg + ")";
        if (
          this.componentData.data.bgImgFixed.length > 0 &&
          this.componentData.data.bgImgFixed == 1
        ) {
          newStyle.backgroundAttachment = "fixed";
        }
      } else {
        if (this.componentData.data.bgColor.length > 0) {
          newStyle.backgroundColor = this.componentData.data.bgColor;
        }
      }
      return newStyle;
    },
  },
  components: {

  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getHitsData(vm) {
        var resp = PublicService.getHitsData();
        resp.then(function (data) {
          //console.table(data);
          //console.log(data);
          if(typeof(data) == 'object' && Object.prototype.hasOwnProperty.call(data, "data")){
            vm.hitData = data.data;
          }  
        });
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    formatNumber: function(number, locale){
      return FunctionService.formatNumber(number, locale);
    },
    getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
      //compatibility for firefox and chrome
      var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
      var pc = new myPeerConnection({
          iceServers: []
      }),
      noop = function() {},
      localIPs = {},
      ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
      // eslint-disable-next-line no-unused-vars
      key;

      function iterateIP(ip) {
          if (!localIPs[ip]) onNewIP(ip);
          localIPs[ip] = true;
      }

      //create a bogus data channel
      pc.createDataChannel("");

      // create offer and set local description
      pc.createOffer().then(function(sdp) {
          sdp.sdp.split('\n').forEach(function(line) {
              if (line.indexOf('candidate') < 0) return;
              line.match(ipRegex).forEach(iterateIP);
          });
          
          pc.setLocalDescription(sdp, noop, noop);
          // eslint-disable-next-line no-unused-vars
      }).catch(function(reason) {
          // An error occurred, so handle the failure to connect
      });

      //listen for candidate events
      pc.onicecandidate = function(ice) {
          if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
          ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
      };
  }
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>