<template>
  <div></div>
</template>

<script>
import { useRoute } from "vue-router";
//import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";
//import { defineComponent, computed, reactive, ref, getCurrentInstance } from 'vue'
import { defineComponent, computed, reactive, getCurrentInstance } from 'vue'
//import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head';

export default defineComponent({
  //name: "HomeCmp",
  props: ["postdata"],
  setup(props) {

    const route = useRoute();

    //console.log(props.postdata);
    const app= getCurrentInstance()
    // eslint-disable-next-line no-unused-vars
    const lang = localStorage.getItem('lang');
    const siteMeta = app.appContext.config.globalProperties.siteMeta
    const gPro = app.appContext.config.globalProperties;

    let postTitle = typeof(props.postdata.title)=='string' && (props.postdata.title).length>0?displayEntity(removeHTML(props.postdata.title)):siteMeta.title
    let postDesc = typeof(props.postdata.description)=='string' && (props.postdata.description).length>0?displayEntity(removeHTML(props.postdata.description)):(lang!='' && lang!='vi'?siteMeta['desc_'+lang]:siteMeta.desc)
    let postImages = typeof(props.postdata.image)=='string' && (props.postdata.image).length>0?getImages('',props.postdata.image):siteMeta.image
    //let postLink = typeof(props.postdata.code)=='string' && (props.postdata.code).length>0?genCatLink(props.postdata.type, props.postdata.code):(lang!='' && lang!='vi'?gPro.$baseUrl+lang:gPro.$baseUrl)
    //let postKeywords = typeof(props.postdata.tags_view)=='string' && (props.postdata.tags_view).length>0?((props.postdata.tags_view).replace(';',',')):(lang!='' && lang!='vi'?siteMeta['keywords_'+lang]:siteMeta.keywords)
    let postKeywords = '';
    if(typeof(String.prototype.replaceAll())=='string'){
      postKeywords = typeof(props.postdata.tags_view)=='string' && (props.postdata.tags_view).length>0?((props.postdata.tags_view).replaceAll(';',',')):(lang!='' && lang!='vi'?siteMeta['keywords_'+lang]:(siteMeta.keywords).replaceAll(';',','))
    }
    else{
      postKeywords = typeof(props.postdata.tags_view)=='string' && (props.postdata.tags_view).length>0?((props.postdata.tags_view).replace(/;/g,',')):(lang!='' && lang!='vi'?siteMeta['keywords_'+lang]:(siteMeta.keywords).replace(/;/g,','))
    }

    //const AppConst = ref(AppConst)cover
    //const AppConst = ref('')
    //console.log(app.appContext.config.globalProperties.siteTitle);
    const siteData = reactive({
      title: postTitle,
      description: postDesc,
      appName: siteMeta.appName,
      image: postImages,
      author: siteMeta.author,
      icon: siteMeta.icon,
      //url: postLink,
      url: gPro.$baseUrl+route.fullPath,
      keywords: postKeywords,
      robots: siteMeta.robots,
      country: siteMeta.country,
      revisitAfter: siteMeta.revisitAfter,
      documentRating: siteMeta.documentRating,
      documentDistribution: siteMeta.documentDistribution,
      distribution: siteMeta.distribution,
      area: siteMeta.area,
      placename: siteMeta.placename,
      resourceType: siteMeta.resourceType,
      owner: siteMeta.owner,
      classification: siteMeta.classification,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        { name: 'copyright', content: siteMeta.copyright },
        { name: 'application-name', content: siteData.appName },
        { name: 'email', content: siteMeta.email },
        { name: 'section', itemprop: 'articleSection', content: "Tin tức - Sự kiện" },
        { name: 'keywords', content: computed(() => siteData.keywords) },
        { name: 'robots', content: siteData.robots },
        { name: 'country', content: siteData.country },
        { name: 'revisit-after', content: siteData.revisitAfter },
        { name: 'document-rating', content: siteData.documentRating },
        { name: 'document-distribution', content: siteData.documentDistribution },
        { name: 'distribution', content: siteData.distribution },
        { name: 'area', content: siteData.distribution },
        { name: 'placename', content: siteData.placename },
        { name: 'resource-type', content: siteData.resourceType },
        { name: 'owner', content: siteData.owner },
        { name: 'classification', content: siteData.classification },
        //{ name: 'description', content: 'A description of the page', id: 'desc' }, // id to replace intead of create element
        // Twitter
        { name: 'twitter:title', content: computed(() => siteData.title) },
        { name: 'twitter:description', content: computed(() => siteData.description)},
        { name: 'twitter:card', content: siteMeta.twitterCard},
        { name: 'twitter:site', content: computed(() => siteData.url)},
        { name: 'twitter:creator', content: computed(() => siteData.author)},
        // Google+ / Schema.org
        { itemprop: 'name', content: computed(() => siteData.title) },
        { itemprop: 'description', content: siteMeta.copyright },
        // Facebook / Open Graph
        //{ property: 'fb:app_id', content: '123456789' },
        { property: 'og:title', content: computed(() => siteData.title) },
        { property: 'og:site_name', content: computed(() => siteData.title) },
        { property: 'og:type', content: "article" },
        { property: 'og:image', content: siteData.image },
        { property: 'og:image:url', content: siteData.image },
        { property: 'og:url', content: computed(() => siteData.url) },
        { property: 'og:description', content: computed(() => siteData.description) },
        //<meta property="article:published_time" content="2021-12-27T09:40:54+00:00">
        //<meta property="article:modified_time" content="2022-04-15T12:46:19+00:00">
      ],
      // link tags
      link: [
        { rel: 'canonical', href: computed(() => siteData.url), id: 'canonical' },
        { rel: 'author', href: siteMeta.author, undo: false }, // undo property - not to remove the element
        { rel: 'icon', href: siteMeta.icon, sizes: '32x32', type: 'image/png' }, 
        // with shorthand
        //{ r: 'icon', h: 'path/to/icon-32.png', sz: '32x32', t: 'image/png' },
      ],
      /*
      script: [
        { type: 'text/javascript', src: 'cdn/to/script.js', async: true, body: true}, // Insert in body
        // with shorthand
        { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' },
        // ...
      ],
      style: [
        { type: 'text/css', inner: 'body { background-color: #000; color: #fff}', undo: false },
        // ...
      ]
      */
    })

    // eslint-disable-next-line no-unused-vars
    function genCatLink(type, id) {
      return FunctionService.genCatLink(type, id);
    }
    function getImages(str, imgUrl, pos) {
      return FunctionService.getImages(str, imgUrl, pos);
    }
    function displayEntity(str) {
      return FunctionService.displayEntity(str);
    }
    function removeHTML(data) {
      return FunctionService.removeHTML(data);
    }
    /*
    function linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    }
    function displayHtmlContent(data) {
      return FunctionService.displayHtmlContent(data);
    }
    function shorten(str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    }
    function paddZero(number) {
      return FunctionService.paddZero(number);
    }
    function postTypeRoute(type) {
      return FunctionService.postTypeRoute(type);
    }
    function displayShortenEntityContent(content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    }
    function formatTime(time, locale) {
      return FunctionService.formatTime(time, locale);
    }
    */

  },
  data() {
    return {
      
    };
  },
  created(){
    
  },
  computed: {},
  onMounted() {},
  mounted() {},
  watch:{
    
  },
  methods: {
    /*
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl, pos) {
      return FunctionService.getImages(str, imgUrl, pos);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    */
  },
});
</script>

<style></style>
