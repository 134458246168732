<template>
  <section
    v-if="blockData.length"
    class="widget-block-wrapper widget-block-3-wrapper"
    :style="[
      componentData.data.bgColor.length > 0
        ? { 'background-color': componentData.data.bgColor }
        : {},
    ]"
  >
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div v-if="componentData.data.headline.length > 0" class="row">
      <div class="col">
        <div class="widget-block-2-wrapper-title">
        <h1 class="section-wrapper-title widget-block-3-section-title">
          {{ componentData.data.headline }}
        </h1>
        </div>
      </div>
    </div>
    <div v-if="blockData.length" class="row">
      <div class="col">
        <div class="widget-block-container widget-block-3-container">
          <div class="widget-block-3-container-content-item-list">
            <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
            <template
              v-for="(post, index) in blockData[0]"
              :postitem="post"
              :key="index"
            >
              <div class="widget-block-3-container-content-item">
                <div class="widget-block-3-container-content-item-link-wrapper">
                  <a
                    :href="genPostLink(componentData.type, post.id)"
                    class="widget-block-3-container-content-item-link"
                    >{{ shorten(displayEntity(post.title), 80) }}</a
                  >
                </div>
              </div>
            </template>
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "owl.carousel";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      //blockTitle: [],
    };
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
  computed: {},
  components: {},
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];

        resp.then(function (data) {
          //console.log(data);
          //alert(data);
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if (typeof dt.data.child == "object") {
              vm.blockData.push(dt.data.child);
            } else {
              vm.blockData.push(dt.data);
            }
          });
        });
        //console.log(this.blockData);
      }
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
