<template>
  <div v-if="typeof(postitem) == 'object' && Object.keys(postitem).length>0">
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    {{updateHits(postitem.id, postitem.type)}}
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <PostBreadCrumbCmp :code="postitem.catcode" :type="postitem.type"></PostBreadCrumbCmp>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content-wrapper post-content-wrapper">
                  <main v-if="postitem.hasTemplate==0" id="content" class="neve-main" role="main">
                      <div class="container single-post-container">
                          <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div class="main-content-wrapper">
                                <div class="share-network-list-fixed-wrapper">
                                  <div class="share-network-list-fixed-container">
                                    <div id="share-network-list-fixed">
                                      <ShareNetwork
                                                          v-for="network in networks"
                                                          :network="network.network"
                                                          :key="network.network"
                                                          :style="{backgroundColor: network.color}"
                                                          :url="sharing.url"
                                                          :title="sharing.title"
                                                          :description="sharing.description"
                                                          :quote="sharing.quote"
                                                          :hashtags="sharing.hashtags"
                                                          :twitterUser="sharing.twitterUser"
                                                        >
                                                          <i :class="network.icon"></i>
                                                          <span>{{ network.name }}</span>
                                      </ShareNetwork>
                                      <div class="share-network-zalo" data-v-0141c77e="" style="">
                                          <div class="zalo-share-button" :data-href="genPostLink(pageType, postitem.id)" data-oaid="3776018789323949240" data-layout="3" data-color="white" data-customize="true" style=""><img src="/assets/img/ic_zalo.png" style=""/></div>
                                      </div>
                                      <a class="share-network-copy" href="javascript:void(0)" data-v-0141c77e="" style="background-color: rgb(51, 51, 51);">
                                          <i class="fa fa-lg fa-files-o" data-v-0141c77e="" @click="copyUrl" :data-toggle="$t('copy_link_message')" :title="$t('copy_link_message')"></i>
                                          <span data-v-0141c77e=""></span>
                                      </a>
                                      <a class="share-network-print" :href="genPrintLink(pageType, postitem.id)" style="background-color: rgb(51, 51, 51);" target="_blank">
                                        <i class="fa fa-lg fa-print"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <article :id="'post-'+postitem.id" class="doc-data type-post">
                                          <div class="nv-content-wrap entry-content">
                                              <div class="doc-content-container">
                                                <div class="row">
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="doc-title-wrapper">
                                                    <h4 class="doc-title">{{displayEntity(postitem.title)}}</h4>
                                                  </div>
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="doc-detail-wrapper">
                                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                      <li class="nav-item" role="presentation">
                                                        <a class="nav-link active" id="general-info-tab" data-bs-toggle="tab" href="#general-info" role="tab" aria-controls="general-info" aria-selected="true">{{$t('doc_general_info')}}</a>
                                                      </li>
                                                      <li class="nav-item" role="presentation">
                                                        <a class="nav-link" id="content-info-tab" data-bs-toggle="tab" href="#content-info" role="tab" aria-controls="content-info" aria-selected="false">{{$t('doc_content_info')}}</a>
                                                      </li>
                                                      <li class="nav-item" role="presentation">
                                                        <a class="nav-link" id="related-tab" data-bs-toggle="tab" href="#related" role="tab" aria-controls="related" aria-selected="false">{{$t('doc_relate_info')}}</a>
                                                      </li>
                                                    </ul>
                                                    <div class="tab-content doc-tab-content" id="myTabContent">
                                                      <div class="tab-pane fade show active" id="general-info" role="tabpanel" aria-labelledby="general-info-tab">
                                                        <div class="table-responsive">
                                                          <table class="table doc-detail-table">
                                                            <tbody>
                                                              <tr>
                                                                <td class="doc-content-label doc-title-label">{{$t('doc_name')}}:</td>
                                                                <td class="doc-detail-title">{{displayEntity(postitem.title)}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-dept-label">{{$t('doc_dept')}}:</td>
                                                                <td class="doc-dept">{{displayEntity(postitem.dept_name)}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-issue-label">{{$t('doc_issue')}}:</td>
                                                                <td class="doc-issue">
                                                                  <span v-if="postitem.dateissue != null && (postitem.dateissue).length>0 && typeof(parseInt(postitem.dateissue))=='number'" class="doc-issue-date">{{formatTime(postitem.dateissue)}}</span>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-field-label">{{$t('doc_field')}}:</td>
                                                                <td class="doc-field">{{displayEntity(postitem.fieldcode_name)}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-publishup-label">{{$t('doc_publishup')}}:</td>
                                                                <td class="doc-publishup">
                                                                  <span v-if="postitem.publishup != null && (postitem.publishup).length>0 && typeof(parseInt(postitem.publishup))=='number'" class="doc-publishup-date">{{formatTime(postitem.publishup)}}</span>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-publishdown-label">{{$t('doc_publishdown')}}:</td>
                                                                <td class="doc-publishdown">
                                                                  <span v-if="postitem.publishdown != null && (postitem.publishdown).length>0 && typeof(parseInt(postitem.publishdown))=='number'" class="doc-publishdown-date">{{formatTime(postitem.publishdown)}}</span>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-type-label">{{$t('doc_type')}}:</td>
                                                                <td class="doc-type">{{displayEntity(postitem.typecode_name)}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-sovb-label">{{$t('doc_sovb')}}:</td>
                                                                <td class="doc-sovb">{{displayEntity(postitem.sovb)}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-sign-label">{{$t('doc_sign')}}:</td>
                                                                <td class="doc-sign">{{displayEntity(postitem.nguoiky)}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-publish-label">{{$t('doc_publish')}}:</td>
                                                                <td :class="postitem.publish==1?'doc-is-publish doc-publish':'doc-is-not-publish doc-publish'">{{postitem.publish==1?'Có hiệu lực':'Hết hiệu lực'}}</td>
                                                              </tr>
                                                              <tr>
                                                                <td class="doc-content-label doc-download-label">{{$t('doc_download')}}:</td>
                                                                <td class="doc-download">
                                                                  <a class="doc-download-link" :href="genPostDownloadLink(postitem.type, postitem.id)">{{(postitem.docname).length>0?postitem.docname:'Văn bản'}}</a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                      <div class="tab-pane fade" id="content-info" role="tabpanel" aria-labelledby="content-info-tab">
                                                        <div class="row">
                                                          <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="doc-summary-wrapper">
                                                            <div>{{displayHtmlContent(postitem.summary)}}</div>
                                                          </div>
                                                          <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="doc-content-wrapper">
                                                            <div v-html="displayPostContent(postitem.content)"></div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="tab-pane fade" id="related" role="tabpanel" aria-labelledby="related-tab">
                                                        <div class="doc-related-wrapper">
                                                          <div class="row">
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                              <DefaultPostRelatedSectionDocCmp
                                                                :parentComponentData="parentComponentData"
                                                                :postitem="postitem"
                                                                :pageType="pageType"
                                                                @content-page-clicked="contentPageChanged"
                                                              ></DefaultPostRelatedSectionDocCmp>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                </div>
                                                <!--
                                                <div class="row">
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="doc-tags-wrapper">
                                                    <div class="doc-tags-container">Từ khóa: <div class="doc-tags-list" v-html="displayPostTags(postitem.tags, postitem.tags_view)"></div></div>
                                                  </div>
                                                </div>
                                                -->
                                                <div class="row">
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="doc-share-wrapper">
                                                    <div id="share-network-list" class="share-network-list">
                                                      <ShareNetwork
                                                        v-for="network in networks"
                                                        :network="network.network"
                                                        :key="network.network"
                                                        :style="{backgroundColor: network.color}"
                                                        :url="sharing.url"
                                                        :title="sharing.title"
                                                        :description="sharing.description"
                                                        :quote="sharing.quote"
                                                        :hashtags="sharing.hashtags"
                                                        :twitterUser="sharing.twitterUser"
                                                      >
                                                        <i :class="network.icon"></i>
                                                        <span>{{ network.name }}</span>
                                                      </ShareNetwork>
                                                      <div class="share-network-zalo" data-v-0141c77e="" style="">
                                                        <div class="zalo-share-button" :data-href="genPostLink(pageType, postitem.id)" data-oaid="3776018789323949240" data-layout="3" data-color="white" data-customize="true" style=""><img src="/assets/img/ic_zalo.png" style=""/></div>
                                                      </div>
                                                      <a class="share-network-copy" href="javascript:void(0)" data-v-0141c77e="" style="background-color: rgb(51, 51, 51);">
                                                        <i class="fa fa-lg fa-files-o" data-v-0141c77e="" @click="copyUrl" :data-toggle="$t('copy_link_message')" :title="$t('copy_link_message')"></i>
                                                        <span data-v-0141c77e=""></span>
                                                      </a>
                                                      <a class="share-network-print" :href="genPrintLink(pageType, postitem.id)" style="background-color: rgb(51, 51, 51);" target="_blank">
                                                          <i class="fa fa-lg fa-print"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                      </article>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <DefaultPostMoreSectionDocCmp
                                :parentComponentData="parentComponentData"
                                :postitem="postitem"
                                :pageType="pageType"
                                @content-page-clicked="contentPageChanged"
                              ></DefaultPostMoreSectionDocCmp>
                            </div>
                          </div>
                      </div>
                  </main> 
                  <div v-else-if="postitem.hasTemplate==1" class="single-post-container" id="elementor-container">
                  
                  </div>
                  <main v-else-if="postitem.hasTemplate==2" id="content" class="neve-main" role="main">
                      <div class="single-post-container" v-html="displayPostContent(postitem.content)">
                      </div>
                  </main>
            </div>
        </div>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import DefaultPostRelatedSectionDocCmp from "./DefaultPostRelatedSectionDocCmp";
import DefaultPostMoreSectionDocCmp from "./DefaultPostMoreSectionDocCmp";

import PostBreadCrumbCmp from "./PostBreadCrumbCmp";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import "jquery-sticky"

export default {
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      //perPage: parseInt(this.parentComponentData.info.perPage),
      //totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
      sharing: {
        url: this.genPostLink(this.postitem.type, this.postitem.id),
        title: this.postitem.title,
        description: this.removeHTML(this.postitem.summary),
        quote: '',
        hashtags: '',
        twitterUser: ''
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fa fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'twitter', name: 'Twitter', icon: 'fa fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'email', name: 'Email', icon: 'fa fa-lg fa-envelope', color: '#333333' },
        //{ network: 'viber', name: 'Viber', icon: 'fa fa-lg fa-viber', color: '#59267c' },
        //{ network: 'messenger', name: 'Messenger', icon: 'fa fa-lg fa-facebook-messenger', color: '#0084ff' },
        //{ network: 'telegram', name: 'Telegram', icon: 'fa fa-lg fa-telegram-plane', color: '#0088cc' },
        //{ network: 'pinterest', name: 'Pinterest', icon: 'fa fa-lg fa-pinterest', color: '#bd081c' },
        //{ network: 'sms', name: 'SMS', icon: 'fa fa-lg fa-comment-dots', color: '#333333' },
        //{ network: 'tumblr', name: 'Tumblr', icon: 'fa fa-lg fa-tumblr', color: '#35465c' },
      ],
      copyMess: this.$t('copy_link_message'),
      bsPopover: null,
      options: {
        topSpacing: 20
      }
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  components: {
    DefaultPostRelatedSectionDocCmp,
    DefaultPostMoreSectionDocCmp,
    PostBreadCrumbCmp
  },
  created(){
  },
  computed: {},
  onMounted() {
    
  },
  mounted() {
    setTimeout(() => {
      var siteSticky = function() {
        var bSpacing = $('html').height() - $('.main-content-wrapper').height() - $('#share-network-list-fixed').height();  
        $("#share-network-list-fixed").sticky({topSpacing:90, bottomSpacing:bSpacing});
      };
      siteSticky();
    }, 1000)
  },
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    }
  },
  methods: {
    copyUrl: function(event) {
      window.navigator.clipboard.writeText(this.$baseUrl+this.$router.currentRoute.value.path);
        this.bsPopover = new Popover(
          event.target, 
          {
            placement: 'top', 
            trigger: 'click',
            offset: [0, 20],
          },
          )
        this.bsPopover.show()
        setTimeout(() => this.bsPopover.hide(), 1000);
    },
    handlePageChange(event, page){
      //console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    updateHits(code, type){
      PublicService.updatePostHits(code, {'type':type, 'return':0}).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "hits")) {
                    this.currentVideoHits = response.data.hits;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    getRelatedPost(code, param) {
      PublicService.getaRelatedPost(code, param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.relatedPost = response.data;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    displayPostTags: function (tags, tagsView) {
      return FunctionService.displayPostTags(tags, tagsView);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    genPostDownloadLink: function (type, id, endpoint) {
      return FunctionService.genPostDownloadLink(type, id, endpoint);
    },
    genPrintLink: function (type, id) {
      return FunctionService.genPrintLink(type, id);
    },
  },
};
</script>

<style></style>
