<template>
    <section class="section-wrapper section-3-wrapper">
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(blockData[2], null, 2) }}</pre>-->
    <!--<pre>{{ blockData}}</pre>-->
    <div class="container section-3-container">
        <div class="row">
            <div class="col col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 section-3-container-left">
                <div class="row">
                    <div class="col-xl-12">
                        <a v-if="(componentData.data.dataList[0].link).length>0" class="section-3-section-title-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                            <h1 class="section-3-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
                        </a>
                        <h1 v-else class="section-3-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
                    </div>
                </div>
                <div v-if="blockData.length>=1" class="row">
                    <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div v-if="typeof(blockData[0])=='object'" class="section-post-item section-3-post-item section-3-post-item-big">
                            <div class="image-wrapper">
                              <a :href="genPostLink(blockData[0][0].type,blockData[0][0].id)" v-lazy-container="{ selector: 'img' }">
                                <img :data-src="getImages(blockData[0][0].summary+blockData[0][0].content, blockData[0][0].images)" />
                              </a>
                            </div>
                            <div class="section-post-item-info section-3-post-item-info">
                                <a class="section-post-info-title section-3-post-info-title" :href="genPostLink(blockData[0][0].type,blockData[0][0].id)" :title="displayEntity(blockData[0][0].title)">{{displayEntity(blockData[0][0].title)}}</a>
                                <div class="section-3-summary">{{displayHtmlContent(blockData[0][0].summary)}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div>
                            <div v-if="typeof(blockData[0])=='object'" class="row">
                                <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                <template v-for="(post, index) in blockData[0].slice(1,blockData[0].length)"
                                :postitem="post" :key="index"
                                >
                                <div v-if="index<2" class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                    <div class="section-post-item section-3-post-item">
                                        <div class="image-wrapper">
                                          <a class="" :href="genPostLink(post.type,post.id)" v-lazy-container="{ selector: 'img' }"><img :data-src="getImages(post.summary+post.content, post.images)" /></a>
                                        </div>
                                        <div class="section-post-item-info section-3-post-item-info"><a class="section-post-info-title section-3-post-info-title" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 50)}}</a></div>
                                    </div>
                                </div>
                                <div v-else class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <div class="section-post-item section-3-post-item section-3-post-item-title-only">
                                        <div class="section-post-item-info section-3-post-item-info"><a class="section-post-info-title section-3-post-info-title" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 70)}}</a></div>
                                    </div>
                                </div>
                                </template>
                                <!-- eslint-enable -->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <a v-if="(componentData.data.dataList[0].link).length>0" class="section-3-section-more-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                                      {{$t('see_more')}}
                                  </a>
                                  <a v-else class="section-3-section-more-link" :href="genCatLink(componentData.type, componentData.code)" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                                      {{$t('see_more')}}
                                  </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 section-3-container-right">
                <div class="row">
                    <div :class="typeof(blockData[2])=='object'?'section-3-container-right-content1-wrapper col col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12':'section-3-container-right-content1-wrapper col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12'">
                        <div class="row">
                            <div class="col-xl-12">
                                <a v-if="(componentData.data.dataList[1].link).length>0" class="section-3-section-title-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">
                                    <h1 class="section-1-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
                                </a>
                                <h1 v-else class="section-3-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="section-3-container-right-content2-wrapper col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <div class="section-3-right-content">
                                    <div class="row">
                                        <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                        <template v-for="(post, index) in blockData[1]"
                                        :postitem="post" :key="index"
                                        >
                                        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                            <div class="section-post-item section-3-post-item section-3-post-item-title-only">
                                                <div class="section-post-item-info section-3-post-item-info"><i class="post-list-marker fa fa-angle-double-right"></i><a class="section-post-info-title section-3-post-info-title" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 70)}}</a></div>
                                            </div>
                                        </div>
                                        </template>
                                        <!-- eslint-enable -->
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                          <a v-if="(componentData.data.dataList[1].link).length>0" class="section-3-section-more-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">
                                              {{$t('see_more')}}
                                          </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="typeof(blockData[2])=='object'" class="section-3-container-right-content2-wrapper col col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div>
                                <div v-html="displayPostContent(blockData[2][0].content)"></div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      //blockTitle: [],
    };
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
  computed: {

  },
  components: {

  },
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        
        resp.then(function (data) {
          //console.log(data);
          //alert(data);
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
          });
        });
        //console.log(this.blockData);
      }
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    genCatLink: function (type, id) {
      return FunctionService.genCatLink(type, id);
    },
  },
  
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>