<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 pt-3 pb-3 bg-white from-wrapper">
          <h3>{{typeof(cat) == 'object' && cat != null && cat.hasOwnProperty('title') && typeof(cat.title)=='string'?cat.title:''}}</h3>
          <div v-if="posts.length > 0" class="col-12 mt-5 pt-3 pb-3 bg-white from-wrapper">
            <h3>Danh sách</h3>
            <hr />
            <CatSubItem
              v-for="(post, index) in posts"
              :key="post.id"
              :index="index"
              :post="post"
              :imgPlaceholder="imgPlaceholder"
            ></CatSubItem>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import CatSubItem from "../cmps/CatSubItemCmp";
export default {
  data() {
    return {
      cat_id: null,
      cat_index: null,
      posts: [],
      title: "",
      content: "",
      image: "",
      imgPlaceholder: "http://via.placeholder.com/300",
      cat:[]
    };
  },
  computed: {
    imgPreview() {
      return this.images == "" ? this.imgPlaceholder : this.images;
      //return this.images;
      /*
      var finalImg = "";
      if(this.image==""){
        //finalImg = this.getImages(this.summary+this.content, this.images);
      }
      return finalImg == "" ? this.imgPlaceholder : finalImg;
      */
    },
    strippedHtml() {
        let regex = /(<([^>]+)>)/ig;
        return this.summary.content.rendered.replace(regex, "");
    }
  },
  components: {
    CatSubItem
  },
  methods: {
    getImages(str, imgUrl){
      if(typeof(str) == 'string' && str.length>0){
        var imgSrc = str.match(/<img [^>]*src="[^"]*"[^>]*>/gm).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
        return typeof(imgSrc[0])=='string'?imgSrc[0]:imgUrl;
      }
      else{
        return imgUrl;
      }
    },
    get() {
      var id = this.$route.params.id;
      this.$api
        .get("/cat/" + id + '?type=1')
        .then(res => {
          console.log(res);
          this.posts = res.data.child;
          this.cat = res.data.parent;
        })
        .catch(err => {
          console.log(err.response);
        });
    }
  },
  created() {
    //this.get();
  }
};
</script>

<style>
</style>