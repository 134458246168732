<template>
  <section class="section-statistic-list-content-wrapper" :style="sectionStyle">
    <!--{{layout}}-->
    <!--
    json.forEach(x => { console.log(x.firstname, x.lastname); });
    <pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(parentComponentDataItem, null, 2) }}</pre>
    -->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(statisticBlock.vi, null, 2) }}</pre>-->
    <div
      class="container-fluid section-default-list-content-container" 
    >
      <div>
        <div
          v-if="layout == 0"
          class="container section-default-list-content-container-1"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>
            </div>
            <div
              class="container section-default-list-content-container-right col"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 1"
          class="container section-default-list-content-container-1"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <div v-if="typeof(cCode) == 'string' && cCode.length>0" class="statistic-detail-content">
                    <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-content-block col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <!--{{block.component}}-->
                          <component
                            v-if="block.key==cCode"
                            :is="block.component"
                            :block="block"
                            :key="index"
                            :componentData="block"
                            :parentComponentData="parentComponentData"
                            :parentComponentDataItem="parentComponentDataItem"
                            @content-page-clicked="contentPageChanged"
                          ></component>
                        </div>
                      </div>
                  </div>
                  <div v-else>
                    <div v-if="statisticBlock && this.view=='statistitc'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/'+block.key">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="statisticBlock && this.view=='home'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a v-if="typeof(block.link) == 'string' && (block.link).length>0" :href="block.link">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                          <a v-else :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/cat/'+block.code">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>

            </div>
            <div
              class="container section-default-list-content-container-right col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 2"
          class="container section-default-list-content-container-2"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <div v-if="typeof(cCode) == 'string' && cCode.length>0" class="statistic-detail-content">
                    <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-content-block col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <!--{{block.component}}-->
                          <component
                            v-if="block.key==cCode"
                            :is="block.component"
                            :block="block"
                            :key="index"
                            :componentData="block"
                            :parentComponentData="parentComponentData"
                            :parentComponentDataItem="parentComponentDataItem"
                            @content-page-clicked="contentPageChanged"
                          ></component>
                        </div>
                      </div>
                  </div>
                  <div v-else>
                    <div v-if="statisticBlock && this.view=='statistitc'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/'+block.key">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="statisticBlock && this.view=='home'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a v-if="typeof(block.link) == 'string' && (block.link).length>0" :href="block.link">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                          <a v-else :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/cat/'+block.code">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="container section-default-list-content-container-right col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 3"
          class="container section-default-list-content-container-3"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <div v-if="typeof(cCode) == 'string' && cCode.length>0" class="statistic-detail-content">
                    <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-content-block col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <!--{{block.component}}-->
                          <component
                            v-if="block.key==cCode"
                            :is="block.component"
                            :block="block"
                            :key="index"
                            :componentData="block"
                            :parentComponentData="parentComponentData"
                            :parentComponentDataItem="parentComponentDataItem"
                            @content-page-clicked="contentPageChanged"
                          ></component>
                        </div>
                      </div>
                  </div>
                  <div v-else>
                    <div v-if="statisticBlock && this.view=='statistitc'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/'+block.key">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="statisticBlock && this.view=='home'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a v-if="typeof(block.link) == 'string' && (block.link).length>0" :href="block.link">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                          <a v-else :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/cat/'+block.code">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="container section-default-list-content-container-right col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 9"
          class="container section-default-list-content-container-9"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <div v-if="typeof(cCode) == 'string' && cCode.length>0" class="statistic-detail-content">
                    <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-content-block col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <!--{{block.component}}-->
                          <component
                            v-if="block.key==cCode"
                            :is="block.component"
                            :block="block"
                            :key="index"
                            :componentData="block"
                            :parentComponentData="parentComponentData"
                            :parentComponentDataItem="parentComponentDataItem"
                            @content-page-clicked="contentPageChanged"
                          ></component>
                        </div>
                      </div>
                  </div>
                  <div v-else>
                    <div v-if="statisticBlock && this.view=='statistitc'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/'+block.key">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="statisticBlock && this.view=='home'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a v-if="typeof(block.link) == 'string' && (block.link).length>0" :href="block.link">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                          <a v-else :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/cat/'+block.code">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 10"
          class="section-default-list-content-container-0"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <div v-if="typeof(cCode) == 'string' && cCode.length>0" class="statistic-detail-content">
                    <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-content-block col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <!--{{block.component}}-->
                          <component
                            v-if="block.key==cCode"
                            :is="block.component"
                            :block="block"
                            :key="index"
                            :componentData="block"
                            :parentComponentData="parentComponentData"
                            :parentComponentDataItem="parentComponentDataItem"
                            @content-page-clicked="contentPageChanged"
                          ></component>
                        </div>
                      </div>
                  </div>
                  <div v-else>
                    <div v-if="statisticBlock && this.view=='statistitc'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock[lang]" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/'+block.key">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="statisticBlock && this.view=='home'" class="statistic-block-wrapper">
                      <div class="row">
                        <div v-for="(block, index) in statisticBlock" :key="index" class="statistic-block col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                          <a v-if="typeof(block.link) == 'string' && (block.link).length>0" :href="block.link">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                          <a v-else :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/cat/'+block.code">
                            <div class="text-center">
                              <div class="image-wrapper">
                                <img v-if="typeof(block.description) == 'string' && (block.description).length>0 && occurrences(block.description, 'http')" :src="block.description"/>
                                <img v-else :src="block.image"/>
                              </div>
                              <p>{{block.title}}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import DefaultSearchSectionResultCmp from "./cmpsItem/DefaultSearchSectionResultCmp";

import DefaultListSectionSubList1Cmp from "../cmps/DefaultListSectionSubList1Cmp";
import WidgetBlock1 from "../cmps/WidgetBlock1";
import WidgetBlock2 from "../cmps/WidgetBlock2";
import WidgetBlock3 from "../cmps/WidgetBlock3";
import WidgetBlock4 from "../cmps/WidgetBlock4";

import StatisticInternationalCmp from "./cmpsItem/StatisticInternationalCmp";
import StatisticDomesticCmp from "./cmpsItem/StatisticDomesticCmp";
import StatisticReceiptsCmp from "./cmpsItem/StatisticReceiptsCmp";
import StatisticTouristAccommodationCmp from "./cmpsItem/StatisticTouristAccommodationCmp";

import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import jsonBlock from '/public/assets/data/statistic/data.json';
/*
Object.entries(jsonBlock).forEach( item => {
    //Vue.component( item[1].component, require(`${item[1].path}`).default  );
    //console.log(item[1].component);
});
*/

export default {
  setup() {
    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    return{
      baseUrl
    }
  },
  props: [
    "loggedIn",
    "componentData",
    "parentComponentData",
    "parentComponentDataItem",
  ],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      content: [],
      parentData: [],
      isLoading: 1,
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      layout: this.componentData.data.layout,
      pageType: this.parentComponentData.type,
      endpoint: "PageConfig/getStatisticPageConfig",
      param: {},
      statisticBlock: jsonBlock,
      lang: localStorage.getItem('lang'),
      view: 'statistitc'
    };
  },
  computed: {
    sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    //DefaultSearchSectionResultCmp,
    DefaultListSectionSubList1Cmp,
    WidgetBlock1,
    WidgetBlock2,
    WidgetBlock3,
    WidgetBlock4,
    StatisticInternationalCmp,
    StatisticDomesticCmp,
    StatisticReceiptsCmp,
    StatisticTouristAccommodationCmp
  },
  async created() {
    if(this.$route.query.view != 'undefined' && this.$route.query.category != 'undefined'){
      if(this.$route.query.view=='home' && (this.$route.query.category).length>0){
        this.view = 'home';
        this.getSubCat(this.$route.query.category);
      }
    }
    //this.get();
    //this.getAPIDataByType(this.componentData);
    this.param = {'offset': this.currentPage, 'callType':1};
    await this.getPageDataFromApi(
      this.cRoute,
      this.param
    );
  },
  methods: {
    contentPageChanged(page) {
      this.$emit("content-page-clicked", page);
    },
    searchFilterChanged(param){
      this.$emit("search-filter-changed", param);
    },
    getPageDataFromApi(type, param){
      param = {'type':type, 'position':'contentRight'};
            PublicService.getStatisticPageConfig('PageConfig/getStatisticPageConfig',param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.content = response.data;
                    this.isLoading = 0;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    getSubCat(code){
      PublicService.getBlockPublicDataEndpoint('other/getSubCat/'+code).then(
        (response) => {
          //console.log(response);
          if (
            Object.prototype.hasOwnProperty.call(response, "data")
          ) {
            this.statisticBlock = response.data;
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length > 0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {},
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    get() {
      const route = useRoute();
      const id = route.params.id;
      PublicService.getCatPublicContent(id).then(
        (response) => {
          //console.log("DefaultListSectionCmp");
          //console.log(response);
          //this.content = response.data;
          this.posts = response.data.child;
          this.cat = response.data.parent;
          //console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    occurrences: function(string, subString, allowOverlapping){
      return FunctionService.occurrences(string, subString, allowOverlapping);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
