<template>
  <section
    v-if="posts.length"
    class="widget-block-wrapper widget-block-2-wrapper"
    :style="[
      componentData.data.bgColor.length > 0
        ? { 'background-color': componentData.data.bgColor }
        : {},
    ]"
  >
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div v-if="componentData.data.headline.length > 0" class="row">
      <div class="col text-center">
        <h1 class="section-wrapper-title widget-block-2-wrapper-title">
          {{ componentData.data.headline }}
        </h1>
      </div>
    </div>
    <div v-if="posts.length" class="widget-block-container widget-block-2-container">
      <div>
        <section id="banner">
          <div
            id="owl-list"
            class="owl-list owl-theme owl-carousel"
            :style="this.setContainerStyle(componentData.data)"
          >
            <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
            <template
              v-for="(post, index) in posts"
              :postitem="post"
              :key="index"
            >
              <div
                v-if="typeof post == 'object' && Object.keys(post).length > 0"
                :class="typeof(post.video)=='string' && post.video.length > 0 ? 'video-item' : 'item'"
              >
                <div v-if="typeof(post.video)=='string' && post.video.length > 0" class="video-background">
                  <div class="video-foreground">
                    <div v-html="post.video"></div>
                  </div>
                </div>
                <div v-if="typeof(post.video)=='string' && post.video.length == 0" class="banner-item-wrapper">
                  <span
                    v-if="post.images.length > 0"
                    :style="`background-image: url(${getImages('',post.images)})`"
                  ></span>
                  <div class="container widget-block-2-container">
                    <div class="widget-block-2-info">
                      <div v-if="post.content.length > 0">
                        <div class="banner-content">
                          <div v-html="post.content"></div>
                        </div>
                      </div>
                      <div v-else class="caption">
                        <h1
                          v-if="post.summary.length > 0"
                          class="animated fadeInUp"
                        >
                          {{ removeHTML(post.summary) }}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- eslint-enable -->
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import "owl.carousel";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      parentData: [],
    };
  },
  computed: {},
  components: {
  },
  created() {
    //this.get();
    this.getAPIDataByType(this.componentData);
  },
  methods: {
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length > 0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {
      //console.log(owlCarousel);
      var owl = jQuery(".owl-list");
      if (owl.length > 0) {
        owl.owlCarousel({
          dots: true,
          items: 1,
          loop: true,
          //autoplay: true,
          //autoplayTimeout: 5000,
          //autoplayHoverPause: true,
          video: true,
          videoWidth: false, // Default false; Type: Boolean/Number
          videoHeight: false, // Default false; Type: Boolean/Number
          nav: true,
          navText: [
            "<button type='button' class='btn btn-info btn-sm'>Previos</button>",
            "<button type='button' class='btn btn-info btn-sm'>Next</button>",
          ],
          animateOut: "fadeOut",
          animateIn: "fadeIn",
        });
        owl.on("changed.owl.carousel", function (event) {
          var item = event.item.index - 2; // Position of the current item
          jQuery("h1").removeClass("animated fadeInUp");
          jQuery(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h1")
            .addClass("animated fadeInUp");

          //console.log(event);
          //console.log(item);
          var current = event.item.index;
          /*
          console.log(
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length
          );
          */
          if (
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length > 0
          ) {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }

            var currentIframe = jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find("iframe");
            //console.log(currentIframe);
            if (currentIframe.length > 0) {
              var src = currentIframe.attr("src");
              console.log(src);
              currentIframe.attr("src", src + "&autoplay=1");
              currentIframe.addClass("playing-video");
            }
          } else {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }
          }
        });
      }
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
