export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "search_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
  "month_hit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượt truy cập trong tháng"])},
  "year_hit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượt truy cập trong năm"])},
  "search_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ khoá"])},
  "search_datatype_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại thông tin"])},
  "search_datatype1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin tức"])},
  "search_datatype2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "search_datatype3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ảnh"])},
  "search_datatype4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài liệu"])},
  "search_datatype5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn thủ tục"])},
  "search_datatype6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Góp ý dự thảo"])},
  "search_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
  "search_chose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn"])},
  "search_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết quả tìm kiếm"])},
  "search_result_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
  "search_result_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết quả"])},
  "search_result_units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết quả"])},
  "search_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
  "search_keyword_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập từ khoá tìm kiếm"])},
  "search_no_result_search_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tìm thấy nội dung bạn đang tìm kiếm? Hãy thử các kết quả tìm kiếm khác tại đây."])},
  "search_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tra cứu văn bản"])},
  "search_docs_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tra cứu"])},
  "select_docs_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn Nhóm văn bản"])},
  "select_docs_dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn Cơ quan ban hành"])},
  "select_docs_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn Lĩnh vực ban hành"])},
  "select_docs_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn Loại văn bản"])},
  "select_docs_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn Năm ban hành"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ khóa"])},
  "post_for_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin tức cho từ khoá"])},
  "update_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đăng"])},
  "view_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lượt xem"])},
  "related_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin liên quan"])},
  "other_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin khác"])},
  "other_album_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Album khác"])},
  "other_video_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video khác"])},
  "other_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản khác"])},
  "other_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thủ tục khác"])},
  "first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang đầu"])},
  "last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang cuối"])},
  "next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang sau"])},
  "prev_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang trước"])},
  "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài liệu đính kèm"])},
  "doc_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số kí hiệu"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết"])},
  "date_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày ban hành"])},
  "date_effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày có hiệu lực"])},
  "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tiếp"])},
  "copy_link_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép địa chỉ thành công"])},
  "doc_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên văn bản"])},
  "doc_dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cơ quan ban hành"])},
  "doc_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày ban hành"])},
  "doc_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lĩnh vực ban hành"])},
  "doc_publishup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày có hiệu lực"])},
  "doc_publishdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày hết hiệu lực"])},
  "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại văn bản"])},
  "doc_sovb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số hiệu"])},
  "doc_sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người ký"])},
  "doc_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu lực văn bản"])},
  "doc_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải văn bản"])},
  "doc_general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chung"])},
  "doc_content_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung tóm tắt"])},
  "doc_relate_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản liên quan"])},
  "breadcrumb_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang chủ"])},
  "online_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "statistic_cat_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số liệu thống kê"])},
  "statistic_lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tra cứu số liệu"])},
  "statistic_year_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Năm"])},
  "statistic_period_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số liệu"])},
  "subscribe_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký nhận bản tin du lịch"])},
  "subscribe_tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký để nhận các bản tin tổng hợp về du lịch"])},
  "subscribe_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "subscribe_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
  "invalid_email_mess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng điền địa chỉ email hợp lệ"])},
  "subscribe_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký thành công"])},
  "subscribe_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký không thành công"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ nhật"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ hai"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ ba"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ tư"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ năm"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ sáu"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ bảy"])},
  "print_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In trang"])},
  "register_already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã có tài khoản?"])},
  "register_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
  "register_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
  "register_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ email"])},
  "register_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên đăng nhập"])},
  "register_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
  "register_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ"])},
  "register_tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại"])},
  "register_username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phải điền tên đăng nhập"])},
  "register_username_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên đăng nhập phải có độ dài tối thiểu 6 ký tự"])},
  "register_username_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên đăng nhập phải có độ dài tối đa 20 ký tự"])},
  "register_email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phải điền địa chỉ email"])},
  "register_email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ email không đúng định dạng"])},
  "register_email_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ email phải có độ dài tối đa 70 ký tự"])},
  "register_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phải điền mật khẩu"])},
  "register_password_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu phải có độ dài tối thiểu 6 ký tự"])},
  "register_password_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu phải có độ dài tối đa 20 ký tự"])},
  "register_password_strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu phải có ký tự hoa, thường, số và ký tự đặc biệt"])},
  "login_no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có tài khoản?"])},
  "login_register_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký ngay"])},
  "login_btt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
  "login_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
  "login_email_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ email hoặc tên đăng nhập"])},
  "login_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ email"])},
  "login_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên đăng nhập"])},
  "login_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
  "login_username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phài điền tên đăng nhập"])},
  "login_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phài điền mật khẩu"])},
  "login_back_to_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<< Trở về trang chủ"])},
  "create_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo tài khoản"])},
  "or_login_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoặc đăng nhập bằng"])},
  "or_signup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoặc"])}
}