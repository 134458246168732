<template>
    <section class="section-wrapper section-menu-top-wrapper" :style="sectionStyle">
        <!--
        <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
        <pre>{{ JSON.stringify(blockData, null, 2) }}</pre>
        <pre>{{ blockData}}</pre>
        -->
        <div class="container-fluid section-menu-top-container d-xl-block d-lg-block d-md-none d-sm-none d-xs-none d-none">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="section-menu-top-inner-container">
                    <div class="row align-items-center">
                      <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div class="row align-items-center">
                          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div class="menu-top-block-1">
                              <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                              <template v-for="(post, index) in blockData[0]"
                              :postitem="post" :key="index"
                              >
                                <div class="menu-top-block-1-item">
                                    <div v-if="(post.content).length==0" class="menu-top-block-1-item-img">
                                      <a v-if="(post.link).length>0" :href="post.link" :target="(post.target==0?'_self':'_blank')"><img :src="getImages(post.summary+post.content, post.images)" /></a>
                                      <img v-else :src="getImages(post.summary+post.content, post.images)" />
                                    </div>
                                    <div v-else class="menu-top-block-1-item-content" v-html="post.content"></div>
                                </div>
                              </template>
                              <!-- eslint-enable -->
                            </div>
                          </div>
                          <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 col-12">
                            <form class="search-form" ref="form" :action="searchAction" method="GET">
                              <div class="input-group">
                                      <input class="menu-top-search form-control border rounded-pill" type="text" :placeholder="$t('search_holder')" v-model="searchText" id="menu-top-search" v-on:keydown.enter.prevent="submit()">
                                      <span class="input-group-append menu-top-search-btn-wrapper">
                                          <button class="menu-top-search-btn btn btn-outline-secondary bg-white border-start-0 border rounded-pill ms-n3" type="button" @click.stop.prevent="submit()">
                                              <i class="fa fa-search"></i>
                                          </button>
                                      </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div class="menu-top-block-2 menu-top-link-wrapper">
                          <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                              <template v-for="(post, index) in blockData[1]"
                              :postitem="post" :key="index"
                              >
                                <div class="menu-top-link-wrapper">
                                  <a v-if="(post.link).length>0" :href="post.link" :target="(post.target==0?'_self':'_blank')" class="menu-top-link"><img v-if="post.images" :src="post.images" />{{post.title}}</a>
                                  <span v-else>{{post.link}}</span>
                                </div>
                              </template>
                          <!-- eslint-enable -->
                          <div class="menu-top-link-wrapper">
                            <!--
                              <select id="menu-top-language" class="menu-top-language" v-on:change="changeLang($event)">
                              <option value="en">English</option>
                              <option value="vi">Tiếng Việt</option>--
                              <option value="jp">日本語</option>
                            </select>
                            -->
                              <a class="lang-link lang-link-en" data-lang="en" v-on:click="changeLangLink($event)">English</a>
                              <a class="lang-link lang-link-jp" data-lang="jp" v-on:click="changeLangLink($event)">日本語</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {getCurrentInstance, ref} from "vue";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import BannerItem from "./BannerItemCmp";

export default {
  setup(){
    let lang = ref('');
    let displayLang = ref('');
    let baseUrl = ref('');

    const app= getCurrentInstance();
    lang = app.appContext.config.globalProperties.siteLang
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    if(lang!='en'){
      displayLang = 'en';
    }
    return {
      lang,
      displayLang,
      baseUrl
    }
  },
  props: ["loggedIn", "componentData", "allData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      searchText: "",
      searchAction: "/search",
    };
  },
  created(){ 
    var vm = this;
    if(typeof(this.componentData.data.dataList)=='object'){
      this.getBlockData(this.componentData, vm);
    }
  },
  mounted(){
    
  },
  computed: {
    sectionStyle() {
      let newStyle = {};
      if (this.componentData.data.bgImg.length > 0) {
        newStyle.backgroundImage = "url(" + this.componentData.data.bgImg + ")";
        if (
          this.componentData.data.bgImgFixed.length > 0 &&
          this.componentData.data.bgImgFixed == 1
        ) {
          newStyle.backgroundAttachment = "fixed";
        }
      } else {
        if (this.componentData.data.bgColor.length > 0) {
          newStyle.backgroundColor = this.componentData.data.bgColor;
        }
      }
      return newStyle;
    },
  },
  components: {
    //BannerItem
  },
  methods: {
    changeLang(event){
      var langSelected = event.target.value;
      if(langSelected!='vi'){
        this.$router.push("/"+langSelected+'/');
        //this.$router.go('http://localhost:8080/'+langSelected);
        localStorage.setItem('lang', langSelected);
        //window.location.href = 'http://localhost:8080/'+langSelected;
        window.location.href = this.baseUrl+'/'+langSelected;
      }
      else{
        this.$router.push("/");
      }
    },
    changeLangLink(event){
      //var langSelected = event.target.value;
      var langSelected = event.currentTarget.getAttribute('data-lang');
      if(langSelected!='vi'){
        //this.$router.push("/"+langSelected+'/');
        this.$router.push("/"+langSelected);
        //this.$router.go('http://localhost:8080/'+langSelected);
        localStorage.setItem('lang', langSelected);
        //window.location.href = 'http://localhost:8080/'+langSelected;
        window.location.href = this.baseUrl+'/'+langSelected;
      }
      else{
        this.$router.push("/");
      }
    },
    /*
    submit : function(){
      this.$refs.form.submit()
    },
    */
    submit(){
      this.$router.push((localStorage.getItem('lang') && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText);
      this.searchAction = (localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText;
      //this.$refs.form.submit();
    },
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>