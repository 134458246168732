<template>
  <section
    v-if="typeof(componentData)=='object' && posts.length>0"
    class="section-wrapper widget-block-wrapper section-default-list-sublist-right-wrapper"
    :style="sectionStyle"
  >
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div
      v-if="typeof(parentComponentData) == 'object' && typeof(posts) == 'object' && posts.length>0"
      class="container section-default-list-sublist-right-container"
      :style="this.setContainerStyle(componentData.data)"
    >
      <div class="section-default-list-sublist-right-container-content">
        <div class="section-default-list-sublist-right-container-content-item-headlink">
            <div class="section-default-list-sublist-right-container-content-item-link-wrapper section-default-list-sublist-right-container-content-item-headlink-wrapper">
              <a :href="genCatLink(parentComponentData.type, parentComponentData.code)" class="section-default-list-sublist-right-container-content-item-headlink">{{displayEntity(parentComponentData.title)}}</a>
            </div>
          </div>
        <div class="section-default-list-sublist-right-container-content-item-list">
          <div v-for="(post, index) in posts" 
              :key="index"
            class="section-default-list-sublist-right-container-content-item"
          >
            <div class="section-default-list-sublist-right-container-content-item-link-wrapper">
              <a :href="genCatLink(post.type, post.code)" class="section-default-list-sublist-right-container-content-item-link">{{displayEntity(post.title)}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute } from "vue-router";

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import BannerItem from "./BannerItemCmp";
/*
import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
*/

export default {
  props: ["loggedIn", "componentData", "parentComponentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      parentData: [],
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
    };
  },
  created() {
    this.getData(this.cCode);
  },
  computed: {
    sectionStyle() {
      let newStyle = {};
      if (this.componentData.data.bgImg.length > 0) {
        newStyle.backgroundImage = "url(" + this.componentData.data.bgImg + ")";
        if (
          this.componentData.data.bgImgFixed.length > 0 &&
          this.componentData.data.bgImgFixed == 1
        ) {
          newStyle.backgroundAttachment = "fixed";
        }
      } else {
        if (this.componentData.data.bgColor.length > 0) {
          newStyle.backgroundColor = this.componentData.data.bgColor;
        }
      }
      return newStyle;
    },
  },
  components: {
    //BannerItem,
  },
  methods: {
    getData(code) {
      PublicService.getBlockPublicDataEndpoint('other/getSubCat/'+code).then(
        (response) => {
          //console.log(response);
          if (
            Object.prototype.hasOwnProperty.call(response, "data")
          ) {
            this.posts = response.data;
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    genCatLink: function (type, id) {
      return FunctionService.genCatLink(type, id);
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
