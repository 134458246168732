<template>
  <section class="section-wrapper section-1-wrapper">
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(blockData, null, 2) }}</pre>-->
    <!--<pre>{{ blockData}}</pre>-->
    <div class="container section-container section-1-container">
      <div class="row">
        <div
          class="col col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 section-1-left-content section-1-content"
        >
          <div v-if="typeof(componentData.data.dataList[0]) == 'object' && (componentData.data.dataList[0].dataHeadLine).length>0" class="row">
            <div class="col-xl-12">
              <a v-if="(componentData.data.dataList[0].link).length>0" class="section-1-section-title-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                <h1 class="section-1-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
              </a>
              <h1 v-else class="section-1-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div id="section-1-slide-col" class="col">
                  <b-carousel
                    id="section-1-slider"
                    v-model="slide"
                    :interval="5000"
                    controls
                    
                    background="#fff"
                    img-width=""
                    img-height=""
                    style=""
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide
                      v-for="(post, index) in blockData[0]"
                      :key="index"
                      caption=""
                      text=""
                      :img-src="getImages(post.summary+post.content, post.images)"
                      :class="index==0?'active':''"
                      :id="`section-1-carousel-item${post.id}`"
                      style=""
                      :title="displayEntity(post.title)"
                      :summary="post"
                    ></b-carousel-slide>
                    <ol class="carousel-indicators">
                      <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                      <template v-for="(post, index) in blockData[0]"
                        :postitem="post" :key="index"
                        >
                        <li
                          :class="(index==0)?'active':''"
                          data-bs-target="#section-1-slider"
                          :data-bs-slide-to="index"
                        ></li>
                      </template>
                      <!-- eslint-enable -->
                    </ol>
                  </b-carousel>
                </div>
                <div id="section-1-post-info-wrapper" class="col-xl-4">
                  <div id="section-1-post-info-container">
                    <a class="section-1-post-info-title-link" :href="activeLink" :title="displayEntity(activeTitle)">
                      <h1 class="section-1-post-info-title">
                        {{displayEntity(shorten(activeTitle, 85))}}
                      </h1>
                    </a>
                    <div>
                      <p class="section-1-post-info-excerpt">
                        {{shorten(displayEntity(removeHTML(activeSummary)), 250)}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 section-1-right-content section-1-content"
        >
          <div class="row">
            <div class="col-xl-12">
              <a v-if="(componentData.data.dataList[1].link).length>0" class="section-1-section-title-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">
                <h1 class="section-1-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
              </a>
              <h1 v-else class="section-1-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
              <template v-for="(post, index) in blockData[1]"
              :postitem="post" :key="index"
              >
                <div class="row">
                  <div class="col">
                    <div class="section-1-post-item">
                      <div class="section-1-post-number-wrapper">
                        <p class="section-1-post-number"><i class="fa"></i></p>
                      </div>
                      <div class="section-1-post-title-container">
                        <a class="section-1-post-title" :href="genPostLink(componentData.type,post.id)" :title="displayEntity(post.title)">
                          {{shorten(displayEntity(post.title), 60)}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- eslint-enable -->
              <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right right-align">
                    <a v-if="(componentData.data.dataList[1].link).length>0" class="section-1-section-more-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">{{ $t('see_more') }}</a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

/*eslint no-unused-vars: "error"*/

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import ClassWatcher from "../services/dom.service";

/*
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
*/


import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

//import Section1LeftItem from "./cmpsItem/Section1LeftItemCmp";

export default {
  props: ["loggedIn", "componentData", "allData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      slide: 0,
      sliding: null,
      activeTitle: "",
      activeSummary: "",
      activeLink:"",
    };
  },
  computed: {},
  components: {
    //Section1LeftItem,
  },
  mounted() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
    /*
    console.log('carousel-item: ');
    var carousel = document.getElementsByClassName('carousel-item');
    console.log(carousel);
    */
    /*
    carousel.forEach(element => {
      let classWatcher = new ClassWatcher(element, 'trigger', this.workOnClassAdd, this.workOnClassRemoval);
      console.log(classWatcher);
    });
    */

    /*
    const attrObserver = new MutationObserver((mutations) => {
      mutations.forEach(mu => {
        if (mu.type !== "attributes" && mu.attributeName !== "class") return;
        console.log("class was modified!");
      });
    });

    const ELS_test = document.querySelectorAll(".carousel-item");
    ELS_test.forEach(el => attrObserver.observe(el, {attributes: true}));

    console.log(ELS_test);
    */

    //$('#section-1-slider').carousel();
    /*
    this.$nextTick(function(){
      var carousel = document.getElementsByClassName('carousel-item');
      console.log(carousel);
    });

    console.log(this.$listener);

    console.log('+++++++++++++++++++++++++++++++++++');
    */
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
        //console.log(slide);
        this.sliding = true
      },
    onSlideEnd(slide) {
        //console.log(slide);
        //console.log(this.blockData[0][slide.to]);
        this.sliding = false
        this.activeTitle = this.blockData[0][slide.to].title;
        this.activeSummary = this.blockData[0][slide.to].summary;
        var activeID = this.blockData[0][slide.to]['id'];
        this.activeLink = this.genPostLink(this.componentData.type,activeID)
    },
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        /*
              resp.then(function(data){
                  data.map(function(dt){
                      console.log(dt)
                      });
                      //setBlockData(dt);
                      }.bind(this));
              */

        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            //console.log("vm: ");
            //console.log(vm);
            vm.blockData.push(dt.data);
            //blockData = dt.data;
            //this.setBlockData(dt.data);
            //dtCt++;
          });
          if(typeof(vm.blockData[0]) == 'object'){
            //console.log(vm.blockData[0][0]);
            vm.activeTitle = vm.blockData[0][0].title;
            vm.activeSummary = vm.blockData[0][0].summary;
            vm.activeLink = vm.genPostLink(vm.componentData.type, vm.blockData[0][0].id);
          }
        });

        /*
              for (const res in resp) {
                  console.log(res.data);
                  console.log(res.then(response => { return response.data }));
                  allData.push(res.then(response => { return response.data }));
              }
              */
        /*
              vm.blockData = allData;
              //this.blockData = allData;
              console.log('-------------------');
              console.log('blockData:');
              console.log(this.blockData);
              //this.setBlockData(allData);
              console.log('-------------------');
              */
      }
      //console.log("********************************************");
    },
    setBlockData(data) {
      this.blockData.push(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function(number){
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function(type){
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    workOnClassAdd() {
        alert("I'm triggered when the class is added")
    },
    workOnClassRemoval() {
        alert("I'm triggered when the class is removed")
    }
  },
  watch: {
    allData() {
      alert();
    },
  },
  created() {
    //this.getBlockData(this.componentData);
    //this.get();
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
