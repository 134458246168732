<template>
  <div>
    <!--
    <pre>{{ JSON.stringify(content, null, 2) }}</pre>
    <pre>{{this.$route.name}}</pre>
    <pre>{{cRoute}}</pre>
    {{cCode}}
    -->
    <div v-if="isLoading == 0 && content.length > 0">
      <MetaCmp
      :postdata="this.post"
      ></MetaCmp>
      <div v-for="(block, index) in content" :key="index">
        <component
          :is="block.data.component"
          :block="block"
          :key="block.data.id"
          :componentData="block.data"
          :parentComponentData="this.cat"
          :parentComponentDataItem="this.post"
          @content-page-clicked="contentPageChanged"
          @search-filter-changed="searchFilterChanged"
        ></component>
      </div>
    </div>
    <notifications/>
  </div>
</template>

<script>

import { useRoute } from 'vue-router'
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import $ from "jquery";

import MetaCmp from "../cmps/cmpsItem/MetaCmp";
import MenuTopCmp from "../cmps/MenuTopCmp";
import MenuTopCmpV2 from "../cmps/MenuTopCmpV2";
import MainMenuCmp from "../cmps/MainMenuCmp";
import MainMenuCmpV2 from "../cmps/MainMenuCmpV2";
import BannerSectionCmp from "../cmps/BannerSectionCmp";
import HomeSection1Cmp from "../cmps/HomeSection1Cmp";
import HomeSection2Cmp from "../cmps/HomeSection2Cmp";
import HomeSection3Cmp from "../cmps/HomeSection3Cmp";
import HomeSection4Cmp from "../cmps/HomeSection4Cmp";
import HomeSection5Cmp from "../cmps/HomeSection5Cmp";
import HomeSection6Cmp from "../cmps/HomeSection6Cmp";
import HomeSection7Cmp from "../cmps/HomeSection7Cmp";
import HomeSection8Cmp from "../cmps/HomeSection8Cmp";
import HomeSection9Cmp from "../cmps/HomeSection9Cmp";
import MainFooterCmp from "../cmps/MainFooterCmp";
import MainFooterCmpV2 from "../cmps/MainFooterCmpV2";
import HitCounterCmp from "../cmps/HitCounterCmp";
import ImageConnectCmp from "../cmps/ImageConnectCmp";
// Trang bài viết
//import BannerDefaultListSectionCmp from "../cmps/BannerDefaultListSectionCmp";
import BannerDefaultPostSectionCmp from "../cmps/BannerDefaultPostSectionCmp";
//import DefaultListSectionCmp from "../cmps/DefaultListSectionCmp";
import DefaultPostSectionCmp from "../cmps/DefaultPostSectionCmp";
//import DefaultListSectionSubListCmp from "../cmps/DefaultListSectionSubListCmp";
import DefaultPostSectionSubListCmp from "../cmps/DefaultPostSectionSubListCmp";
import DefaultListSectionSubList1Cmp from "../cmps/DefaultListSectionSubList1Cmp";

export default {
  /*
  props: {
        definitions: Array
  },
  */
  setup() {

  },
  data() {
    return {
      //bannerImage: this.$AppConst.NO_IMAGE_HOLDER,
      //post: {title:'',summary:'',content:''},
      post: {},
      postTemplateData: {},
      bannerImage: '',
      cat_id: null,
      cat_index: null,
      posts: [],
      title: "",
      image: "",
      imgPlaceholder: "http://via.placeholder.com/300",
      cat: [],
      isLoading: 1,
      isLoaded: 0,
      content: {},
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      hasTemplate: 0,
      //perPage: 10,
      //totalItem: 100,
      currentPage: isNaN(this.$route.query.page)?1:this.$route.query.page,
      callType: 1,
      AppConst: this.$AppConst,
    };
  },
  components: {
    MenuTopCmp,
    MenuTopCmpV2,
    MainMenuCmp,
    MainMenuCmpV2,
    BannerSectionCmp,
    HomeSection1Cmp,
    HomeSection2Cmp,
    HomeSection3Cmp,
    HomeSection4Cmp,
    HomeSection5Cmp,
    HomeSection6Cmp,
    HomeSection7Cmp,
    HomeSection8Cmp,
    HomeSection9Cmp,
    MainFooterCmp,
    MainFooterCmpV2,
    HitCounterCmp,
    ImageConnectCmp,
    //BannerDefaultListSectionCmp,
    BannerDefaultPostSectionCmp,
    //DefaultListSectionCmp,
    DefaultPostSectionCmp,
    //DefaultListSectionSubListCmp,
    DefaultPostSectionSubListCmp,
    DefaultListSectionSubList1Cmp,
    //CatItem,
    MetaCmp
  },
  computed: {
      checkImg(){
        return this.images != '';
      },
      strippedHtml() {
          let regex = /(<([^>]+)>)/ig;
          return this.summary.replace(regex, "");
      }
    },
  async created() {
    this.get();
  },
  mounted() {
    
  },
  methods: { 
    getPageDataFromApi(type, code, param){
      //var param = {};
      //param['callType'] = 1;
      //alert(param.offset);
      PublicService.getPublicPageData(code, 1, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "child")) {
            //this.posts = response.data.child;
            //this.cat = response.data.parent;
            //this.totalItem = typeof(response.data.parent.info) == "object" && typeof(response.data.parent.info.total) == "string"?response.data.parent.info.total:0;
            param = {'type':type, 'position':'home'};
            PublicService.getPublicPageConfig(code, 'PageConfig/getPageConfig',param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.content = response.data;
                    this.isLoading = 0;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
            /*
            if(typeof(response.data.parent)=='object' && typeof(response.data.parent.hasTemplate)=='string' && response.data.parent.hasTemplate==1){ // Có giao diện
              this.hasTemplate = 1;
            }
            else{ // Không có giao diện, dùng giao diện mắc định
              this.isLoading = 0;
              this.hasTemplate = 0;
            }
            */
            this.isLoading = 0;
            this.hasTemplate = 0;
            
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function(number){
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function(type){
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    occurrences: function(string, subString, allowOverlapping){
      return FunctionService.occurrences(string, subString, allowOverlapping);
    },
    logData(data){
      console.log(data);
    }, 
    get() {
      const route = useRoute()
      const id = route.params.id;
      PublicService.getAlbumPublicContent(id).then(
        (response) => {
          this.post = response.data;
          //this.content = response.data;
          //this.hasTemplate = this.post.hasTemplate;
          this.bannerImage = this.getImages(response.data.excerpt+response.data.content, response.data.images);
          this.hasTemplate = response.data.hasTemplate;
          //console.log('this.hasTemplate: ' + this.hasTemplate);
            //this.post = response.data;

            this.postTemplateData = response.data;
            this.bannerImage = this.getImages(this.post.summary+this.post.content, this.post.images);

            if (Object.prototype.hasOwnProperty.call(response.data, "catcode")) {
              var catParam = {'callType':10};
              PublicService.getCatPublicContent(response.data.catcode, 1, catParam).then(
                (responseCat) => {
                  //console.log('this.cat:');
                  //console.log(responseCat);
                  if (Object.prototype.hasOwnProperty.call(responseCat, "data")) {
                    this.cat = responseCat.data.parent;
                    //console.log(this.cat);
                    var param = this.$router.currentRoute.value.query;
                    if(Object.keys(param).length>0){
                      param.offset = this.currentPage;
                      param.callType = this.callType;
                    }
                    else{
                      param = {'offset': this.currentPage, 'callType': this.callType};
                    }
                    this.getPageDataFromApi(this.cRoute, this.cCode, param);

                  }
                },
                (error) => {
                    this.content =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
            }

        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getOld() {
      const route = useRoute()
      const id = route.params.id;
      PublicService.getAlbumPublicContent(id).then(
        (response) => {
          this.post = response.data;
          //this.content = response.data;
          //this.hasTemplate = this.post.hasTemplate;
          this.bannerImage = this.getImages(response.data.excerpt+response.data.content, response.data.images);
          this.hasTemplate = response.data.hasTemplate;
          //console.log('this.hasTemplate: ' + this.hasTemplate);
          if(this.hasTemplate==1){
            //console.log('hasTemplate: ' + this.hasTemplate);
            PublicService.getPostTemplatePublicContent(id).then(
              (responseTmp) => {
                //console.log(responseTmp);
                //console.log(typeof(responseTmp.data));
                //console.log(typeof(responseTmp.data.data.post));
                //console.log((responseTmp.data.data.post).length);

                if(typeof(responseTmp.data.data.post.enqueuedScripts)=='object'){
                  /*
                  (responseTmp.data.data.post.enqueuedScripts).forEach(element => {
                    
                  });
                  */
                  const scripts = [];
                  Object.keys(responseTmp.data.data.post.enqueuedScripts.edges).forEach(function(key) {
                    //console.log(key, responseTmp.data.data.post.enqueuedScripts.edges[key].node.src);
                    var srcStr = responseTmp.data.data.post.enqueuedScripts.edges[key].node.src;
                    if(typeof(srcStr) == 'string' && (srcStr).length>0){
                      //if(this.occurrences(srcStr,'http')){
                        scripts.push(responseTmp.data.data.post.enqueuedScripts.edges[key].node.src);
                      //}
                      //else{
                        //scripts.push('http://govcms.leanhtuan.work/'+responseTmp.data.data.post.enqueuedScripts.edges[key].node.src);
                      //}
                    }
                  });
                  //console.log(scripts);
                  /*
                  scripts.forEach(script => {
                      let tag = document.head.querySelector(`[src="${ script }"`);
                      if (!tag) {
                          tag = document.createElement("script");
                          tag.setAttribute("src", script);
                          tag.setAttribute("type", 'text/javascript');
                          document.head.appendChild(tag); 
                      }
                  });
                  */
                }

                if(Object.prototype.hasOwnProperty.call(responseTmp.data.data.post, 'content')){
                  this.post = responseTmp.data.data.post;
                }
                else{
                  this.post = response.data;
                }
                console.log(this.post.content);
                $('#elementor-container').html(this.post.content);
              },
              (error) => {
                this.content =
                  (error.responseTmp &&
                    error.responseTmp.data &&
                    error.responseTmp.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          else{
            //this.post = response.data;
            this.postTemplateData = response.data;
            this.bannerImage = this.getImages(this.post.summary+this.post.content, this.post.images);

            if (Object.prototype.hasOwnProperty.call(response.data, "catcode")) {
              var catParam = {'callType':10};
              PublicService.getCatPublicContent(response.data.catcode, 1, catParam).then(
                (responseCat) => {
                  console.log('this.cat:');
                  console.log(responseCat);
                  if (Object.prototype.hasOwnProperty.call(responseCat, "data")) {
                    this.cat = responseCat.data.parent;
                    //console.log(this.cat);
                    var param = this.$router.currentRoute.value.query;
                    if(Object.keys(param).length>0){
                      param.offset = this.currentPage;
                      param.callType = this.callType;
                    }
                    else{
                      param = {'offset': this.currentPage, 'callType': this.callType};
                    }
                    this.getPageDataFromApi(this.cRoute, this.cCode, param);

                  }
                },
                (error) => {
                    this.content =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
            }

          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style>
</style>