import axios from 'axios';
//import { forEach } from 'core-js/core/array';
import authHeader from './auth-header';
import functionService from './function.service';

//const API_URL = 'http://apigov.leanhtuan.work/';
const API_URL = 'https://public.vietnamtourism.gov.vn/';

class PublicService {
    getHomePublicContent() {
        var param = this.injectParam({});
        return axios.get(API_URL + 'news', {
            params: {
                param: param,
            },
        });
    }
    getHomePrivateContent() {
        var param = this.injectParam({});
        return axios.get(API_URL + 'news', {
            headers: authHeader(),
            params: {
                param: param,
            },
        });
    }
    getPostPublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        //console.log(param);
        return axios.get(API_URL + 'post/' + id, {
            params: {
                param: param,
            },
        });
    }
    getSpecificPostPublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        //alert(JSON.stringify(param));
        return axios.get(API_URL + 'specificPost/' + id, {
            params: {
                param: param,
            },
        });
    }
    getGuidePublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        return axios.get(API_URL + 'guide/' + id, {
            params: {
                param: param,
            },
        });
    }
    getVideoPublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        return axios.get(API_URL + 'video/' + id, {
            params: {
                param: param,
            },
        });
    }
    getAlbumPublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        return axios.get(API_URL + 'album/' + id, {
            params: {
                param: param,
            },
        });
    }
    getDocPublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        return axios.get(API_URL + 'doc/' + id, {
            params: {
                param: param,
            },
        });
    }
    getSearchPageConfig(url = 'PageConfig/getSearchPageConfig', param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + url);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + url, {
            params: {
                param: param,
            },
        });
    }
    getNoticePageConfig(url = 'PageConfig/getNoticePageConfig', param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + url);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + url, {
            params: {
                param: param,
            },
        });
    }
    getCustomPageConfig(url = 'PageConfig/getNoticePageConfig', param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + url);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + url, {
            params: {
                param: param,
            },
        });
    }
    getSearchResult(param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log('Search');
        console.log(API_URL + 'search');
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + 'search', {
            params: {
                param: param,
            },
        });
    }
    getNoticeResult(param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log('Notice');
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + 'notice', {
            params: {
                param: param,
            },
        });
    }
    getNewestResult(param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log('Newest');
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + 'newest', {
            params: {
                param: param,
            },
        });
    }
    getCustomResult(url, param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log('getCustomResult');
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(url + 'notice', {
            params: {
                param: param,
            },
        });
    }
    getTagPageConfig(url = 'PageConfig/getTagPageConfig', param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + url);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + url, {
            params: {
                param: param,
            },
        });
    }
    getTagPublicContent(id, param = {}) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log('getTagPublicContent: ' + id);
        console.log(API_URL + 'tag/' + id);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + 'tag/' + id, {
            params: {
                param: param,
            },
        });
    }
    getStatisticPageConfig(url = 'PageConfig/getStatisticPageConfig', param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + url);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + url, {
            params: {
                param: param,
            },
        });
    }
    getDatabasePageConfig(url = 'PageConfig/getDatabasePageConfig', param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + url);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + url, {
            params: {
                param: param,
            },
        });
    }
    getPostTemplatePublicContent(id) {
        //console.log(id);
        var param = this.injectParam({});
        return axios.get(API_URL + 'postTemplate/' + id, {
            params: {
                param: param,
            },
        });
        //return axios.get('http://govcms.leanhtuan.work/govpage.php');
    }
    getCatPublicContent(id, type = 1, param = {}) {
        //console.log(id);
        param = this.injectParam(param);
        return axios.get(API_URL + 'cat/' + id + '?type=' + type, {
            params: {
                param: param,
            },
        });
    }
    getSubCatPublicContent(id, type = 1, param = {}) {
        //console.log(id);
        param = this.injectParam(param);
        return axios.get(API_URL + 'sub/' + id + '?type=' + type, {
            params: {
                param: param,
            },
        });
    }
    async getPublicHomeConfig() {
        var param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(('getPublicHomeConfig'));
        console.log(API_URL + 'home');
        console.log(param);
        console.log('**************************************');
        */
        return await axios.get(API_URL + 'home', {
            params: {
                param: param,
            },
        });
    }
    async getPublicPageConfig(code, endpoint = 'PageConfig/getPageConfig', param) {
        param = this.injectParam(param);
        //console.log(API_URL + endpoint + '/' + type + '/' + code);
        //return await axios.get(API_URL + endpoint + '/' + type + '/' + code);
        var paramStr = this.buildParam(param);
        //console.log('getPublicPageConfig: ' + API_URL + endpoint + '/' + code + '?type=' + type);
        //return await axios.get(API_URL + endpoint + '/' + code + '?type=' + type);
        //console.log(API_URL + endpoint + '/' + code + '?' + paramStr);
        return await axios.get(API_URL + endpoint + '/' + code + '?' + paramStr);
    }
    getPublicPageData(code, type, param) {
        //var rs = new Promise((resolve, reject) => {});
        param = this.injectParam(param);
        var rs = new Promise(() => {});
        switch (type) {
            case 1: // Danh mục
                rs = this.getCatPublicContent(code, type, param);
                break;
            case 2: // Chuyên mục
                rs = this.getSubCatPublicContent(code, type, param);
                break;
            case 3: // Tag
                rs = this.getTagPublicContent(code, param);
                break;
            default:
                break;
        }
        return rs;
    }
    getBlockPublicDataAsync(url, param) {
        param = this.injectParam(param);
        var APIUrl = functionService.occurrences(url, 'http') > 0 ? url : API_URL + url;
        return axios.get(APIUrl, {
            params: {
                param: param,
            },
        });
    }
    getBlockPublicData(url, param) {
        //console.log(API_URL + url);
        param = this.injectParam(param);
        var APIUrl = functionService.occurrences(url, 'http') > 0 ? url : API_URL + url;
        return axios.get(APIUrl, {
            params: {
                param: param,
            },
        });
    }
    getBlockPublicDataEndpoint(endpoint, param) {
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log(API_URL + endpoint);
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + endpoint, {
            params: {
                param: param,
            },
        });
    }
    getBlockPublicDataUrl(url, param) {
        param = this.injectParam(param);
        return axios.get(url, {
            params: {
                param: param,
            },
        });
    }
    getUrl(url, param) {
        param = this.injectParam(param);
        return axios.get(url, {
            params: {
                param: param,
            },
        });
    }
    getUrls(urls, param) {
        param = this.injectParam(param);
        var returnData = [];
        const requestArr = urls.map(async item => {
            let waitForThisData = await this.getBlockPublicData(item.url, param);
            console.log(waitForThisData);
            return waitForThisData;
        });
        // eslint-disable-next-line no-unused-vars
        Promise.all(requestArr).then((data) => {
            /*
            console.log('getUrls')
            console.log('----------------------------------------');
            console.log(data);
            console.log('----------------------------------------');
            */
            //returnData.push(data);
            //returnData = data;
            // eslint-disable-next-line no-unused-vars
            requestArr.forEach(function(value, index) {
                //returnData[index] = data[index];
                //console.log(index);
                returnData.push(value);
            });
        })
        return returnData;
    }
    async getAllBlockPublicDataByTypeSequence(data) {
        var response = {};
        response = await this.getBlockPublicDataByTypeSequence(data);
        return response;
    }
    async getBlockPublicDataByTypeSequence(data) {
        var
            returnData = {};
        var datatype = '';
        if (Object.prototype.hasOwnProperty.call(data, 'data')) {
            if (Object.prototype.hasOwnProperty.call(data.data, 'dataType')) {
                datatype = data.data.dataType;
                var dataList = Object.keys(data.data.dataList).length > 0 ? data.data.dataList : [];
                switch (datatype) {
                    case 0:
                        break;
                    case '1':
                        console.log('type 1');
                        if (dataList.length > 0) {
                            returnData = [];
                            //let promises = [];
                            /*
                            //for await (const dataItem of dataList) {
                            for (const dataItem of dataList) {
                                console.log('dataItem.dataAPI: ' + dataItem.dataAPI);

                                this.getBlockPublicData(dataItem.dataAPI, dataItem).then(response => {
                                    console.log('***************************************');
                                    console.log(response);
                                    console.log('***************************************');
                                    //returnData.push(response);
                                    promises.push(response);

                                })

                            }
                            */

                            const requestArr = dataList.map(async dataItem => {
                                let waitForThisData = await this.getBlockPublicData(dataItem.dataAPI, dataItem);
                                console.log(waitForThisData);
                                return waitForThisData;
                            });

                            Promise.all(requestArr).then((data) => {
                                    console.log('resolved promise.all')
                                    console.log('----------------------------------------');
                                    console.log(data);
                                    console.log('----------------------------------------');
                                    //returnData.push(data);
                                    //returnData = data;
                                    requestArr.forEach(function(value, index) {
                                        //returnData[index] = data[index];
                                        console.log(index);
                                        returnData.push(value);
                                    });
                                })
                                //returnData = promises;
                            console.log(returnData);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return (returnData);
    }

    async getAllBlockPublicDataByType(data) {
        var response = {};
        response = await this.getBlockPublicDataByType(data);
        return response;
    }
    async getBlockPublicDataByType(data) {
        var
            returnData = {};
        var datatype = '';
        if (Object.prototype.hasOwnProperty.call(data, 'data')) {
            if (Object.prototype.hasOwnProperty.call(data.data, 'dataType')) {
                //console.log(data.data);
                datatype = data.data.dataType;
                /*
                console.log('*******************************');
                console.log(data.data.dataList);
                console.log(Object.keys(data.data.dataList).length);
                console.log('*******************************');
                */
                var dataList = Object.keys(data.data.dataList).length > 0 ? data.data.dataList : [];
                switch (datatype) {
                    case 0:
                        break;
                    case '1':
                        //console.log('type 1');
                        if (dataList.length > 0) {
                            returnData = [];
                            //let promises = [];                            
                            /*
                            for await (const dataItem of dataList) {
                                if (Object.prototype.hasOwnProperty.call(dataItem, 'dataAPI')) {
                                    //var dataResp = await this.getBlockPublicData(dataItem.dataAPI, dataItem).then(response => { return response });
                                    var dataResp = await this.getBlockPublicData(dataItem.dataAPI, dataItem);
                                    console.log(dataResp);
                                    if (typeof(dataResp) == 'object' && Object.prototype.hasOwnProperty.call(dataResp, 'data')) {
                                        console.log('aaaaaaaaaaaaaaaaaaaaaaaaa');
                                        console.log(dataResp);
                                        promises.push(dataResp);
                                        returnData.push(dataResp);
                                    } else {
                                        returnData.push({});
                                    }
                                }
                            }
                            console.log('---------------------------------------');
                            Promise.all(promises).then(responses => console.log(responses));
                            */
                            /*
                            await Promise.all(dataList.map(dataItem =>
                                this.getBlockPublicData(dataItem.dataAPI, dataItem).then(response => {
                                    returnData.push(response);
                                })
                            ));
                            */
                            //await Promise.all(dataList.map((dataItem, index) =>
                            await Promise.allSettled(dataList.map((dataItem, index) =>
                                this.getBlockPublicData(dataItem.dataAPI, dataItem).then(response => {
                                    //returnData.push(response);
                                    returnData[index] = response;
                                })
                            ));
                        }
                        break;
                    case '4':
                        //console.log(datatype);
                        if (dataList.length > 0) {
                            returnData = [];
                            await Promise.allSettled(dataList.map((dataItem, index) =>
                                this.getBlockPublicData(dataItem.dataAPI, dataItem).then(response => {
                                    //returnData.push(response);
                                    returnData[index] = response;
                                })
                            ));
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return (returnData);
    }
    async getaAlbumPictures(code, param) {
        //console.log('getaAlbumPictures');
        var returnData = [];
        var endpoint = 'AlbumPicture/' + code;
        returnData = this.getBlockPublicDataEndpoint(endpoint, param);
        return returnData;
    }
    async getRelatedPost(code, param) {
        var returnData = [];
        var endpoint = 'Related/' + code;
        returnData = this.getBlockPublicDataEndpoint(endpoint, param);
        return returnData;
    }
    async getRelatedTopicPost(code, param) {
        var returnData = [];
        var endpoint = 'RelatedTopic/' + code;
        returnData = this.getBlockPublicDataEndpoint(endpoint, param);
        return returnData;
    }
    async getMorePost(code, param) {
        var returnData = [];
        var endpoint = 'MorePost/' + code;
        returnData = this.getBlockPublicDataEndpoint(endpoint, param);
        return returnData;
    }
    async getMediaPost(code, param) {
        var returnData = [];
        var endpoint = 'media/' + code;
        returnData = this.getBlockPublicDataEndpoint(endpoint, param);
        return returnData;
    }
    async getMediaPostByExtID(param) {
        var returnData = [];
        var endpoint = 'media';
        returnData = this.getBlockPublicDataEndpoint(endpoint, param);
        return returnData;
    }
    async getDocFilter() {
        var returnData = [];
        var endpoint = 'other/getDocSearchFilter';
        returnData = this.getBlockPublicDataEndpoint(endpoint, {});
        return returnData;
    }
    async getGuideFilter() {
        var returnData = [];
        var endpoint = 'other/getGuideSearchFilter';
        returnData = this.getBlockPublicDataEndpoint(endpoint, {});
        return returnData;
    }
    getBreadCrumb(code, type, param) {
        //var rs = new Promise(() => {});
        var rs = this.doGetBreadCrumb(code, type, param);
        return rs;
    }
    doGetBreadCrumb(id, type = 1, param = {}) {
        //console.log(id);
        param = this.injectParam(param);
        /*
        console.log('**************************************');
        console.log('doGetBreadCrumb')
        console.log(API_URL + 'breadcrumb/' + id + '?type=' + type + '&lang=' + localStorage.getItem('lang'));
        console.log(param);
        console.log('**************************************');
        */
        return axios.get(API_URL + 'breadcrumb/' + id + '?type=' + type + '&lang=' + localStorage.getItem('lang'), {
            params: {
                param: param,
            },
        });
    }
    async getHitCounter(data, param) {
        var
            returnData = {};
        if (Object.prototype.hasOwnProperty.call(data, 'data')) {
            if (Object.prototype.hasOwnProperty.call(data.data, 'dataType')) {
                //console.log(data.data);
                /*
                console.log('*******************************');
                console.log(data.data.dataList);
                console.log(Object.keys(data.data.dataList).length);
                console.log('*******************************');
                */
                var dataList = Object.keys(data.data.dataList).length > 0 ? data.data.dataList : [];
                if (dataList.length > 0) {
                    returnData = [];
                    await Promise.allSettled(dataList.map((dataItem, index) =>
                        this.getBlockPublicData(dataItem.dataAPI, param).then(response => {
                            //returnData.push(response);
                            returnData[index] = response;
                        })
                    ));
                }
            }
        }
        return (returnData);
    }

    async getHitsData() {
        let param = {};
        param = this.injectParam(param);
        var rs = new Promise(() => {});
        var endpoint = 'HitCounter/1';
        //var param = { 'type': type };
        rs = axios.get(API_URL + endpoint, {
            params: {
                param: param,
            },
        });
        return rs;
    }

    buildParam(param) {
        return Object.entries(param).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
    }
    updatePostHits(id, param) {
        param = this.injectParam(param);
        var rs = new Promise(() => {});
        var endpoint = 'UpdateHits/' + id;
        //var param = { 'type': type };
        rs = axios.get(API_URL + endpoint, {
            params: {
                param: param,
            },
        });
        return rs;
    }
    doSubscribe(email, type, service) {
        var rs = new Promise(() => {});
        var endpoint = 'subscribe/' + service;
        var param = { 'email': email, 'type': type, 'service': service };
        param = this.injectParam(param);
        rs = axios.get(API_URL + endpoint, {
            params: {
                param: param,
            },
        });
        return rs;
    }
    injectParam(param) {
        let lang = localStorage.getItem('lang');
        if (typeof(param) != 'object') {
            param = {};
        }
        param['lang'] = lang;
        //new URL(location.href).searchParams.get('page')
        //console.log(new URL(location.href).searchParams);
        //alert(new URL(location.href).searchParams.get('token'));
        let customParam = new URLSearchParams(window.location.search);
        if (customParam.has('env')) {
            if (customParam.get('env') == "dev") {
                param['env'] = "dev";
            }
        }
        if (customParam.has('task')) {
            if (customParam.get('task') == "preview") {
                param['task'] = "preview";
            }
        }
        //alert(customParam.has('token'));
        if (customParam.has('token')) {
            //param['token'] = encodeURIComponent(customParam.get('token'));
            param['token'] = customParam.get('token');
        }
        //console.log(param);
        //console.log('param.lang: ' + param.lang);
        //console.log(param.lang);
        return param;
    }
}

export default new PublicService();