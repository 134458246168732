<template>
  <div v-if="typeof(postitem) == 'object' && Object.keys(postitem).length>0 && typeof(relatedPost) == 'object' && relatedPost.length>0">
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div class="post-related-topic-section">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content-related-wrapper post-related-topic-content-wrapper">
                 <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                <template v-for="(post, index) in relatedPost" :key="index">
                  <div class="post-related-topic-item">
                    <a class="post-related-topic-item-link" :href="genPostLink(post.type, post.id)" :title="displayEntity(post.title)">- {{displayEntity(post.title)}}</a>
                  </div>
                </template>
            </div>
        </div>
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";



export default {
  data() {
    return {
      relatedPost: [],
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  async created(){
    var param = {'type':this.postitem.type};
    this.getRelatedTopicPost(this.postitem.id, param);
  },
  computed: {},
  onMounted() {
    
  },
  mounted() {},
  watch:{
 
  },
  methods: {
    handlePageChange(event, page){
      //console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    getRelatedTopicPost(code, param) {
      PublicService.getRelatedTopicPost(code, param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.relatedPost = response.data;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    displayPostTags: function (tags, tagsView) {
      return FunctionService.displayPostTags(tags, tagsView);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
  },
};
</script>

<style></style>
