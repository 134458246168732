<template>
  <section v-if="typeof(blockData)=='object' && blockData.length>0" class="section-wrapper section-10-wrapper" :style="sectionStyle">
    <!--<pre>{{ JSON.stringify(blockData[0], null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <div class="container section-10-container">
      <div class="row">
        <div class="col-xl-12">
          <a v-if="(componentData.data.dataList[0].link).length>0" class="section-10-section-title-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
            <h1 class="section-10-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
          </a>
          <h1 v-else class="section-10-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
        </div>
      </div>
      <div class="row">
        <div
          class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
        >
          <div>
            <div class="section-10-slide">
              <div class="section-10-slide-container">
                <div class="slider">
                  <div v-if="blockData[0]" :id="'section-10-slider-' + componentData.id" class="section-10-slider owl-carousel owl-theme">
                    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                    <template v-for="(post, index) in blockData[0]"
                    :key="index"
                    >
                        <div class="slider-card">
                            <div
                                class="d-flex justify-content-center align-items-center image-wrapper"
                            >
                                <a v-if="(typeof(post.link)=='string' && (post.link).length>0)" :href="genPostLink(componentData.type, post.id)" :target="typeof(post.target)=='string' && (post.target).length>0?post.target:'_blank'">
                                    <img :src="getImages(post.content, post.images)" :alt="post.title" />
                                </a>
                                <img v-else :src="getImages(post.content, post.images)" :alt="post.title" />
                            </div>
                            <div class="section-10-post-title-wrapper"><a class="section-10-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 55)}}</a></div>
                        </div>
                    </template>
                    <!-- eslint-enable -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import "owl.carousel";
//import Vue from 'vue'

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

//import owlCarousel from 'owl.carousel'

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      //blockTitle: [],
    };
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
  computed: {
    sectionStyle() {
      let newStyle = {};
      if (this.componentData.data.bgImg.length > 0) {
        newStyle.backgroundImage = "url(" + this.componentData.data.bgImg + ")";
        if (
          this.componentData.data.bgImgFixed.length > 0 &&
          this.componentData.data.bgImgFixed == 1
        ) {
          newStyle.backgroundAttachment = "fixed";
        }
      } else {
        if (this.componentData.data.bgColor.length > 0) {
          newStyle.backgroundColor = this.componentData.data.bgColor;
        }
      }
      return newStyle;
    },
  },
  components: {
    //BannerItem
  },
  mounted() {
    this.initOwl();
  },
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            console.log(dt.data);
            allData.push(dt.data);
            //vm.blockData.push(dt.data.child);
            //vm.blockData.push(dt.data);

            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }

            //this.blockData.push(dt.data);
            /*
            console.log(cfgData.data.dataList[dtCt]);
            if(typeof(cfgData.data.dataList[dtCt])=='object' && typeof(cfgData.data.dataList[dtCt].dataHeadLine)=='string'){
                vm.blockTitle.push(
                cfgData.data.dataList[dtCt].dataHeadLine.length > 0
                    ? cfgData.data.dataList[dtCt].dataHeadLine
                    : ""
                );
            }
            dtCt++;
            */
            vm.$nextTick(function () {
               vm.initOwl();
            });
            vm.initOwl();
          });
        });
        //console.log(this.blockData);
        vm.initOwl();
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    initOwl() {
      /*
      $(".section-10-slider").owlCarousel({
        loop: true,
        autoHeight: false,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        dots: true,
        nav: false,
        navText: [
          '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          '<i class="fa fa-angle-right" aria-hidden="true"></i>',
        ],
        //items: 2,
        lazyLoad: true,
        responsiveClass: true,
        margin: 20,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 5,
          },
        },
      });
      */
      //console.table(this.componentData);
      var owl = $("#section-10-slider-" + this.componentData.id);
      if (owl.length > 0) {
        owl.owlCarousel({
          loop: true,
          autoHeight: false,
          //autoplay: false,
          //autoplayTimeout: 5000,
          autoplayHoverPause: true,
          dots: true,
          nav: false,
          navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>',
          ],
          //items: 2,
          lazyLoad: true,
          responsiveClass: true,
          margin: 20,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 5,
            },
          },
        });

        if(typeof(this.componentData.data.slideInterval)=='string' && typeof(parseInt(this.componentData.data.slideInterval))=='number'){
          if(parseInt(this.componentData.data.slideInterval)>0){
            owl.data('owl.carousel').options.autoplay = true;
            owl.data('owl.carousel').options.autoplayTimeout = this.componentData.data.slideInterval;
            owl.trigger( 'refresh.owl.carousel' );
          }
        }
      }

    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
