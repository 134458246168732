<template>
    <section :class="'section-wrapper section-menu-topV2-wrapper section-menu-topV2-wrapper-'+lang" :style="sectionStyle">
        <!--
        <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
        <pre>{{ JSON.stringify(blockData, null, 2) }}</pre>
        <pre>{{ blockData}}</pre>
        -->
        <div class="container-fluid section-menu-topV2-container d-xl-block d-lg-block d-md-none d-sm-none d-xs-none d-none">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="section-menu-topV2-inner-container">
                    <div class="row align-items-center">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <div class="row align-items-center">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div class="menu-top-block">
                              <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                              <template v-if="typeof(blockData[0]) == 'object' && blockData[0].length>0">
                                <div class="site-logo">
                                  <a class="site-logo-1" :href="blockData[0][0].link" :target="(blockData[0][0].target==0?'_self':'_blank')"><img :src="getImages('', blockData[0][0].images)"/></a>
                                  <a class="site-logo-2" v-if="typeof(blockData[0][1]) == 'object'" :href="blockData[0][1].link" :target="(blockData[0][1].target==0?'_self':'_blank')"><img :src="getImages('', blockData[0][1].images)"/></a>
                                  <div class="menu-top-link-wrapper">
                                    <div class="menu-top-link-container">
                                        <span class="menu-top-date">{{toDayStr}}</span>
                                        <a v-if="lang!='en'" class="lang-link lang-link-en" data-lang="en" v-on:click="changeLangLink($event)">English</a>
                                        <a v-if="lang!='vi'" class="lang-link lang-link-vi" data-lang="vi" v-on:click="changeLangLink($event)">Tiếng Việt</a>
                                        <!--<a class="lang-link lang-link-jp" data-lang="jp" v-on:click="changeLangLink($event)">日本語</a>-->
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <!-- eslint-enable -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {getCurrentInstance, ref} from "vue";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import BannerItem from "./BannerItemCmp";

export default {
  setup(){
    //let lang = ref('');
    //let displayLang = ref('');
    let baseUrl = ref('');

    const app= getCurrentInstance();
    //lang = app.appContext.config.globalProperties.siteLang
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    /*
    if(lang!='en'){
      displayLang = 'en';
    }
    */
    return {
      //lang,
      //displayLang,
      baseUrl
    }
  },
  props: ["loggedIn", "componentData", "allData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      searchText: "",
      searchAction: "/search",
      lang: localStorage.getItem('lang'),
    };
  },
  created(){ 
    var vm = this;
    if(typeof(this.componentData.data.dataList)=='object'){
      this.getBlockData(this.componentData, vm);
    }
  },
  mounted(){
    this.toDayStr = this.getToDay(this.$t);
  },
  computed: {
    sectionStyle() {
      let newStyle = {};
      if (this.componentData.data.bgImg.length > 0) {
        newStyle.backgroundImage = "url(" + this.componentData.data.bgImg + ")";
        if (
          this.componentData.data.bgImgFixed.length > 0 &&
          this.componentData.data.bgImgFixed == 1
        ) {
          newStyle.backgroundAttachment = "fixed";
        }
      } else {
        if (this.componentData.data.bgColor.length > 0) {
          newStyle.backgroundColor = this.componentData.data.bgColor;
        }
      }
      return newStyle;
    },
  },
  components: {
    //BannerItem
  },
  methods: {
    changeLang(event){
      var langSelected = event.target.value;
      if(langSelected!='vi'){
        this.$router.push("/"+langSelected+'/');
        //this.$router.go('http://localhost:8080/'+langSelected);
        localStorage.setItem('lang', langSelected);
        //window.location.href = 'http://localhost:8080/'+langSelected;
        window.location.href = this.baseUrl+'/'+langSelected;
      }
      else{
        this.$router.push("/");
      }
    },
    changeLangLink(event){
      //var langSelected = event.target.value;
      var langSelected = event.currentTarget.getAttribute('data-lang');
      if(langSelected!='vi'){
        //this.$router.push("/"+langSelected+'/');
        this.$router.push("/"+langSelected);
        //this.$router.go('http://localhost:8080/'+langSelected);
        localStorage.setItem('lang', langSelected);
        //window.location.href = 'http://localhost:8080/'+langSelected;
        window.location.href = this.baseUrl+'/'+langSelected;
      }
      else{
        //this.$router.push("/");
        window.location.href = '/';
      }
    },
    /*
    submit : function(){
      this.$refs.form.submit()
    },
    */
    submit(){
      this.$router.push((localStorage.getItem('lang') && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText);
      this.searchAction = (localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText;
      //this.$refs.form.submit();
    },
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    getToDay($t){
      return FunctionService.getToDay($t);
    }
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>