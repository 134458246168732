<template>
    <section class="section-wrapper section-6-wrapper" :style="sectionStyle">
        <!--<pre>{{ JSON.stringify(blockData, null, 2) }}</pre>-->
        <div class="container-fluid section-6-container">
            <div class="row">
                <div class="col text-center">
                    <a v-if="(componentData.data.link).length>0" class="section-6-section-title-link" :href="componentData.data.link" :target="componentData.data.target==0?'_self':'_blank'">
                        <h1 class="section-wrapper-title section-6-wrapper-title">{{ componentData.data.headline }}</h1>
                    </a>
                    <h1 v-else class="section-wrapper-title section-6-wrapper-title">{{ componentData.data.headline }}</h1>
                </div>
            </div>
            <div class="row g-0">
                <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-21 col-12">
                    <div class="section-6-content" :style="((componentData.data.bgColor).length?'background-color:'+componentData.data.bgColor+';':'')">
                        <div class="row g-0">
                            <div class="section-6-left-content-wrapper col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-21 col-12 left-col">
                                <div class="section-6-left-content">
                                    <div class="row g-0">
                                        <div class="col col-xl-12">
                                            <div class="section-6-post-item-title-wrapper">
                                                <a v-if="(componentData.data.dataList[0].link).length>0" class="section-6-section-title-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                                                    <h1 class="section-6-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
                                                </a>
                                                <h1 v-else class="section-6-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-0">
                                        <div class="col col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-21 col-12">
                                            <div v-if="typeof(blockData[0])=='object' && typeof(blockData[0][0])=='object'" class="section-6-post-item section-6-post-item-big">
                                                <div class="image-wrapper">
                                                  <a class="" :href="genPostLink(blockData[0][0].type,blockData[0][0].id)" v-lazy-container="{ selector: 'img' }">
                                                    <img :data-src="getImages(blockData[0][0].summary+blockData[0][0].content, blockData[0][0].images)" />
                                                  </a>
                                                </div>
                                                <div class="section-6-post-title-wrapper"><a class="section-6-post-title-link" :href="genPostLink(blockData[0][0].type,blockData[0][0].id)" :title="displayEntity(blockData[0][0].title)">{{shorten(displayEntity(blockData[0][0].title), 80)}}</a></div>
                                            </div>
                                        </div>
                                        <div class="col col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-21 col-12">
                                            <div v-if="typeof(blockData[0])=='object'" class="row">
                                                <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                                <template v-for="(post, index) in blockData[0].slice(1,(blockData[0]).length)"
                                                :postitem="post" :key="index"
                                                >
                                                <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-21 col-12">
                                                    <div :class="(index+2==blockData[0].length?'section-6-post-item section-6-post-item-small section-6-post-item-small-last':'section-6-post-item section-6-post-item-small')">
                                                        <div class="row">
                                                            <div class="col col-xl--6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="image-wrapper" v-lazy-container="{ selector: 'img' }"><img :data-src="getImages(post.summary+post.content, post.images)" /></div>
                                                            </div>
                                                            <div class="col col-xl--6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="section-6-post-title-wrapper"><a class="section-6-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 40)}}</a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </template>
                                                <!-- eslint-enable -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section-6-right-content-wrapper col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-21 col-12 right-col">
                                <div class="section-6-right-content">
                                    <div class="row">
                                        <div class="col col-xl-12">
                                            <div class="section-6-post-item-title-wrapper">
                                              <a v-if="(componentData.data.dataList[1].link).length>0" class="section-6-section-title-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">
                                                    <h1 class="section-6-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
                                              </a>
                                              <h1 v-else class="section-6-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="section-6-slide-wrapper">
                                                <div class="section-6-slide">
                                                    <div class="section-6-slide-container">
                                                        <div class="slider">
                                                            <div v-if="typeof(blockData[1])=='object'" id="section-6-slider" class="owl-carousel owl-theme">
                                                                <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                                                <template v-for="(post, index) in blockData[1]"
                                                                :postitem="post" :key="index"
                                                                >
                                                                <div :class="(index==0?'item slider-card active':'item slider-card')">
                                                                    <div class="d-flex justify-content-center align-items-center image-wrapper">
                                                                        <a class="" :href="genPostLink(post.type,post.id)" v-lazy-container="{ selector: 'img' }">
                                                                          <img :data-src="getImages(post.summary+post.content, post.images)" alt />
                                                                        </a>
                                                                    </div>
                                                                    <a class="section-6-right-content-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">
                                                                        <h5 class="section-6-right-content-post-title">{{shorten(displayEntity(post.title), 70)}}</h5>
                                                                    </a>
                                                                </div>
                                                                </template>
                                                                <!-- eslint-enable -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//import BannerItem from "./BannerItemCmp";

import 'owl.carousel';
//import Vue from 'vue'
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import jQuery from "jquery";

const $ = jQuery;
window.$ = $;

//import owlCarousel from 'owl.carousel' 

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      //blockTitle: [],
    };
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
  computed: {
      sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            //newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        return newStyle
    }
  },
  components: {
    //BannerItem
  },
  mounted(){
      this.initOwl();
  },   
  methods: {
      getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
            /*
            console.log(cfgData.data.dataList[dtCt]);
            if(typeof(cfgData.data.dataList[dtCt])=='object' && typeof(cfgData.data.dataList[dtCt].dataHeadLine)=='string'){
                vm.blockTitle.push(
                cfgData.data.dataList[dtCt].dataHeadLine.length > 0
                    ? cfgData.data.dataList[dtCt].dataHeadLine
                    : ""
                );
            }
            dtCt++;
            */

           vm.$nextTick(function () {
               vm.initOwl();
            });

          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    initOwl(){
        //console.log('section-6-slider');
        //console.log($('#section-6-slider'));
          $('#section-6-slider').owlCarousel({
            loop: true,
            margin: 10,
            autoHeight: false,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            dots: true,
            nav: false,
            navigation: false,
            navText: ['', ''],
            //items: 2,
            lazyLoad: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items: 2,
                    nav: false
                },
                1000: {
                    items: 3,
                    nav: false,
                }
            }
        })
      }
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>