<template>
    <section class="section-wrapper section-5-wrapper" :style="sectionStyle">
        <div class="container-fluid section-5-container">
            <div class="row">
                <div class="col text-center">
                    <a v-if="(componentData.data.link).length>0" class="section-5-section-title-link" :href="componentData.data.link" :target="componentData.data.target==0?'_self':'_blank'">
                        <h1 class="section-wrapper-title section-5-wrapper-title">{{ componentData.data.headline }}</h1>
                    </a>
                    <h1 v-else class="section-wrapper-title section-5-wrapper-title">{{ componentData.data.headline }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div class="section-5-col-wrapper">
                        <div class="row g-0 section-5-row-content">
                            <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                            <template v-for="(post, index) in blockData[0]"
                            :postitem="post" :key="index"
                            >
                            <div class="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div class="section-post-item section-5-post-item">
                                    <div class="image-wrapper">
                                      <a class="" :href="genPostLink(post.type,post.id)" v-lazy-container="{ selector: 'img' }">
                                        <img class="section-5-post-item-img" :data-src="getImages(post.summary+post.content, post.images)" />
                                      </a>
                                    </div>
                                    <div class="section-5-post-title-wrapper"><a class="section-5-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 80)}}</a></div>
                                </div>
                            </div>
                            </template>
                            <!-- eslint-enable -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import BannerItem from "./BannerItemCmp";
export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      //blockTitle: [],
    };
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
  computed: {
      sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    //BannerItem
  },
  methods: {
      getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
            /*
            console.log(cfgData.data.dataList[dtCt]);
            if(typeof(cfgData.data.dataList[dtCt])=='object' && typeof(cfgData.data.dataList[dtCt].dataHeadLine)=='string'){
                vm.blockTitle.push(
                cfgData.data.dataList[dtCt].dataHeadLine.length > 0
                    ? cfgData.data.dataList[dtCt].dataHeadLine
                    : ""
                );
            }
            dtCt++;
            */
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    }
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>