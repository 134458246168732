<template>
  <div v-if="typeof postitem == 'object'">
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div class="search-content-wrapper">
      <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <label for="txtkey">{{$t('tags')}}</label>
              <input v-model="searchText" v-on:keyup.enter="submit()" type="text" name="txtkey" id="txtkey" class="form-control search-filter-control" :placeholder="$t('search_keyword_input')"/>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <label for="search_type">{{$t('search_datatype_label')}}</label>
              <select v-model="search_type" name="search_type" id="search_type" class="form-control search-filter-control">
                    <option value="1">{{$t('search_datatype1')}}</option>
                    <option value="4">{{$t('search_datatype4')}}</option>
                    <option value="5">{{$t('search_datatype5')}}</option>
                    <option value="6">{{$t('search_datatype6')}}</option>
                    <option value="3">{{$t('search_datatype3')}}</option>
                    <option value="2">{{$t('search_datatype2')}}</option>								
              </select>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
             <div class="form-group">
              <label for="groupcode">{{$t('search_category')}}</label>
              <select v-model="groupcode" name="groupcode" id="groupcode" class="form-control search-filter-control">
                <option value="" selected="selected">--Chọn--</option>
                <option value="01">Giới thiệu Cục Du lịch Quốc gia Việt Nam</option>
                <option value="0105">&nbsp;&nbsp;&nbsp;Chức năng, nhiệm vụ</option>
                <option value="0110">&nbsp;&nbsp;&nbsp;Cơ cấu tổ chức</option>
                <option value="0115">&nbsp;&nbsp;&nbsp;Lịch sử ngành</option>
                <option value="0125">&nbsp;&nbsp;&nbsp;Một số hình ảnh tư liệu</option>
                <option value="25">Chiến lược, chính sách</option>
                <option value="2501">&nbsp;&nbsp;&nbsp;Chiến lược phát triển du lịch</option>
                <option value="2505">&nbsp;&nbsp;&nbsp;Quy hoạch du lịch</option>
                <option value="2510">&nbsp;&nbsp;&nbsp;Chương trình, đề án</option>
                <option value="20">Hệ thống văn bản</option>
                <option value="2010">&nbsp;&nbsp;&nbsp;Tra cứu văn bản</option>
                <option value="07">Thủ tục hành chính</option>
                <option value="0701">&nbsp;&nbsp;&nbsp;Hướng dẫn thủ tục hành chính</option>
                <option value="0703">&nbsp;&nbsp;&nbsp;Hướng dẫn nộp phí, lệ phí và trả kết quả</option>
                <option value="09">Quản lý chuyên ngành</option>
                <option value="0901">&nbsp;&nbsp;&nbsp;Quản lý chung</option>
                <option value="0905">&nbsp;&nbsp;&nbsp;Quản lý lữ hành</option>
                <option value="0910">&nbsp;&nbsp;&nbsp;Quản lý cơ sở lưu trú</option>
                <option value="0915">&nbsp;&nbsp;&nbsp;Xúc tiến du lịch</option>
                <option value="0920">&nbsp;&nbsp;&nbsp;Hợp tác quốc tế</option>
                <option value="0925">&nbsp;&nbsp;&nbsp;Đào tạo, tổ chức cán bộ</option>
                <option value="12">Số liệu thống kê</option>
                <option value="1205">&nbsp;&nbsp;&nbsp;Khách quốc tế đến</option>
                <option value="1210">&nbsp;&nbsp;&nbsp;Khách du lịch nội địa</option>
                <option value="1215">&nbsp;&nbsp;&nbsp;Tổng thu du lịch</option>
                <option value="1225">&nbsp;&nbsp;&nbsp;Cơ sở lưu trú</option>
                <option value="13">Tài liệu chuyên ngành</option>
                <option value="1305">&nbsp;&nbsp;&nbsp;Xúc tiến du lịch</option>
                <option value="1310">&nbsp;&nbsp;&nbsp;Cơ sở lưu trú</option>
                <option value="1315">&nbsp;&nbsp;&nbsp;Thống kê du lịch</option>
                <option value="1316">&nbsp;&nbsp;&nbsp;Báo cáo thường niên</option>
                <option value="1320">&nbsp;&nbsp;&nbsp;Đào tạo nhân lực</option>
                <option value="1325">&nbsp;&nbsp;&nbsp;Du lịch có trách nhiệm</option>
                <option value="1330">&nbsp;&nbsp;&nbsp;Nhãn du lịch xanh</option>
                <option value="1340">&nbsp;&nbsp;&nbsp;Tài liệu khác</option>
                <option value="35">Cơ quan du lịch địa phương</option>
                <option value="3502">&nbsp;&nbsp;&nbsp;Cơ quan du lịch địa phương</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
            <button class="btn search-page-btn" @click.stop.prevent="submit()">{{$t('search_btn')}}</button>
          </div>
        </div>
      <hr/>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="search-result-total"><span class="search-result-total-label">{{$t('search_result')}}: </span><span class="search-result-total-no">{{this.totalItem}}</span> {{this.total>1?$t('search_result_unit'):$t('search_result_units')}}</div>
        </div>
      </div>
      <div v-if="search_method==1" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <p @click="changeSearchMethod">{{$t('search_no_result_search_other')}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="search-result-wrapper" style="min-height: 50vh;">
            <div class="loader-wrapper">
              <div class="loader-container">
                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
            </div>
            <div class="row">
              <template v-for="(post, index) in postitem" :key="index">
                <div
                  class="section-default-list-news-item col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12"
                >
                  <div class="section-default-list-news-item-wrapper">
                    <div class="section-default-list-news-item-container">
                      <div
                        class="section-default-list-news-item-inner"
                      >
                        <div
                          class="section-default-list-news-item-img-container image-wrapper" v-lazy-container="{ selector: 'img' }"
                        >
                          <img
                            :data-src="getImages(post.summary + post.content, post.images)"
                            class="section-default-list-news-item-img"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="section-default-list-news-post-info-wrapper">
                      <div class="section-default-list-news-post-info-container">
                        <a
                          class="section-default-list-news-post-info-title-link"
                          :href="genPostLink(post.type, post.id)" :alt="displayEntity(post.title)" :title="displayEntity(post.title)"
                        >
                          <h1 class="section-post-info-title section-default-list-news-post-info-title">
                            {{ displayShortenEntityContent(post.title, 70) }} <span v-if="post.type != 4 && post.type != 5 && post.type != 6 && (post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                          </h1>
                        </a>
                        <div>
                          <p class="section-default-list-news-post-info-excerpt">
                            {{ displayShortenEntityContent(post.summary, 150) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- eslint-enable -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  data() {
    return {
      AppConst: this.$AppConst,
      postData: {},
      postitem: [],
      search_method: 1,
      search_type: typeof(this.$route.query.search_type)=='string' && (this.$route.query.search_type).length>0?this.$route.query.search_type:1,
      groupcode: typeof(this.$route.query.groupcode)=='string' && (this.$route.query.groupcode).length>0?this.$route.query.groupcode:"",
      searchText: typeof(this.$route.query.txtkey)=='string' && (this.$route.query.txtkey).length>0?this.$route.query.txtkey:"",
      counter: this.$.vnode.key,
      perPage: typeof(this.componentData)=='object' && Object.prototype.hasOwnProperty.call(this.componentData, "chlistLimitild") && parseInt(this.componentData.listLimit)?parseInt(this.componentData.listLimit):15,
      totalItem: 0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
      isLoading: 0,
    };
  },
  props: ["pageType", "parentComponentData", "componentData"],
  beforeMount() {
    //this.isLoading = 1;
  },
  created(){
    //alert(this.$route.query.txtkey);
    //this.isLoading = 1;
    if(typeof(this.$route.query.txtkey)=='string' && (this.$route.query.txtkey).length>0){
      this.searchText = this.$route.query.txtkey;
    }
    //this.searchPost();
  },
  computed: {},
  onMounted() {},
  mounted() {
    this.isLoading = 1;
    //alert(document.getElementsByClassName('loader-wrapper').length);
    $('.main-menu-search-wrapper').hide();
    document.getElementsByClassName('search-result-total')[0].style.visibility = 'hidden';
    this.searchPost();
  },
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    },
    isLoading(state){
      if(document.getElementsByClassName('loader-wrapper').length>0){
        if(state==1){
          document.getElementsByClassName('loader-wrapper')[0].style.visibility = 'visible';
        }
        else{
          document.getElementsByClassName('loader-wrapper')[0].style.visibility = 'hidden';
        }
      }
      if(document.getElementsByClassName('search-result-total').length>0){
        if(state==1){
          document.getElementsByClassName('search-result-total')[0].style.visibility = 'hidden';
        }
        else{
          document.getElementsByClassName('search-result-total')[0].style.visibility = 'visible';
        }
      }
    },
  },
  methods: {
    searchPost(){
      var param = {'limit': this.perPage, 'offset': this.currentPage, 'search_type': this.search_type, 'groupcode': this.groupcode, 'txtkey': this.searchText, 'method': this.search_method};
      //var param = {'limit': this.perPage, 'offset': this.currentPage};
      //console.log(param);
      this.isLoading = 1;
      PublicService.getSearchResult(param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    if (Object.prototype.hasOwnProperty.call(response.data, "child")) {
                      this.postitem = response.data.child;
                      this.totalItem = parseInt(response.data.info.total);
                      this.isLoading = 0;
                    }
                    else{
                      this.postitem = [];
                      this.totalItem = 0;
                      this.isLoading = 0;
                    }
                    //document.getElementsByClassName('search-result-total')[0].style.visibility = 'visible';
                  }
                  if(this.totalItem == 0){
                    //alert();
                    this.submit(0);
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    submit(methodVal=1){
      //this.$router.push((localStorage.getItem('lang') && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG ? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText);
      //this.searchAction = (localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText;
      //this.$router.push("/search?txtkey="+this.searchText); 
      this.search_method = methodVal;
      if(this.search_type != 1){
        this.search_method = 0;
      }
      var param = {'search_type': this.search_type, 'groupcode': this.groupcode, 'txtkey': this.searchText, 'method': this.search_method};
      var paramStr = this.buildParam(param);
      console.log('paramStr:');
      console.log(paramStr);
      this.$router.push((localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?"+paramStr); 
      this.searchPost();
    },
    changeSearchMethod(){
      this.search_method = 0;
      this.submit(this.search_method);
      //alert(this.search_method);
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
       this.currentPage = page;
       this.searchPost();
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    buildParam: function (param) {
      return FunctionService.buildParam(param);
    },
  },
};
</script>

<style>
.main-menu-search-wrapper{
  /*display: none !important;*/
}
</style>
