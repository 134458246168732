<template>
    <section class="section-wrapper section-2-wrapper">
        <!--
        <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
        <pre>{{ JSON.stringify(blockData, null, 2) }}</pre>
        -->
        <!--<pre>{{ blockData}}</pre>-->
        <div class="container-fluid section-main-menu-container">
            <div class="row">
              <div class="col-12">

                <div class="site-navbar-wrap">
                  <!--
                  <div class="site-navbar-top">
                    <div class="container py-3">
                      <div class="row align-items-center">
                        <div class="col-6">
                          <div class="d-flex mr-auto">
                            <a href="#" class="d-flex align-items-center mr-4">
                              <span class="icon-envelope mr-2"></span>
                              <span class="d-none d-md-inline-block">info@domain.com</span>
                            </a>
                            <a href="#" class="d-flex align-items-center mr-auto">
                              <span class="icon-phone mr-2"></span>
                              <span class="d-none d-md-inline-block">+1 234 4567 8910</span>
                            </a>
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <div class="mr-auto">
                            <a href="#" class="p-2 pl-0"><span class="icon-twitter"></span></a>
                            <a href="#" class="p-2 pl-0"><span class="icon-facebook"></span></a>
                            <a href="#" class="p-2 pl-0"><span class="icon-linkedin"></span></a>
                            <a href="#" class="p-2 pl-0"><span class="icon-instagram"></span></a>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  -->

                  <div class="site-navbar site-navbar-target js-sticky-header">
                    <div class="container-fluid main-menu-content-container">
                      <div class="row align-items-center">
                        <div class="col brand-logo-container">
                          <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                          <!--<template v-for="(post, index) in blockData[0]"
                          :postitem="post" :key="index"
                          >-->
                          <template v-if="typeof(blockData[0]) == 'object' && blockData[0].length>0">
                            <h1 class="my-0 site-logo"><a :href="blockData[0][0].link"><img :src="blockData[0][0].images"/></a></h1>
                          </template>
                          <!-- eslint-enable -->
                        </div>
                        <div class="col">
                          <nav class="site-navigation text-right d-xl-block d-lg-block d-md-none d-sm-none d-none" role="navigation">
                            <div class="container-fluid">
                              <div class="d-inline-block d-lg-none ml-md-0 mr-auto py-3"><a href="#" class="site-menu-toggle js-menu-toggle text-white"><span class="icon-menu h3"></span></a></div>

                              <ul class="site-menu main-menu js-clone-nav d-none d-lg-block">
                                <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                <template v-for="(post, index) in blockData[1]"
                                :postitem="post" :key="index"
                                >
                                  <li v-if="typeof(post.sub)=='object' && (post.sub).length>0" class="has-children">
                                    <a v-if="post.link" :href="post.link" :class="cCode==post.code?'active nav-link':'nav-link'">{{post.title}}</a>
                                    <a v-else :href="genCatLink(post.type, post.code)" :class="cCode==post.code?'active nav-link':(typeof(parentActiveMenu[post.code]) == 'string'?parentActiveMenu[post.code]:'nav-link')">{{post.title}}</a>
                                    <ul class="dropdown arrow-top">
                                      <template v-for="(sub, index1) in post.sub"
                                      :key="index1"
                                      >
                                        <li>
                                          <a v-if="sub.link" :href="sub.link" :class="cCode==sub.code?setActiveMenu(sub.code, 'active nav-link'):'nav-link'">{{sub.title}}</a>
                                          <a v-else :href="genCatLink(sub.type, sub.code)" :class="cCode==sub.code?setActiveMenu(sub.code, 'active nav-link'):'nav-link'">{{sub.title}}</a>
                                        </li>
                                      </template>
                                    </ul>
                                  </li>
                                  <li v-else class="">
                                    <a v-if="post.link" :href="post.link" :class="cCode==post.code?'active nav-link':'nav-link'">{{post.title}}</a>
                                    <a v-else :href="genCatLink(post.type, post.code)" :class="cCode==post.code?'active nav-link':'nav-link'">{{post.title}}</a>
                                  </li>
                                </template>
                                <!-- eslint-enable -->
                              </ul>
                            </div>
                          </nav>
                          
                          
                          <button id="mobile-menu-btn" class="btn btn-primary d-xl-none d-lg-none d-md-block d-sm-block d-block" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i class="fa fa-bars" aria-hidden="true"></i>
                          </button>

                          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                            <div class="offcanvas-header">
                              <!--<h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>-->
                              <form class="search-form" ref="form" :action="searchAction" method="GET">
                                <div class="input-group">
                                        <input v-on:keyup.enter="submit()" class="menu-top-search form-control border" type="text" :placeholder="$t('search_holder')" v-model="searchText" id="menu-top-search" v-on:keydown.enter.prevent="submit()">
                                        <span class="input-group-append menu-top-search-btn-wrapper">
                                            <button class="menu-top-search-btn btn btn-outline-secondary bg-white border-start-0 border ms-n3" type="button" @click.stop.prevent="submit()" data-bs-dismiss="offcanvas" aria-label="Close">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                </div>
                              </form>
                              <button type="button" class="mobile-menu-close btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                              <div class="offcanvas-body-content">
                                
                                
                                <div class="accordion" id="accordionExample">
                                   <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                  <template v-for="(post, index) in blockData[1]"
                                  :postitem="post" :key="index"
                                  >

                                    <div v-if="typeof(post.sub)=='object' && (post.sub).length>0" class="accordion-item"
                                      :postitem="post" :key="index">
                                      <h2 class="accordion-header" :id="'heading'+index">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" :aria-expanded="isActive" @click="hideshow()" :aria-controls="'collapse'+index">
                                          <a v-if="post.link" :href="post.link" class="nav-link single-nav-link"><span>{{post.title}}</span></a>
                                          <a v-else :href="genCatLink(post.type, post.code)" class="nav-link single-nav-link"><span>{{post.title}}</span></a>
                                        </button>
                                      </h2>
                                      <div :id="'collapse'+index" :class="isActive?'accordion-collapse collapse show':'accordion-collapse collapse'" :aria-labelledby="'heading'+index" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul class="dropdown arrow-top">
                                              <template v-for="(sub, index1) in post.sub"
                                              :key="index1"
                                              >
                                                <li>
                                                  <a v-if="sub.link" :href="sub.link" class="nav-link">{{sub.title}}</a>
                                                  <a v-else :href="genCatLink(sub.type, sub.code)" class="nav-link">{{sub.title}}</a>
                                                </li>
                                              </template>
                                            </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <a v-if="post.link" :href="post.link" class="nav-link single-nav-link">{{post.title}}</a>
                                      <a v-else :href="genCatLink(post.type, post.code)" class="nav-link single-nav-link">{{post.title}}</a>
                                    </div>
                                  </template>
                                  <!-- eslint-enable -->
                                </div>
                                
                              </div>
                              <div class="mobile-lang-selector">
                                <router-link to="/" :active-class="lang=='vi'?'active':''">Tiếng Việt</router-link>
                                <router-link to="/en" :active-class="lang=='en'?'active':''">English</router-link>
                              </div>
                              
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

        </div>
    </section>
</template>

<script>

import {ref, getCurrentInstance} from "vue";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import "jquery-sticky"

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

//import '/public/assets/css/menu.css';

export default {
  props: ["loggedIn", "componentData", "allData"],
  setup(){
    let lang = ref('');
    const app = getCurrentInstance();
    lang = app.appContext.config.globalProperties.siteLang
    return {
      lang
    }
  },
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      isActive: false,
      cCode: this.$route.params.id,
      parentActiveMenu: [],
    };
  },
  created(){ 
    var vm = this;
    if(typeof(this.componentData.data.dataList)=='object'){
      this.getBlockData(this.componentData, vm);
    }
  },
  mounted(){
    /*
    let menuScript = document.createElement('script')
    menuScript.setAttribute('src', '/public/assets/js/menu.js')
    document.head.appendChild(menuScript)
    */
    var siteSticky = function() {
      $(".js-sticky-header").sticky({topSpacing:0});
    };
    siteSticky();

    /*
    var siteMenuClone = function() {

      $('.js-clone-nav').each(function() {
        var $this = $(this);
        $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
      });


      setTimeout(function() {
        //console.log('menu log');
        var counter = 0;
        $('.site-mobile-menu .has-children').each(function(){
          var $this = $(this);
          
          $this.prepend('<span class="arrow-collapse collapsed">');

          $this.find('.arrow-collapse').attr({
            'data-toggle' : 'collapse',
            'data-target' : '#collapseItem' + counter,
          });

          $this.find('> ul').attr({
            'class' : 'collapse',
            'id' : 'collapseItem' + counter,
          });

          counter++;

        });

      }, 1000);

      $('body').on('click', '.arrow-collapse', function(e) {
        var $this = $(this);
        if ( $this.closest('li').find('.collapse').hasClass('show') ) {
          $this.removeClass('active');
        } else {
          $this.addClass('active');
        }
        e.preventDefault();  
        
      });

      $(window).resize(function() {
        var $this = $(this),
          w = $this.width();

        if ( w > 768 ) {
          if ( $('body').hasClass('offcanvas-menu') ) {
            $('body').removeClass('offcanvas-menu');
          }
        }
      })

      $('body').on('click', '.js-menu-toggle', function(e) {
        var $this = $(this);
        e.preventDefault();

        if ( $('body').hasClass('offcanvas-menu') ) {
          $('body').removeClass('offcanvas-menu');
          $this.removeClass('active');
        } else {
          $('body').addClass('offcanvas-menu');
          $this.addClass('active');
        }
      }) 

      // click outisde offcanvas
      $(document).mouseup(function(e) {
        var container = $(".site-mobile-menu");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ( $('body').hasClass('offcanvas-menu') ) {
            $('body').removeClass('offcanvas-menu');
          }
        }
      });
    }; 
    siteMenuClone();
    */
  },
  computed: {

  },
  components: {

  },
  methods: {
    submit(){
      this.$router.push((localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText);
      this.searchAction = (localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?txtkey="+this.searchText;
      //this.$refs.form.submit();
    },
    setActiveMenu(code, activeClass){
      if(typeof(code) == 'string' && code.length>2){
        var parentCode = code.substring(0, 2);
        this.parentActiveMenu[parentCode] = 'active nav-link';
      }
      return activeClass;
    },
    hideshow(){
      this.isActive = !this.isActive
      //document.getElementById(elm).classList.remove('show');
    },
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
              vm.blockData.push(dt.data.child);
            }
            else{
              vm.blockData.push(dt.data);
            }
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    genCatLink: function(type, id){
      return FunctionService.genCatLink(type, id);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>