import { createApp } from 'vue'
import { useRoute } from 'vue-router'
import App from './App.vue'
//import { baseUrl, siteUrl } from './helpers/url'
import router from './router'
import store from './store'
//import AppConst from './constant/constant'
import AppConst from './constant'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
//import VueMeta from 'vue-meta'
import { createHead } from '@vueuse/head'
//import { createMetaManager } from 'vue-meta'
//import VueMeta from 'vue-meta'


import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

// eslint-disable-next-line no-unused-vars
import VueSocialSharing from 'vue-social-sharing'
//import VueSocialSharing from '@/vue-social-sharing'

import Notifications from '@kyvg/vue3-notification'


import '@/styles/main.sass'
import i18n from './i18n'

import VueLazyload from 'vue-lazyload'

//Vue.use(VueLazyload)

/*
const loadimage = require('./assets/loading.gif')
const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1
})

new Vue({
    el: 'body',
    components: {
        App
    }
})
*/

//window.$ = window.jQuery = require('jquery');

const app = createApp(App)
const head = createHead()

app.config.globalProperties.siteLang = AppConst.PAGE_LANG
app.config.globalProperties.siteLocale = AppConst.PAGE_LOCALE
app.config.globalProperties.$baseUrl = AppConst.BASE_URL
app.config.globalProperties.$siteUrl = AppConst.SITE_URL
app.config.globalProperties.siteMeta = AppConst.SITE_META
app.config.globalProperties.useHead = head;

app.config.globalProperties.$AppConst = AppConst
app.use(router)
app.use(store)
app.use(useRoute)
app.use(i18n)
app.use(BootstrapVue3)
    /*app.use(VueMeta, {
            // optional pluginOptions
            refreshOnceOnNavigation: true
        })*/
    //app.use(createMetaManager())
app.use(head)
    //app.use(VueMeta)
    /*
    app.use(VueSocialSharing, {
        networks: {
            'fakeblock': 'https://fakeblock.com/share?url=@url&title=@title'
        }
    });
    */
app.use(VueSocialSharing)
app.use(Notifications)

app.use(VueLazyload)

//app.use(onMounted)
//app.use(AppConst)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')