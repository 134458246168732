<template>
  <div>
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(statisticData, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="statistic-title statistic-international-title">
                <div class="homepage-block-title">
                  <a class="top-nav-curr" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != AppConst.PAGE_LANG?'/'+lang:'')+'/statistic'">{{$t('statistic_cat_title')}}</a> | <a v-if="typeof(statisticData.cat) == 'object'" class="top-nav-curr" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/receipts'">{{statisticData.cat.title}}</a>
                </div>
              </div>
          <!--<pre>{{ JSON.stringify(statisticData, null, 2) }}</pre>-->
          <div v-html="htmlcontent"></div>      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, getCurrentInstance} from "vue";
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  setup() {
    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    return{
      baseUrl
    }
  },
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      htmlcontent: '',
      statisticyear: 2022,
      yearFilter: [],
      statisticData: [],
      statisticCode: '1215',
      monthList: [],
      sYear: '',
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang')
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  created() {
    //var dataUrl = this.baseUrl+'/StatisticGen/ receipts';
    //var dataUrl = 'http://demogov.leanhtuan.work/StatisticGen/receipts';
    var dataUrl = this.baseUrl+'/StatisticGen/receipts';
    
    if(this.occurrences(this.baseUrl,'localhost')){
      dataUrl = 'https://vietnamtourism.gov.vn/StatisticGen/receipts';
    }

    var param = { "code": this.statisticCode };
    this.getStatisticData(dataUrl, param);

    //var staticUrl = this.baseUrl+"/getStatistic/ receipts"
    //var staticUrl = "http://demogov.leanhtuan.work/getStatistic/receipts"
    var staticUrl = this.baseUrl+'/getStatistic/receipts';
    
    if(this.occurrences(this.baseUrl,'localhost')){
      staticUrl = 'https://vietnamtourism.gov.vn/getStatistic/receipts';
    }

    this.getStatisticAvaiable(staticUrl, param);
  },
  computed: {},
  onMounted() { },
  mounted() {
    
  },
  watch: {
    htmlcontent: {
             handler: function() {
                 this.$nextTick(() => {
                  

                });
           },
            deep: true
       },
     statisticData: {
             handler: function() {
                 this.$nextTick(() => {
                  //var sCode = (this.statisticCode).substring(0,2);
                  this.yearFilter = Object.prototype.hasOwnProperty.call(this.statisticData, "statistic") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic, "statistic") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic.statistic, "years")
                  ?this.statisticData.statistic.statistic.years:[];
                });
           },
            deep: true
       },
      
  },
  methods: {
    getStatisticData(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.htmlcontent = response.data;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getStatisticAvaiable(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.keys(response.data).length>0) {
            this.statisticData = response.data;
            //var sCode = (this.statisticCode).substring(0,2);
            //var sYear = document.getElementById('statistic-year').value;
            //var sYear = response.data.statistic.statistic.info.year;

            this.sYear = this.statisticData.statistic.statistic.info.year;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    changePeriod(event){
      var year = document.getElementById('statistic-year').value;
      //var period = document.getElementById('statistic-month').value;
      //var period = event.target.value;
      //alert(this.$router.currentRoute);
      console.log(event);
      this.$router.push('/statistic/receipts?year='+year);
      
      //var dataUrl = 'http://demogov.leanhtuan.work/StatisticGen/receipts';
      var dataUrl = this.baseUrl+'/StatisticGen/receipts';
    
      if(this.occurrences(this.baseUrl,'localhost')){
        dataUrl = 'https://vietnamtourism.gov.vn/StatisticGen/receipts';
      }

      var param = { "code": this.statisticCode, "year": year};
      this.getStatisticData(dataUrl, param);
    },
    dict_reverse(obj) {
      var new_obj= {}
      var rev_obj = Object.keys(obj).reverse();
      rev_obj.forEach(function(i) { 
        new_obj[i] = obj[i];
      })
      return new_obj;
    },
    naturalCompare(a, b) {
        var ax = [], bx = [];

        a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
        
        while(ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if(nn) return nn;
        }

        return ax.length - bx.length;
    },
    handlePageChange(event, page) {
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if (Object.keys(param).length > 0) {
        if (typeof (param.page) == 'string') {
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if (paramStr.length > 0) {
          paramStr = '&' + paramStr;
        }
      }
      this.$router.push("?page=" + page + paramStr);
      //this.$router.go(this.$router.currentRoute)
      this.$emit('content-page-clicked', page);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    occurrences: function(string, subString, allowOverlapping){
      return FunctionService.occurrences(string, subString, allowOverlapping);
    },
  },
};
</script>

<style>
</style>
