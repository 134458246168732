<!--
<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 pt-3 pb-3 bg-white from-wrapper">
          <h3>{{typeof(cat) == 'object' && cat != null && cat.hasOwnProperty('title') && typeof(cat.title)=='string'?cat.title:''}}</h3>
          <div v-if="posts.length > 0" class="col-12 mt-5 pt-3 pb-3 bg-white from-wrapper">
            <h3>Danh sách</h3>
            <hr />
            <CatItem
              v-for="(post, index) in posts"
              :key="post.id"
              :index="index"
              :post="post"
              :imgPlaceholder="imgPlaceholder"
            ></CatItem>
          </div>
        </div>
    </div>
  </div>
</template>
-->
<template>
  <div>
    <!--
    <pre>{{ JSON.stringify(content, null, 2) }}</pre>
    <pre>{{this.$route.name}}</pre>
    <pre>{{cRoute}}</pre>
    {{cCode}}
    -->
    <div v-if="isLoading == 0 && content.length > 0">
      <div v-for="(block, index) in content" :key="index">
        <!--
        <pre>{{ JSON.stringify(block, null, 2) }}</pre>  
        -->
        <component
          :is="block.data.component"
          :block="block"
          :key="block.data.id"
          :componentData="block.data"
          :parentComponentData="this.cat"
          :parentComponentDataItem="this.posts"
          @content-page-clicked="contentPageChanged"
          @search-filter-changed="searchFilterChanged"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
//import CatItem from "../cmps/CatItemCmp";
import PublicService from "../services/public.service";

import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import MenuTopCmp from "../cmps/MenuTopCmp";
import MenuTopCmpV2 from "../cmps/MenuTopCmpV2";
import MainMenuCmp from "../cmps/MainMenuCmp";
import MainMenuCmpV2 from "../cmps/MainMenuCmpV2";
import BannerSectionCmp from "../cmps/BannerSectionCmp";
import HomeSection1Cmp from "../cmps/HomeSection1Cmp";
import HomeSection2Cmp from "../cmps/HomeSection2Cmp";
import HomeSection3Cmp from "../cmps/HomeSection3Cmp";
import HomeSection4Cmp from "../cmps/HomeSection4Cmp";
import HomeSection5Cmp from "../cmps/HomeSection5Cmp";
import HomeSection6Cmp from "../cmps/HomeSection6Cmp";
import HomeSection7Cmp from "../cmps/HomeSection7Cmp";
import HomeSection8Cmp from "../cmps/HomeSection8Cmp";
import HomeSection9Cmp from "../cmps/HomeSection9Cmp";
import MainFooterCmp from "../cmps/MainFooterCmp";
import MainFooterCmpV2 from "../cmps/MainFooterCmpV2";
import HitCounterCmp from "../cmps/HitCounterCmp";
import ImageConnectCmp from "../cmps/ImageConnectCmp";
// Trang danh sach
import BannerDefaultListSectionCmp from "../cmps/BannerDefaultListSectionCmp";
import DefaultListSectionCmp from "../cmps/DefaultListSectionCmp";
import DefaultListSectionV2Cmp from "../cmps/DefaultListSectionV2Cmp";
import DefaultListSubSectionSubListCmp from "../cmps/DefaultListSubSectionSubListCmp";
import DefaultListSubSectionSubList1Cmp from "../cmps/DefaultListSubSectionSubList1Cmp";


export default {
  data() {
    return {
      cat_id: null,
      cat_index: null,
      posts: [],
      title: "",
      image: "",
      imgPlaceholder: "http://via.placeholder.com/300",
      cat: [],
      isLoading: 1,
      isLoaded: 0,
      content: {},
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      hasTemplate: 0,
      //perPage: 10,
      //totalItem: 100,
      currentPage: isNaN(this.$route.query.page)?1:this.$route.query.page,
      callType: 1,
    };
  },
  computed: {
    imgPreview() {
      return this.images == "" ? this.imgPlaceholder : this.images;
      //return this.images;
      /*
      var finalImg = "";
      if(this.image==""){
        //finalImg = this.getImages(this.summary+this.content, this.images);
      }
      return finalImg == "" ? this.imgPlaceholder : finalImg;
      */
    },
    strippedHtml() {
      let regex = /(<([^>]+)>)/gi;
      return this.summary.content.rendered.replace(regex, "");
    },
  },
  components: {
    MenuTopCmp,
    MenuTopCmpV2,
    MainMenuCmp,
    MainMenuCmpV2,
    BannerSectionCmp,
    HomeSection1Cmp,
    HomeSection2Cmp,
    HomeSection3Cmp,
    HomeSection4Cmp,
    HomeSection5Cmp,
    HomeSection6Cmp,
    HomeSection7Cmp,
    HomeSection8Cmp,
    HomeSection9Cmp,
    MainFooterCmp,
    MainFooterCmpV2,
    HitCounterCmp,
    ImageConnectCmp,
    BannerDefaultListSectionCmp,
    DefaultListSectionCmp,
    DefaultListSectionV2Cmp,
    DefaultListSubSectionSubListCmp,
    DefaultListSubSectionSubList1Cmp,
    //CatItem,
  },
  async created() {
    //this.get();
    //await this.getDataFromApi(this.cRoute, this.cCode);
    //var param = {'offset': this.currentPage, 'callType': this.callType};
    var param = this.$router.currentRoute.value.query;
    if(Object.keys(param).length>0){
      param.offset = this.currentPage;
      param.callType = this.callType;
    }
    else{
      param = {'offset': this.currentPage, 'callType': this.callType};
    }
    await this.getPageDataFromApi(this.cRoute, this.cCode, param);
  },
  methods: {
    getPageDataFromApi(type, code, param){
      //var param = {};
      //param['callType'] = 1;
      //alert(param.offset);
      PublicService.getPublicPageData(code, 2, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "child")) {
            this.posts = response.data.child;
            this.cat = response.data.parent;
            //this.totalItem = typeof(response.data.parent.info) == "object" && typeof(response.data.parent.info.total) == "string"?response.data.parent.info.total:0;
            param = {'type':type, 'position':'home'};
            PublicService.getPublicPageConfig(code, 'PageConfig/getPageConfig',param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.content = response.data;
                    this.isLoading = 0;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );

            if(typeof(response.data.parent)=='object' && typeof(response.data.parent.hasTemplate)=='string' && response.data.parent.hasTemplate==1){ // Có giao diện
              this.hasTemplate = 1;
            }
            else{ // Không có giao diện, dùng giao diện mắc định
              this.isLoading = 0;
              this.hasTemplate = 0;
            }

            
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getDataFromApi(type, code) {
      PublicService.getPublicPageConfig(type, code).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.content = response.data;
            this.isLoading = 0;
            /*
            //Vue.nextTick(function(){
            this.$nextTick(function () {
              //vm.initOwl();
              //Vue.initOwl();
              this.initOwl();
            });
            //}.bind(vm));
            */
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      /*
      .then(() => {
              console.log(this); // Vue instance
              console.log(vm);
            
                this.initOwl();
            
            })
      */
    },
    async contentPageChanged(page){
      this.currentPage = page;
      
      var param = this.$router.currentRoute.value.query;
      if(Object.keys(param).length>0){
        param.offset = this.currentPage;
        param.limit = this.perPage;
        param.callType = this.callType;
        if(typeof(param.page)=='string'){
          //delete param['page'];
        }
      }
      else{
        param = {'offset': this.currentPage, 'limit': this.perPage, 'callType': this.callType};
      }
      await this.getPageDataFromApi(this.cRoute, this.cCode, param);
    },
    searchFilterChanged(param){
      //var param = {'offset': this.currentPage, 'limit': this.perPage, 'callType': this.callType};
      if(typeof(param)=='object'){
        var filterParam = param;
        filterParam.offset = 0;
        filterParam.limit = this.perPage;
        filterParam.callType = this.callType;
        //alert(filterParam);
        //await this.getPageDataFromApi(this.cRoute, this.cCode, filterParam);
        console.log(filterParam);
        PublicService.getPublicPageData(this.cCode, 2, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "child")) {
            this.posts = response.data.child;
            this.cat = response.data.parent;  
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      }
    },
    getImages(str, imgUrl) {
      if (typeof str == "string" && str.length > 0) {
        var imgSrc = str
          .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
          .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));
        return typeof imgSrc[0] == "string" ? imgSrc[0] : imgUrl;
      } else {
        return imgUrl;
      }
    },
    get() {
      const route = useRoute();
      const id = route.params.id;
      PublicService.getSubCatPublicContent(id).then(
        (response) => {
          //this.content = response.data;
          this.posts = response.data.child;
          this.cat = response.data.parent;
          //console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      /*
      var id = this.$route.params.id;
      this.$api
        .get("/cat/" + id + '?type=1')
        .then(res => {
          console.log(res);
          this.posts = res.data.child;
          this.cat = res.data.parent;
        })
        .catch(err => {
          console.log(err.response);
        });
      */
    },
  },
};
</script>

<style></style>
