export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "search_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "month_hit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Visitors"])},
  "year_hit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Visitors"])},
  "search_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "search_datatype_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data type"])},
  "search_datatype1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "search_datatype2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "search_datatype3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Album"])},
  "search_datatype4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
  "search_datatype5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
  "search_datatype6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
  "search_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "search_chose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chose"])},
  "search_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search result"])},
  "search_result_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search result"])},
  "search_result_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result"])},
  "search_result_units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "search_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "search_keyword_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter search keyword"])},
  "search_no_result_search_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't find what you're looking for? Please try another search results here."])},
  "search_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search document"])},
  "search_docs_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "select_docs_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select document group"])},
  "select_docs_dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the issuing authority"])},
  "select_docs_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select publishing area"])},
  "select_docs_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select document type"])},
  "select_docs_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select year of issue"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "post_for_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post for tag"])},
  "update_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "view_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
  "related_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related"])},
  "other_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "other_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "other_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "other_album_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More album"])},
  "other_video_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More video"])},
  "first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
  "last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
  "next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "prev_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
  "doc_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document NO"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "date_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue date"])},
  "date_effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effect date"])},
  "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "copy_link_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied"])},
  "doc_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên văn bản"])},
  "doc_dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cơ quan ban hành"])},
  "doc_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày ban hành"])},
  "doc_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lĩnh vực ban hành"])},
  "doc_publishup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày có hiệu lực"])},
  "doc_publishdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày hết hiệu lực"])},
  "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại văn bản"])},
  "doc_sovb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số hiệu"])},
  "doc_sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người ký"])},
  "doc_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu lực văn bản"])},
  "doc_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải văn bản"])},
  "doc_general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chung"])},
  "doc_content_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung tóm tắt"])},
  "doc_relate_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản liên quan"])},
  "breadcrumb_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "online_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "statistic_cat_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "statistic_lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look up data"])},
  "statistic_year_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "statistic_period_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "subscribe_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the travel newsletter"])},
  "subscribe_tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to receive comprehensive travel newsletters"])},
  "subscribe_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "subscribe_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "invalid_email_mess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input valid email address"])},
  "subscribe_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for subscribing!"])},
  "subscribe_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "print_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])}
}