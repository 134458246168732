<template>
  <div class="col-md-12">
    <div class="login-container card-container">
      <div class="row">
        <div class="col-md-5 d-md-block d-sm-none d-none">
          <img class="login-side-img" src="/assets/img/login_side.jpg"/>
        </div>
        <div class="col-md-7 col-sm-12 col-12">
          <div class="login-panel">
            <div class="login-panel-inner">
              <img
                id="profile-img"
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                class="profile-img-card"
              />
              <Form @submit="handleLogin" :validation-schema="schema">
                <div class="form-group">
                  <label for="username">{{$t('login_email_username')}}</label>
                  <Field name="username" type="text" class="form-control" />
                  <ErrorMessage name="username" class="error-feedback" />
                </div>
                <div class="form-group">
                  <label for="password">{{$t('login_password')}}</label>
                  <Field name="password" type="password" class="form-control" />
                  <ErrorMessage name="password" class="error-feedback" />
                </div>

                <div class="form-group">
                  <button class="btn btn-primary btn-block signin-btn" :disabled="loading">
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>{{$t('login_btt_label')}}</span>
                  </button>
                  <!--<p class="or-signup"><span style="margin-right: 15px;">Or</span><a :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/register'">Sign up</a></p>-->
                </div>

                <div class="form-group">
                  <p class="signup-or-social">
                    <a class="login-register-link" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/register'">{{$t('create_an_account')}}</a>
                    <br/>
                    <span style="margin-right: 15px;margin-left: 15px;">{{$t('or_login_with')}}</span>
                    <!--<i class="fa fa-facebook-official" aria-hidden="true"></i><i class="fa fa-google" aria-hidden="true"></i>-->
                  </p>
                  <div id="signin_button"></div>
                  <!--
                  <div id="g_id_onload"
                    data-client_id="82959701906-2luiqtf1dvugttam5hbl5va9i5afsrn0.apps.googleusercontent.com"
                    data-callback="globalThis.handleCredentialResponse"
                    data-auto_prompt="false">
                  </div>
                  -->
                  <!--:data-login_uri="baseUrl+'/login'"-->
                  <!--
                  <div class="g_id_signin"
                    data-type="standard"
                    data-size="large"
                    data-theme="outline"
                    data-text="sign_in_with"
                    data-shape="rectangular"
                    data-logo_alignment="left">
                  </div>
                  -->
                </div>

                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </Form>
              <div class="form-group">
                <a class="login-back-to-home" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')">{{$t('login_back_to_home')}}</a>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<!--<script src="https://accounts.google.com/gsi/client" async defer></script>-->
<script>
import { ref, getCurrentInstance } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import jwt_decode from "jwt-decode";

export default {
  name: "LoginCmp",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const { t } = this.$root.$i18n
    const schema = yup.object().shape({
      username: yup.string().required(t('login_username_required')),
      password: yup.string().required(t('login_password_required')),
    });

    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;

    return {
      loading: false,
      message: "",
      schema,
      baseUrl,
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang'),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted: function () {
            let googleScript = document.createElement('script');
            googleScript.src = 'https://accounts.google.com/gsi/client';
            googleScript.async = true;
            googleScript.defer = true;
            document.head.appendChild(googleScript);
            
            window.addEventListener('load', () => {
                console.log(window.google);
                window.google.accounts.id.initialize({
                    client_id: "82959701906-2luiqtf1dvugttam5hbl5va9i5afsrn0.apps.googleusercontent.com",
                    callback: this.handleCredentialResponse
                });
                window.google.accounts.id.renderButton(
                    document.getElementById("signin_button"),
                    { theme: "outline", size: "large" }  // customization attributes
                );
            })
        },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
    this.$root.$i18n.locale = localStorage.getItem('lang');
  },
  methods: {
    handleCredentialResponse(response) {
      // decodeJwtResponse() is a custom function defined by you
      // to decode the credential response.
      //const responsePayload = decodeJwtResponse(response.credential);

      var responsePayload = jwt_decode(response.credential);

      console.log("ID: " + responsePayload.sub);
      console.log('Full Name: ' + responsePayload.name);
      console.log('Given Name: ' + responsePayload.given_name);
      console.log('Family Name: ' + responsePayload.family_name);
      console.log("Image URL: " + responsePayload.picture);
      console.log("Email: " + responsePayload.email);
    },
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
// eslint-disable-next-line no-unused-vars
function globalHelper(){
  alert('Hello World');
}
// eslint-disable-next-line no-unused-vars
/*
function handleCredentialResponse(response) {
      // decodeJwtResponse() is a custom function defined by you
      // to decode the credential response.
      //const responsePayload = decodeJwtResponse(response.credential);

      var responsePayload = jwt_decode(response.credential);

      console.log("ID: " + responsePayload.sub);
      console.log('Full Name: ' + responsePayload.name);
      console.log('Given Name: ' + responsePayload.given_name);
      console.log('Family Name: ' + responsePayload.family_name);
      console.log("Image URL: " + responsePayload.picture);
      console.log("Email: " + responsePayload.email);
    }
  */
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
