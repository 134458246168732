<template>
  <section
    class="section-wrapper section-4-wrapper"
    :style="sectionStyle"
  >
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <div class="container-fluid section-4-container">
      <div class="row">
        <div class="col text-center">
          <h1 class="section-wrapper-title section-4-wrapper-title">
            {{(componentData.data.headline).length>0?componentData.data.headline:''}}
          </h1>
        </div>
      </div>
      <div class="row">
        <!-- eslint-enable -->
        <div class="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
          <div class="section-4-col-wrapper">
            <div class="section-4-post-item-title-wrapper">
              <a v-if="(componentData.data.dataList[0].link).length>0" class="section-4-section-title-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                <h1 class="section-4-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
              </a>
              <h1 v-else class="section-4-section-title">{{ componentData.data.dataList[0].dataHeadLine }}</h1>
            </div>
            <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
            <template v-for="(post, index) in blockData[0]"
            :postitem="post" :key="index"
            >
              <div v-if="index==0" class="section-post-item section-4-post-item">
                <div class="image-wrapper">
                  <a class="" :href="genPostLink(post.type,post.id)" v-lazy-container="{ selector: 'img' }">
                    <img
                      class="section-4-post-item-img"
                      :data-src="getImages(post.summary+post.content, post.images)"
                    />
                  </a>
                </div>
                <div class="section-4-post-title-wrapper">
                  <a class="section-4-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 100)}}</a>
                </div>
              </div>
              <div v-else class="section-post-item section-4-post-item section-4-post-item-title-only">
                <div class="section-4-post-title-wrapper">
                  <a class="section-4-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 100)}}</a>
                </div>
              </div>
            </template>
            <!-- eslint-enable -->
            <div class="section-4-section-more-link-wrapper">
              <a v-if="(componentData.data.dataList[0].link).length>0" class="section-4-section-more-link" :href="componentData.data.dataList[0].link" :target="componentData.data.dataList[0].target==0?'_self':'_blank'">
                {{ $t('see_more') }}
              </a>
            </div>
          </div>
        </div>
        <div class="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
          <div class="section-4-col-wrapper">
            <div class="section-4-post-item-title-wrapper">
              <a v-if="(componentData.data.dataList[1].link).length>0" class="section-4-section-title-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">
                <h1 class="section-4-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
              </a>
              <h1 v-else class="section-4-section-title">{{ componentData.data.dataList[1].dataHeadLine }}</h1>
            </div>
            <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
            <template v-for="(post, index) in blockData[1]"
            :postitem="post" :key="index"
            >
              <div v-if="index==0" class="section-post-item section-4-post-item">
                <div class="image-wrapper">
                  <a class="" :href="genPostLink(post.type,post.id)" v-lazy-container="{ selector: 'img' }">
                    <img
                      class="section-4-post-item-img"
                      :data-src="getImages(post.summary+post.content, post.images)"
                    />
                  </a>
                </div>
                <div class="section-4-post-title-wrapper">
                  <a class="section-4-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 100)}}</a>
                </div>
              </div>
              <div v-else class="section-post-item section-4-post-item section-4-post-item-title-only">
                <div class="section-4-post-title-wrapper">
                  <a class="section-4-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 100)}}</a>
                </div>
              </div>
            </template>
            <!-- eslint-enable -->
            <div class="section-4-section-more-link-wrapper">
              <a v-if="(componentData.data.dataList[1].link).length>0" class="section-4-section-more-link" :href="componentData.data.dataList[1].link" :target="componentData.data.dataList[1].target==0?'_self':'_blank'">
                {{ $t('see_more') }}
              </a>
            </div>
          </div>
        </div>
        <div class="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
          <div class="section-4-col-wrapper">
            <div class="section-4-post-item-title-wrapper">
              <a v-if="(componentData.data.dataList[2].link).length>0" class="section-4-section-title-link" :href="componentData.data.dataList[2].link" :target="componentData.data.dataList[2].target==0?'_self':'_blank'">
                <h1 class="section-4-section-title">{{ componentData.data.dataList[2].dataHeadLine }}</h1>
              </a>
              <h1 v-else class="section-4-section-title">{{ componentData.data.dataList[2].dataHeadLine }}</h1>
            </div>
            <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
            <template v-for="(post, index) in blockData[2]"
            :postitem="post" :key="index"
            >
              <div v-if="index==0" class="section-post-item section-4-post-item">
                <div class="image-wrapper">
                  <a class="" :href="genPostLink(post.type,post.id)" v-lazy-container="{ selector: 'img' }">
                    <img
                      class="section-4-post-item-img"
                      :data-src="getImages(post.summary+post.content, post.images)"
                    />
                  </a>
                </div>
                <div class="section-4-post-title-wrapper">
                  <a class="section-4-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 100)}}</a>
                </div>
              </div>
              <div v-else class="section-post-item section-4-post-item section-4-post-item-title-only">
                <div class="section-4-post-title-wrapper">
                  <a class="section-4-post-title-link" :href="genPostLink(post.type,post.id)" :title="displayEntity(post.title)">{{shorten(displayEntity(post.title), 100)}}</a>
                </div>
              </div>
            </template>
            <!-- eslint-enable -->
            <div class="section-4-section-more-link-wrapper">
              <a v-if="(componentData.data.dataList[2].link).length>0" class="section-4-section-more-link" :href="componentData.data.dataList[2].link" :target="componentData.data.dataList[2].target==0?'_self':'_blank'">
                {{ $t('see_more') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import BannerItem from "./BannerItemCmp";

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
      //blockTitle: [],
    };
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
  computed: {
    sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    //BannerItem
  },
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
            /*
            console.log(cfgData.data.dataList[dtCt]);
            if(typeof(cfgData.data.dataList[dtCt])=='object' && typeof(cfgData.data.dataList[dtCt].dataHeadLine)=='string'){
                vm.blockTitle.push(
                cfgData.data.dataList[dtCt].dataHeadLine.length > 0
                    ? cfgData.data.dataList[dtCt].dataHeadLine
                    : ""
                );
            }
            dtCt++;
            */
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    genCatLink: function (type, id) {
      return FunctionService.genCatLink(type, id);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
