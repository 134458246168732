<template>
    <section class="section-wrapper section-7-wrapper" :style="sectionStyle">
        <div class="container section-7-container">
          <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
          <!--<pre>{{ JSON.stringify(blockData, null, 2) }}</pre>-->
            <div class="row">
                <div class="col text-center">
                    <a v-if="(componentData.data.link).length>0" class="section-7-section-title-link" :href="componentData.data.link" :target="componentData.data.target==0?'_self':'_blank'">
                        <h1 class="section-wrapper-title section-7-wrapper-title">{{ componentData.data.headline }}</h1>
                    </a>
                    <h1 v-else class="section-wrapper-title section-7-wrapper-title">{{ componentData.data.headline }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div class="section-widget-content section-7-widget-content facebook-widget">
                        <div class="section-7-widget-content-head facebook-widget-head"></div>
                        <div class="widget-content-inner">
                          <div>
                            <div id="fb-root"></div>
                            <div class="fb-page" data-href="https://www.facebook.com/vnattitc" data-tabs="timeline" data-width="500" data-height="500" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/vnattitc" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/vnattitc">Cục Du lịch Quốc gia Việt Nam</a></blockquote></div>
                          </div>
                        </div>
                    </div>
                </div>
                <!--
                <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div class="section-7-widget-content-head youtube-widget-head"></div>
                    <div class="section-widget-content section-7-widget-content youtube-widget">
                        
                        <div class="widget-content-inner">
                          <div class="youtube-subscribe">
                              <div class="g-ytsubscribe" data-channelid="UCCWX3wMrxnW5i6VpBnaNOrg" data-layout="full" data-count="default"></div>
                          </div>
                          <div id="youtube-widget-video">
                          </div>
                          <div class="youtube-widget-info">
                              <div class="youtube-widget-video-title" id="youtube-widget-video-title"></div>
                              <div class="youtube-widget-video-desc" id="youtube-widget-video-desc"></div>
                          </div>
                        </div>
                    </div>
                </div>
                -->
                <div v-if="typeof(blockData[2]) == 'object' && (blockData[2]).length>0" class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div class="section-7-widget-content-head youtube-widget-head"></div>
                    <div class="section-widget-content section-7-widget-content youtube-widget">
                        
                        <div class="widget-content-inner">
                          <div class="youtube-subscribe">
                              <div id="youtube-subscribe-container"></div>
                          </div>
                          <div class="youtube-content-inner-block">
                            <div v-if="blockData[2][0]" id="youtube-widget-video">
                              <div class="video-container">
                                <iframe id="video" width="1920" height="1080" :src="'https://www.youtube.com/embed/'+blockData[2][0]['source']+'?loop=1&amp;modestbranding=1&amp;autohide=1&amp;showinfo=0&amp;controls=0&amp;playlist='+blockData[2][0]['source']" :title="blockData[2][0]['title']" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                              </div>
                            </div>
                            <div v-if="blockData[2][0]" class="youtube-widget-info">
                                <div class="youtube-widget-video-title" id="youtube-widget-video-title">{{blockData[2][0]['title']}}</div>
                                <div class="youtube-widget-video-desc" id="youtube-widget-video-desc" v-html="blockData[2][0]['summary']"></div>
                            </div>
                            <div v-if="typeof(blockData[2]) == 'object' && (blockData[2]).length>1" class="youtube-widget-list">
                              <div v-for="(youtubeItem, index) in blockData[2]" 
                                :key="index"
                                class="youtube-widget-list-item"
                              >
                                <a v-if="youtubeItem.link" :href="youtubeItem.link" :target="youtubeItem.target">{{youtubeItem.title}}</a>
                                <p v-else>{{youtubeItem.title}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div v-if="typeof(blockData[3]) == 'object' && (blockData[3]).length>0" class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div class="section-widget-content section-7-widget-content zalo-widget">
                        <div class="section-7-widget-content-head zalo-widget-head"></div>
                        <div class="widget-content-inner">
                          <div class="zalo-widget-container">
                              <div class="zalo-follow-button" :data-oaid="blockData[3][0]['source']" data-cover="no" data-article="2" data-width="" data-height=""></div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import BannerItem from "./BannerItemCmp";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import jsonBlock from '/public/assets/data/youtube/data.json';

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type:"",
      code:"",
      posts: [],
      blockData: [],
      youtubeBlock: jsonBlock,
    };
  },
  //async mounted(){
  mounted(){

    this.$nextTick(function(){
      /*
      let youtubeScript = document.createElement('script')
      youtubeScript.setAttribute('src', 'https://apis.google.com/js/platform.js')
      document.head.appendChild(youtubeScript)
      */
      let FBScript = document.createElement('script')
      FBScript.setAttribute('src', 'https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v14.0')
      FBScript.setAttribute('crossorigin', 'anonymous')
      FBScript.setAttribute('nonce', 'OhjeinMP')
      FBScript.setAttribute('async', 'true')
      FBScript.setAttribute('defer', 'true')
      document.head.appendChild(FBScript)
    });
      //this.getYoutubeWidget();
      /*
      let IGScript = document.createElement('script')
      IGScript.setAttribute('src', 'https://www.instagram.com/embed.js')
      //IGScript.setAttribute('crossorigin', 'anonymous')
      IGScript.setAttribute('async', 'true')
      document.head.appendChild(IGScript)

      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
      */

      var timer1 = setInterval(function () {
      //dconsole.log($('#youtube-subscribe-container').length);
      if($('#youtube-subscribe-container').length>0){
        let youtubeScript = document.createElement('script')
        youtubeScript.setAttribute('src', 'https://apis.google.com/js/platform.js')
        youtubeScript.setAttribute('async', 'true')
        //youtubeScript.setAttribute('defer', 'true')
        document.head.appendChild(youtubeScript)
        $('#youtube-subscribe-container').html('<div class="g-ytsubscribe" data-channelid="UCCWX3wMrxnW5i6VpBnaNOrg" data-layout="full" data-count="default"></div>');
          }
          if($('.g-ytsubscribe').length>0){
            clearInterval(timer1);
          }
        }, 1000);

    //});
    
  },
  created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
   watch: {
    blockData() {

    }
  },
  computed: {
    sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    //BannerItem
  },
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        
        resp.then(function (data) {
          //console.log(data);
          //alert(data);
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
          });
        });
        //console.log(this.blockData);
      }
    },
    getYoutubeWidget(){
      // Lấy thông tin widget youtube
      $.getJSON('https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCCWX3wMrxnW5i6VpBnaNOrg&maxResults=1&order=date&type=video&key=AIzaSyCGuD-Ysg-OmbVP7c0r3I5B3PcvFnROiW4', function(data) {
        var text = '';
        var title = '';
        var desc = '';
        if(typeof(data)=='object'){
            //text = JSON.stringify(data);
            console.log(data.items);
            if(typeof(data.items)=='object'){
                //text += '<div class="image-wrapper"><img src="'+data.items[0].snippet.thumbnails.medium.url+'"/></div>';
                text += '<div class="video-wrapper">\
                    <iframe src="https://www.youtube.com/embed/'+data.items[0].id.videoId+'" height="315" width="560" allowfullscreen="" frameborder="0"></iframe></div>';
                title = data.items[0].snippet.title;
                desc = data.items[0].snippet.description;
            }
        }
        $("#youtube-widget-video").html(text);
        $("#youtube-widget-video-title").html(title);
        $("#youtube-widget-video-desc").html(desc);
      });
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    genCatLink: function (type, id) {
      return FunctionService.genCatLink(type, id);
    },
    
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>