<template>
  <div>
    <!--
    <pre>{{ JSON.stringify(content, null, 2) }}</pre>
    <pre>{{this.$route.name}}</pre>
    <pre>{{cRoute}}</pre>
    {{cCode}}
    -->
    <div v-if="isLoading == 0 && content.length > 0">
      <div v-for="(block, index) in content" :key="index">
        <component
          :is="block.data.component"
          :block="block"
          :key="block.data.id"
          :componentData="block.data"
          :parentComponentData="this.cat"
          :parentComponentDataItem="this.posts"
          @content-page-clicked="contentPageChanged"
          @search-filter-changed="searchFilterChanged"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
//import CatItem from "../cmps/CatItemCmp";
import PublicService from "../services/public.service";

import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import MenuTopCmp from "../cmps/MenuTopCmp";
import MenuTopCmpV2 from "../cmps/MenuTopCmpV2";
import MainMenuCmp from "../cmps/MainMenuCmp";
import MainMenuCmpV2 from "../cmps/MainMenuCmpV2";
import BannerSectionCmp from "../cmps/BannerSectionCmp";
import HomeSection1Cmp from "../cmps/HomeSection1Cmp";
import HomeSection2Cmp from "../cmps/HomeSection2Cmp";
import HomeSection3Cmp from "../cmps/HomeSection3Cmp";
import HomeSection4Cmp from "../cmps/HomeSection4Cmp";
import HomeSection5Cmp from "../cmps/HomeSection5Cmp";
import HomeSection6Cmp from "../cmps/HomeSection6Cmp";
import HomeSection7Cmp from "../cmps/HomeSection7Cmp";
import HomeSection8Cmp from "../cmps/HomeSection8Cmp";
import HomeSection9Cmp from "../cmps/HomeSection9Cmp";
import MainFooterCmp from "../cmps/MainFooterCmp";
import MainFooterCmpV2 from "../cmps/MainFooterCmpV2";
import HitCounterCmp from "../cmps/HitCounterCmp";
import ImageConnectCmp from "../cmps/ImageConnectCmp";
// Trang danh sach
/*
import BannerDefaultListSectionCmp from "../cmps/BannerDefaultListSectionCmp";
import DefaultSearchSectionCmp from "../cmps/DefaultSearchSectionCmp";
*/
import BannerDefaultStatisticSectionCmp from "../cmps/BannerDefaultStatisticSectionCmp";
import DefaultStatisticSectionCmp from "../cmps/DefaultStatisticSectionCmp";
import DefaultListSectionSubListCmp from "../cmps/DefaultListSectionSubListCmp";
import DefaultListSectionSubList1Cmp from "../cmps/DefaultListSectionSubList1Cmp";

//import { defineComponent, computed, reactive, ref, getCurrentInstance } from 'vue'
import { defineComponent, computed, reactive, getCurrentInstance } from 'vue'
//import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head';

export default defineComponent({
  //name: "HomeCmp",
  setup() {

    const route = useRoute();

    //const AppConst = ref(AppConst)
    //const AppConst = ref('')
    const app= getCurrentInstance()
    const lang = localStorage.getItem('lang')
    const siteMeta = app.appContext.config.globalProperties.siteMeta
    const gPro = app.appContext.config.globalProperties;
    //console.log(app.appContext.config.globalProperties.siteTitle);
    const siteData = reactive({
      title: lang!='' && lang!='vi'?siteMeta['title_'+lang]:siteMeta.title,
      description: lang!='' && lang!='vi'?siteMeta['desc_'+lang]:siteMeta.desc,
      appName: siteMeta.appName,
      image: siteMeta.image,
      author: siteMeta.author,
      icon: siteMeta.icon,
      //url: (lang!='' && lang!='vi'?gPro.$baseUrl+'/'+lang+'/statistitc':gPro.$baseUrl+'statistitc'),
      url: gPro.$baseUrl+route.fullPath,
      keywords: lang!='' && lang!='vi'?siteMeta['keywords_'+lang]:siteMeta.keywords,
      robots: siteMeta.robots,
      country: siteMeta.country,
      revisitAfter: siteMeta.revisitAfter,
      documentRating: siteMeta.documentRating,
      documentDistribution: siteMeta.documentDistribution,
      distribution: siteMeta.distribution,
      area: siteMeta.area,
      placename: siteMeta.placename,
      resourceType: siteMeta.resourceType,
      owner: siteMeta.owner,
      classification: siteMeta.classification,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        { name: 'copyright', content: siteMeta.copyright },
        { name: 'application-name', content: siteData.appName },
        { name: 'email', content: siteMeta.email },
        { name: 'section', itemprop: 'articleSection', content: "Tin tức - Sự kiện" },
        { name: 'keywords', content: computed(() => siteData.keywords) },
        { name: 'robots', content: siteData.robots },
        { name: 'country', content: siteData.country },
        { name: 'revisit-after', content: siteData.revisitAfter },
        { name: 'document-rating', content: siteData.documentRating },
        { name: 'document-distribution', content: siteData.documentDistribution },
        { name: 'distribution', content: siteData.distribution },
        { name: 'area', content: siteData.distribution },
        { name: 'placename', content: siteData.placename },
        { name: 'resource-type', content: siteData.resourceType },
        { name: 'owner', content: siteData.owner },
        { name: 'classification', content: siteData.classification },
        //{ name: 'description', content: 'A description of the page', id: 'desc' }, // id to replace intead of create element
        // Twitter
        { name: 'twitter:title', content: computed(() => siteData.title) },
        { name: 'twitter:description', content: computed(() => siteData.description)},
        { name: 'twitter:card', content: siteMeta.twitterCard},
        { name: 'twitter:site', content: computed(() => siteData.url)},
        { name: 'twitter:creator', content: computed(() => siteData.author)},
        // Google+ / Schema.org
        { itemprop: 'name', content: computed(() => siteData.title) },
        { itemprop: 'description', content: siteMeta.copyright },
        // Facebook / Open Graph
        //{ property: 'fb:app_id', content: '123456789' },
        { property: 'og:title', content: computed(() => siteData.title) },
        { property: 'og:site_name', content: computed(() => siteData.title) },
        { property: 'og:type', content: "article" },
        { property: 'og:image', content: siteMeta.image },
        { property: 'og:image:url', content: siteMeta.image },
        { property: 'og:url', content: computed(() => siteData.url) },
        { property: 'og:description', content: computed(() => siteData.description) },
        //<meta property="article:published_time" content="2021-12-27T09:40:54+00:00">
        //<meta property="article:modified_time" content="2022-04-15T12:46:19+00:00">
      ],
      // link tags
      link: [
        { rel: 'canonical', href: computed(() => siteData.url), id: 'canonical' },
        { rel: 'author', href: siteMeta.author, undo: false }, // undo property - not to remove the element
        { rel: 'icon', href: siteMeta.icon, sizes: '32x32', type: 'image/png' }, 
        // with shorthand
        //{ r: 'icon', h: 'path/to/icon-32.png', sz: '32x32', t: 'image/png' },
      ],
      /*
      script: [
        { type: 'text/javascript', src: 'cdn/to/script.js', async: true, body: true}, // Insert in body
        // with shorthand
        { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' },
        // ...
      ],
      style: [
        { type: 'text/css', inner: 'body { background-color: #000; color: #fff}', undo: false },
        // ...
      ]
      */
    })
  },
  data() {
    return {
      cat_id: null,
      cat_index: null,
      posts: [],
      title: "",
      image: "",
      imgPlaceholder: "http://via.placeholder.com/300",
      cat: [],
      isLoading: 1,
      isLoaded: 0,
      content: {},
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      hasTemplate: 0,
      //perPage: 10,
      //totalItem: 100,
      currentPage: isNaN(this.$route.query.page)?1:this.$route.query.page,
      callType: 1,
    };
  },
  computed: {
    imgPreview() {
      return this.images == "" ? this.imgPlaceholder : this.images;
      //return this.images;
      /*
      var finalImg = "";
      if(this.image==""){
        //finalImg = this.getImages(this.summary+this.content, this.images);
      }
      return finalImg == "" ? this.imgPlaceholder : finalImg;
      */
    },
    strippedHtml() {
      let regex = /(<([^>]+)>)/gi;
      return this.summary.content.rendered.replace(regex, "");
    },
  },
  components: {
    MenuTopCmp,
    MenuTopCmpV2,
    MainMenuCmp,
    MainMenuCmpV2,
    BannerSectionCmp,
    HomeSection1Cmp,
    HomeSection2Cmp,
    HomeSection3Cmp,
    HomeSection4Cmp,
    HomeSection5Cmp,
    HomeSection6Cmp,
    HomeSection7Cmp,
    HomeSection8Cmp,
    HomeSection9Cmp,
    MainFooterCmp,
    MainFooterCmpV2,
    HitCounterCmp,
    ImageConnectCmp,
    BannerDefaultStatisticSectionCmp,
    DefaultStatisticSectionCmp,
    DefaultListSectionSubListCmp,
    DefaultListSectionSubList1Cmp,
    //CatItem,
  },
  async created() {
    //this.get();
    //await this.getDataFromApi(this.cRoute, this.cCode);
    //var param = {'offset': this.currentPage, 'callType': this.callType};
    var param = this.$router.currentRoute.value.query;
    if(Object.keys(param).length>0){
      param.offset = this.currentPage;
      param.callType = this.callType;
    }
    else{
      param = {'offset': this.currentPage, 'callType': this.callType};
    }
    await this.getPageDataFromApi(this.cRoute, param);
  },
  methods: {
    getPageDataFromApi(type, param){
      param = {'type':type, 'position':'home'};
            PublicService.getStatisticPageConfig('PageConfig/getStatisticPageConfig',param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.content = response.data;
                    this.isLoading = 0;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    getDataFromApi(type, code) {
      PublicService.getPublicPageConfig(type, code).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.content = response.data;
            this.isLoading = 0;
            /*
            //Vue.nextTick(function(){
            this.$nextTick(function () {
              //vm.initOwl();
              //Vue.initOwl();
              this.initOwl();
            });
            //}.bind(vm));
            */
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      /*
      .then(() => {
              console.log(this); // Vue instance
              console.log(vm);
            
                this.initOwl();
            
            })
      */
    },
    async contentPageChanged(page){
      this.currentPage = page;
      
      var param = this.$router.currentRoute.value.query;
      if(Object.keys(param).length>0){
        param.offset = this.currentPage;
        param.limit = this.perPage;
        param.callType = this.callType;
        if(typeof(param.page)=='string'){
          //delete param['page'];
        }
      }
      else{
        param = {'offset': this.currentPage, 'limit': this.perPage, 'callType': this.callType};
      }
      await this.getPageDataFromApi(this.cRoute, this.cCode, param);
    },
    searchFilterChanged(param){
      //var param = {'offset': this.currentPage, 'limit': this.perPage, 'callType': this.callType};
      if(typeof(param)=='object'){
        var filterParam = param;
        filterParam.offset = 0;
        filterParam.limit = this.perPage;
        filterParam.callType = this.callType;
        //alert(filterParam);
        //await this.getPageDataFromApi(this.cRoute, this.cCode, filterParam);
        //console.log(filterParam);
        PublicService.getPublicPageData(this.cCode, 1, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "child")) {
            this.posts = response.data.child;
            this.cat = response.data.parent;  
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      }
    },
    getImages(str, imgUrl) {
      if (typeof str == "string" && str.length > 0) {
        var imgSrc = str
          .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
          .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));
        return typeof imgSrc[0] == "string" ? imgSrc[0] : imgUrl;
      } else {
        return imgUrl;
      }
    },
    get() {
      const route = useRoute();
      const id = route.params.id;
      PublicService.getCatPublicContent(id).then(
        (response) => {
          //this.content = response.data;
          this.posts = response.data.child;
          this.cat = response.data.parent;
          //console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      /*
      var id = this.$route.params.id;
      this.$api
        .get("/cat/" + id + '?type=1')
        .then(res => {
          console.log(res);
          this.posts = res.data.child;
          this.cat = res.data.parent;
        })
        .catch(err => {
          console.log(err.response);
        });
      */
    },
  },
});
</script>

<style></style>
