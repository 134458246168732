<template>
  <section
    v-if="componentData"
    class="section-wrapper section-banner-default-list-wrapper"
    :style="sectionStyle"
  >
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div v-if="parentComponentData" class="section-banner-default-list-container" :style="this.setContainerStyle(componentData.data)">
      <div class="section-banner-default-list-container-content">
        <div class="section-banner-default-list-container-content-img-container">
          <img class="section-banner-default-list-container-content-img" v-if="typeof(parentComponentData.image)=='string' && (parentComponentData.image).length>0" :src="getImages('',parentComponentData.image)"/>
        </div>
        <div class="section-banner-default-list-container-content-title-wrapper">
          <div class="container section-banner-default-list-container-content-title-container">
            <div class="section-banner-default-list-container-content-title"><a :href="genCatLink(parentComponentData.type, parentComponentData.code)" class="section-default-list-sublist-container-content-item-link">{{displayEntity(parentComponentData.title)}}</a></div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>

import { useRoute } from "vue-router";

//import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import BannerItem from "./BannerItemCmp";
/*
import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
*/

export default {
  props: ["loggedIn", "componentData", "parentComponentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      parentData: [],
      cRoute: useRoute().name,
      cCode: this.$route.params.id
    };
  },
  created() {
   
  },
  computed: {
    sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    //BannerItem,
  },
  methods: {
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function(number){
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function(type){
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    genCatLink: function (type, id) {
      return FunctionService.genCatLink(type, id);
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>