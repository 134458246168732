<template>
  <div>
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="statistic-title statistic-international-title">
                <div class="homepage-block-title">
                  <a class="top-nav-curr" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != AppConst.PAGE_LANG?'/'+lang:'')+'/statistic'">{{$t('statistic_cat_title')}}</a> | <a v-if="typeof(statisticData.cat) == 'object'" class="top-nav-curr" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != AppConst.PAGE_LANG?'/'+lang:'')+'/statistic/international'">{{statisticData.cat.title}}</a>
                </div>
              </div>
              <div class="statistic-filter-wrapper">
								<div class="row statistic-filter-title-wrapper">
									<div class="statistic-filter-title col-lg-12">{{$t('statistic_lookup')}}</div>
								</div>
								<div class="statistic-filter-container row">
									<div class="col-lg-12">
										<div class="statistic-select-year-wrapper statistic-filter-items">
											<label for="statistic-year" class="statistic-filter-label">{{$t('statistic_year_label')}}&nbsp;</label>
											<select class="statistic-year" id="statistic-year" name="year" v-model="sYear">
                        <option v-for="(y, idxy) in yearFilter" :key="idxy" :value="idxy">{{idxy}}</option>
											</select>
										</div>
										<div class="statistic-select-month-wrapper statistic-filter-items">
											<label for="statistic-month" class="statistic-filter-label">{{$t('statistic_period_label')}}&nbsp;</label>
											<select v-if="lang=='en'" v-model="sMonth" class="statistic-month" id="statistic-month" name="period" v-on:change="changePeriod($event)">
                        <option v-for="(y, idxm) in dict_reverse(monthList)" :key="idxm" :value="idxm">{{toMonthName(idxm.replace('t',''))}}</option>
											</select>
                      <select v-else v-model="sMonth" class="statistic-month" id="statistic-month" name="period" v-on:change="changePeriod($event)">
                        <option v-for="(y, idxm) in dict_reverse(monthList)" :key="idxm" :value="idxm">{{idxm.replace('t','Tháng ')}}</option>
											</select>
										</div>
									</div>
								</div>
							</div>
          <!--<pre>{{ JSON.stringify(statisticData, null, 2) }}</pre>-->
          <div v-html="htmlcontent"></div>      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, getCurrentInstance} from "vue";
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  setup() {
    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;
    return{
      baseUrl
    }
  },
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      htmlcontent: '',
      statisticyear: 2022,
      yearFilter: [],
      statisticData: [],
      statisticCode: localStorage.getItem('lang')=='en'?'1501':'1205',
      monthList: [],
      sMonth: '',
      sYear: '',
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang')
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  created() {
    var dataUrl = this.baseUrl+'/StatisticGen/international';
    
    if(this.occurrences(this.baseUrl,'localhost')){
      dataUrl = 'https://vietnamtourism.gov.vn/StatisticGen/international';
    }
    
    //var dataUrl = 'http://demo.vietnamtourism.gov.vn/StatisticGen/international';
    var param = { "code": this.statisticCode };
    this.getStatisticData(dataUrl, param);

    var staticUrl = this.baseUrl+"/getStatistic/international";

    if(this.occurrences(this.baseUrl,'localhost')){
      staticUrl = 'https://vietnamtourism.gov.vn/getStatistic/international';
    }

    //var staticUrl = "http://demo.vietnamtourism.gov.vn/getStatistic/international"
    this.getStatisticAvaiable(staticUrl, param);
  },
  computed: {},
  onMounted() { },
  mounted() {
    
  },
  watch: {
    htmlcontent: {
             handler: function() {
                  var cLang = localStorage.getItem('lang');
                 this.$nextTick(() => {
                  var self = this;
                  $('.statistic-year').on('change', function () {
                    var yearVal = $(this).val();
                    //console.log(self.yearFilter);
                    //console.log('yearVal: '+yearVal);
                    //console.table(self.yearFilter);
                    //alert(typeof (self.yearFilter[yearVal]));
                    if (typeof (self.yearFilter[yearVal]) == 'object') {
                      //alert(Object.keys(self.monthList).length);
                      if (Object.keys(self.yearFilter[yearVal]).length > 0) {
                        self.sMonth = '';
                        $('.statistic-month').empty();
                        if(cLang == 'en'){
                          $('.statistic-month').append(new Option('Choose', '', true, true));
                        }
                        else{
                          $('.statistic-month').append(new Option('Chọn', '', true, true));
                        }
                         // eslint-disable-next-line no-unused-vars
                        $.each(self.yearFilter[yearVal], function (index, element) {
                          if (index == 't') {
                            //console.log(element);
                            //var opt = Array();
                            //console.log(self.yearFilter[yearVal]['t']);
                            var timeElm = self.yearFilter[yearVal]['t'];
                            timeElm = Object.keys(timeElm).map(function (key) {
                              //return [Number(key), timeElm[key]];
                              //return [String(key), timeElm[key]];
                              return String(key);
                            });
                            //console.log(timeElm);
                            //timeElm = timeElm.sort(self.naturalCompare).reverse();
                            //timeElm = self.dict_reverse(timeElm);
                            timeElm = timeElm.reverse();
                             // eslint-disable-next-line no-unused-vars
                            if(cLang == 'en'){
                              $.each(timeElm, function (index1, element1) {
                                const date = new Date();
                                date.setMonth(element1.replace('t','') - 1);

                                var monthName =  date.toLocaleString('en-US', {
                                    month: 'long',
                                });
                                $('.statistic-month').append(new Option(monthName, element1, true, false));
                              });
                            }
                            else{
                              $.each(timeElm, function (index1, element1) {
                                $('.statistic-month').append(new Option(element1.replace('t', 'Tháng '), element1, true, false));
                              });
                            }
                          }
                        });
                      }
                    }
                  });

                  $('.statistic-month11').on('change',function()
                  {
                    //this.$router.push("?page=1");
                    //window.location('http://localhost:8080/statistic/international?code=1205')
                    $('#frmseach_doc').attr('method','get');
                    $('#frmseach_doc').submit();
                  });

                });
           },
            deep: true
       },
     statisticData: {
             handler: function() {
                 this.$nextTick(() => {
                  //var self = this;
                  //console.table(this.statisticData['statistic']['stat']['time']['type1']['timelist']['y']);
                  //self.yearFilter = Object.prototype.hasOwnProperty.call(this.statisticData, "statistic") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic, "stat") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat, "time")?this.statisticData['statistic']['stat']['time']['type1']['timelist']['y']:[];
                  //self.yearFilter = alert(JSON.stringify(self.statisticData, "statistic"));

                  //var sCode = (this.statisticCode).substring(0,2);
                  var sCode = '12';
                  //var sYear = this.statisticData.statistic.statistic.info.year;
                  //alert(JSON.stringify(this.statisticData.statistic.stat.time.type1[sCode][sYear]));
                  //console.table(this.statisticData.statistic.stat.time.type1[sCode][sYear]);
                  //this.statisticData.statistic.stat.time.type1[sCode];
                  this.yearFilter = Object.prototype.hasOwnProperty.call(this.statisticData, "statistic") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic, "stat") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat, "time") && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time, "type1")  && 
                  Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1, sCode)
                  ?this.statisticData.statistic.stat.time.type1[sCode]:[];
                  //self.yearFilter = this.statisticData['statistic']['stat']['time']['type1']['timelist']['y'];
                  //console.log('statisticData');
                  //self.monthList = Object.prototype.hasOwnProperty.call(this.statisticData, "statistic") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic, "stat") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat, "time") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time, "type1")  && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1, self.statisticCode.substring(0,2)) && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1[self.statisticCode.substring(0,2)])?this.statisticData['statistic']['stat']['time']['type1'][self.statisticCode.substring(0,2)]:[];
                  /*
                  this.$nextTick(() => {
                  console.table(this.statisticData['statistic']['stat']['time']['type1'][self.statisticCode.substring(0,2)].t);
                  console.log(typeof(this.statisticData['statistic']['stat']['time']['type1'][self.statisticCode.substring(0,2)]));
                  self.monthList = Object.prototype.hasOwnProperty.call(this.statisticData, "statistic") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic, "stat") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat, "time") && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time, "type1")  && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1, self.statisticCode.substring(0,2)) && Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1[self.statisticCode.substring(0,2)], 't')?this.statisticData['statistic']['stat']['time']['type1'][self.statisticCode.substring(0,2)]['t']:[];
                  console.table(this.monthList);
                  //self.statisticData['statistic']['stat']['time']['type1'][self.statisticCode.substring(0,2)]
                  });
                  */
                });
           },
            deep: true
       },
      
  },
  methods: {
    getStatisticData(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.htmlcontent = response.data;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getStatisticAvaiable(url, param) {
      PublicService.getUrl(url, param).then(
        (response) => {
          //console.log('html:');
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.keys(response.data).length>0) {
            this.statisticData = response.data;
            //var sCode = (this.statisticCode).substring(0,2);
            var sCode = '12';
            //var sYear = document.getElementById('statistic-year').value;
            var sYear = response.data.statistic.statistic.info.year;
            //alert(JSON.stringify(sYear));
            //alert(response.data['statistic']['stat']['time']['type1'][sCode][sYear]);
            //console.table(response.data['statistic']['stat']['time']['type1'][sCode][sYear]);
            //alert(JSON.stringify(response.data['statistic']['stat']['time']['type1'][sCode][sYear]['t']));
            this.monthList = Object.prototype.hasOwnProperty.call(response.data, "statistic") && 
            Object.prototype.hasOwnProperty.call(response.data.statistic, "stat") && 
            Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat, "time") && 
            Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time, "type1")  && 
            Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1, sCode) && 
            Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1[sCode], sYear) && 
            Object.prototype.hasOwnProperty.call(this.statisticData.statistic.stat.time.type1[sCode][sYear], 't')
            ?this.statisticData.statistic.stat.time.type1[sCode][sYear]['t']:[];
            //alert(JSON.stringify(this.monthList));

            this.sYear = this.statisticData.statistic.statistic.info.year;
            this.sMonth = 't'+this.statisticData.statistic.statistic.info.month;
          }
        },
        (error) => {
          this.htmlcontent =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    changePeriod(event){
      var year = document.getElementById('statistic-year').value;
      //var period = document.getElementById('statistic-month').value;
      var period = event.target.value;
      //alert(this.$router.currentRoute);
      if(this.lang != 'vi'){
        this.$router.push('/'+this.lang+'/statistic/international?year='+year+'&period='+period);
      }
      else{
        this.$router.push('/statistic/international?year='+year+'&period='+period);
      }
      
      var dataUrl = this.baseUrl+"/StatisticGen/international"

      if(this.occurrences(this.baseUrl,'localhost')){
        dataUrl = "https://vietnamtourism.gov.vn/StatisticGen/international";
      }
      
      //var dataUrl = 'http://demo.vietnamtourism.gov.vn/StatisticGen/international';
      var param = { "code": this.statisticCode, "year": year, "period": period};
      this.getStatisticData(dataUrl, param);
    },
    dict_reverse(obj) {
      var new_obj= {}
      var rev_obj = Object.keys(obj).reverse();
      rev_obj.forEach(function(i) { 
        new_obj[i] = obj[i];
      })
      return new_obj;
    },
    naturalCompare(a, b) {
        var ax = [], bx = [];

        a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
        
        while(ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if(nn) return nn;
        }

        return ax.length - bx.length;
    },
    handlePageChange(event, page) {
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if (Object.keys(param).length > 0) {
        if (typeof (param.page) == 'string') {
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if (paramStr.length > 0) {
          paramStr = '&' + paramStr;
        }
      }
      this.$router.push("?page=" + page + paramStr);
      //this.$router.go(this.$router.currentRoute)
      this.$emit('content-page-clicked', page);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    occurrences: function(string, subString, allowOverlapping){
      return FunctionService.occurrences(string, subString, allowOverlapping);
    },
    toMonthName: function(monthNo){
      return FunctionService.toMonthName(monthNo);
    },
  },
};
</script>

<style>
</style>
