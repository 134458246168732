<template>
  <div v-if="typeof(postitem) == 'object' && Object.keys(postitem).length>0">
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    {{updateHits(postitem.id, postitem.type)}}
    <div>
      <!--
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <PostBreadCrumbCmp :code="postitem.catcode" :type="postitem.type"></PostBreadCrumbCmp>
        </div>
      </div>
      -->
      <div class="row g-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content-wrapper post-content-wrapper">
                  <main v-if="postitem.hasTemplate==0" id="content" class="neve-main" role="main">
                      
                  </main> 
                  <main v-else-if="postitem.hasTemplate==1" id="content" class="neve-main" role="main">
                      <!--
                      <div class="single-post-container" v-html="displayPostContent(postitem.summary)">
                      </div>
                      <div class="single-post-container" v-html="displayPostContent(postitem.content)">
                      </div>
                      -->
                      <!--
                      {{ typeof(specificPostData.html) }}
                      {{ specificPostData }}
                      {{ specificPostData.styles }}
                      -->
                      <!--{{ genHTML }}-->
                      <div v-if="typeof(specificPostData) == 'object' && typeof(specificPostData.html) == 'string' && typeof(specificPostData.styles) == 'string'" class="single-post-container">
                        <!--<component v-cloak scoped v-bind:is="style">{{ specificPostData.styles }}</component>-->
                        <!--<div ref="canvas" id="canvas"></div>-->
                        <div id="specific-post-content-wrapper" class="specific-post-content-wrapper" v-html="displayPostContent(specificPostData.html)"></div>
                        <div class="container" id="specific-post-other-content-wrapper">
                          <div class="row">
                            <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-content-source">
                              <div class="post-content-source-wrapper"><p>{{postitem.source}}</p></div>
                            </div>                            
                          </div>
                          <div class="row">
                              <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-tags-wrapper">
                                <div class="post-tags-container">{{$t('tags')}}: <div class="post-tags-list" v-html="displayPostTags(postitem.tags, postitem.tags_view)"></div></div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-share-wrapper">
                                <div id="specific-post-share-network-list" class="specific-post-share-network-list">
                                  <ShareNetwork
                                  v-for="network in networks"
                                  :network="network.network"
                                  :key="network.network"
                                  :style="{backgroundColor: network.color}"
                                  :url="sharing.url"
                                  :title="sharing.title"
                                  :description="sharing.description"
                                  :quote="sharing.quote"
                                  :hashtags="sharing.hashtags"
                                  :twitterUser="sharing.twitterUser"
                                  >
                                  <i :class="network.icon"></i>
                                  <span>{{ network.name }}</span>
                                  </ShareNetwork>
                                  <div class="share-network-zalo" data-v-0141c77e="" style="">
                                    <div class="zalo-share-button" :data-href="genPostLink(pageType, postitem.id)" data-oaid="3776018789323949240" data-layout="3" data-color="white" data-customize="true" style=""><img src="/assets/img/ic_zalo.png" style=""/></div>
                                  </div>
                                  <a class="share-network-copy" href="javascript:void(0)" data-v-0141c77e="" style="background-color: rgb(51, 51, 51);">
                                    <i class="fa fa-lg fa-files-o" data-v-0141c77e="" @click="copyUrl" :data-toggle="$t('copy_link_message')" :title="$t('copy_link_message')"></i>
                                    <span data-v-0141c77e=""></span>
                                  </a>
                                  <a class="share-network-print" :href="genPrintLink(pageType, postitem.id)" style="background-color: rgb(51, 51, 51);" target="_blank">
                                    <i class="fa fa-lg fa-print"></i>
                                  </a>
                                </div>
                              </div>
                          </div>
                          
                        </div>
                        <div class="other-onepage-post-section">
                          <div class="other-onepage-post-container container">
                            <div class="row">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <DefaultOnepagePostRelatedSectionNewsCmp
                                  :parentComponentData="parentComponentData"
                                  :postitem="postitem"
                                  :pageType="pageType"
                                  @content-page-clicked="contentPageChanged"
                                ></DefaultOnepagePostRelatedSectionNewsCmp>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <DefaultOnepagePostMoreSectionNewsCmp
                                  :parentComponentData="parentComponentData"
                                  :postitem="postitem"
                                  :pageType="pageType"
                                  @content-page-clicked="contentPageChanged"
                                ></DefaultOnepagePostMoreSectionNewsCmp>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="single-post-container">
                        <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <article :id="'post-'+postitem.id" class="post-data type-post">
                                          <div class="nv-content-wrap entry-content">
                                              <div class="post-content-container">
                                                <div class="row">
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-related-topic-wrapper">
                                                    <div>
                                                      <DefaultPostRelatedTopicSectionNewsCmp
                                                        :parentComponentData="parentComponentData"
                                                        :postitem="postitem"
                                                        :pageType="pageType"
                                                        @content-page-clicked="contentPageChanged"
                                                      ></DefaultPostRelatedTopicSectionNewsCmp>
                                                    </div>
                                                  </div>
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-summary-wrapper">
                                                    <div v-html="displayPostContent(postitem.summary)"></div>
                                                  </div>
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-content-wrapper">
                                                    <div v-html="displayPostContent(postitem.content)"></div>
                                                  </div>
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-content-source">
                                                    <div class="post-content-source-wrapper"><p>{{postitem.source}}</p></div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-tags-wrapper">
                                                    <div class="post-tags-container">{{$t('tags')}}: <div class="post-tags-list" v-html="displayPostTags(postitem.tags, postitem.tags_view)"></div></div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-share-wrapper">
                                                    <div id="specific-post-share-network-list" class="specific-post-share-network-list">
                                                      <ShareNetwork
                                                        v-for="network in networks"
                                                        :network="network.network"
                                                        :key="network.network"
                                                        :style="{backgroundColor: network.color}"
                                                        :url="sharing.url"
                                                        :title="sharing.title"
                                                        :description="sharing.description"
                                                        :quote="sharing.quote"
                                                        :hashtags="sharing.hashtags"
                                                        :twitterUser="sharing.twitterUser"
                                                      >
                                                        <i :class="network.icon"></i>
                                                        <span>{{ network.name }}</span>
                                                      </ShareNetwork>
                                                      <div class="share-network-zalo" data-v-0141c77e="" style="">
                                                        <div class="zalo-share-button" :data-href="genPostLink(pageType, postitem.id)" data-oaid="3776018789323949240" data-layout="3" data-color="white" data-customize="true" style=""><img src="/assets/img/ic_zalo.png" style=""/></div>
                                                      </div>
                                                      <a class="share-network-copy" href="javascript:void(0)" data-v-0141c77e="" style="background-color: rgb(51, 51, 51);">
                                                        <i class="fa fa-lg fa-files-o" data-v-0141c77e="" @click="copyUrl" :data-toggle="$t('copy_link_message')" :title="$t('copy_link_message')"></i>
                                                        <span data-v-0141c77e=""></span>
                                                      </a>
                                                      <a class="share-network-print" :href="genPrintLink(pageType, postitem.id)" style="background-color: rgb(51, 51, 51);" target="_blank">
                                                          <i class="fa fa-lg fa-print"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                      </article>
                                    </div>
                                </div>

                          <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <DefaultPostRelatedSectionNewsCmp
                                :parentComponentData="parentComponentData"
                                :postitem="postitem"
                                :pageType="pageType"
                                @content-page-clicked="contentPageChanged"
                              ></DefaultPostRelatedSectionNewsCmp>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <DefaultPostMoreSectionNewsCmp
                                :parentComponentData="parentComponentData"
                                :postitem="postitem"
                                :pageType="pageType"
                                @content-page-clicked="contentPageChanged"
                              ></DefaultPostMoreSectionNewsCmp>
                            </div>
                          </div>
                      </div>
                  </main>
            </div>
        </div>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ref from 'vue';

import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import DefaultPostRelatedSectionNewsCmp from "./DefaultPostRelatedSectionNewsCmp";
import DefaultPostMoreSectionNewsCmp from "./DefaultPostMoreSectionNewsCmp";
import DefaultPostRelatedTopicSectionNewsCmp from "./DefaultPostRelatedTopicSectionNewsCmp";

import DefaultOnepagePostRelatedSectionNewsCmp from "./DefaultOnepagePostRelatedSectionNewsCmp";
import DefaultOnepagePostMoreSectionNewsCmp from "./DefaultOnepagePostMoreSectionNewsCmp";
//import PostBreadCrumbCmp from "./PostBreadCrumbCmp";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import "jquery-sticky"

/* eslint-disable no-unused-vars */
/*
import grapesjs from 'grapesjs';
//import { grapesjs } from 'grapesjs';
//import { grapesjs } from 'grapesjs/dist/grapes.min.js';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js'
//import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
//import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'
*/

/*
import { loadScript } from "vue-plugin-load-script";
  loadScript("https://vietnamtourism.gov.vn/advancedPost/dist/grapes.min.js")
    .then(() => {
      
    })
    .catch(() => {
      
    });
*/


//const canvas = ref(null);
//let editor

export default {
  data() {
    return {
      postData: {},
      //genHTML: "",
      counter: this.$.vnode.key,
      //perPage: parseInt(this.parentComponentData.info.perPage),
      //totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
      sharing: {
        url: this.genPostLink(this.postitem.type, this.postitem.id),
        title: this.postitem.title,
        description: this.removeHTML(this.postitem.summary),
        quote: '',
        hashtags: '',
        twitterUser: ''
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fa fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'twitter', name: 'Twitter', icon: 'fa fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'email', name: 'Email', icon: 'fa fa-lg fa-envelope', color: '#333333' },
        //{ network: 'viber', name: 'Viber', icon: 'fa fa-lg fa-viber', color: '#59267c' },
        //{ network: 'messenger', name: 'Messenger', icon: 'fa fa-lg fa-facebook-messenger', color: '#0084ff' },
        //{ network: 'telegram', name: 'Telegram', icon: 'fa fa-lg fa-telegram-plane', color: '#0088cc' },
        //{ network: 'pinterest', name: 'Pinterest', icon: 'fa fa-lg fa-pinterest', color: '#bd081c' },
        //{ network: 'sms', name: 'SMS', icon: 'fa fa-lg fa-comment-dots', color: '#333333' },
        //{ network: 'tumblr', name: 'Tumblr', icon: 'fa fa-lg fa-tumblr', color: '#35465c' },
      ],
      copyMess: this.$t('copy_link_message'),
      bsPopover: null,
      options: {
        topSpacing: 20
      },
      specData: this.specificPostData
    };
  },
  props: ["postitem", "pageType", "parentComponentData", "specificPostData"],
  components: {
    DefaultPostRelatedSectionNewsCmp,
    DefaultPostMoreSectionNewsCmp,
    DefaultPostRelatedTopicSectionNewsCmp,
    DefaultOnepagePostRelatedSectionNewsCmp,
    DefaultOnepagePostMoreSectionNewsCmp,
    //PostBreadCrumbCmp,
  },
  created(){
  },
  computed: {},
  onMounted() {
    
  },
  mounted() {
    /*
    //alert(this.specData)
    const style = document.createElement('style')
    style.innerHTML = this.specData.styles
    document.head.appendChild(style)
    //alert(this.specificPostData.styles);
    */
    /*
    let grapesjsScript = document.createElement('script')
    grapesjsScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/grapesjs@0.20.1/dist/grapes.min.js')
    document.head.appendChild(grapesjsScript)
    */
    // eslint-disable-next-line no-unused-vars
    /*
    let editor = grapesjs.init({
      //grapesjs.init({
        //container: canvas.value,
        container: "#canvas",
        fromElement: true,
        storageManager: {
          type: 'local',
          autosave: true,
          autoload: true,
        },
        canvas: {
          styles: [],
          scripts: [],
        },
      });
      editor.setComponents(this.specificPostData.html);
      editor.setStyle(this.specificPostData.styles);
      //this.genHTML = editor.getHTML();
    */

    const style = document.createElement('style')
      style.innerHTML = this.specificPostData.styles
      document.head.appendChild(style)

    setTimeout(() => {
      var siteSticky = function() {
        var bSpacing = $('html').height() - $('.main-content-wrapper').height() - $('#share-network-list-fixed').height();  
        $("#share-network-list-fixed").sticky({topSpacing:90, bottomSpacing:bSpacing});
      };
      siteSticky();

      $('#post-content-wrapper table').each(function() {
            $(this).wrap('<div class="responsive-table"></div>');
      });

    }, 1000)
  },
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    },
    specificPostData(){
      //alert(this.specificPostData.styles);
      const style = document.createElement('style')
      style.innerHTML = this.specificPostData.styles
      document.head.appendChild(style)
    }
  },
  methods: {
    copyUrl: function(event) {
      window.navigator.clipboard.writeText(this.$baseUrl+this.$router.currentRoute.value.path);
        this.bsPopover = new Popover(
          event.target, 
          {
            placement: 'top', 
            trigger: 'click',
            offset: [0, 20],
          },
          )
        this.bsPopover.show()
        setTimeout(() => this.bsPopover.hide(), 1000);
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    updateHits(code, type){
      PublicService.updatePostHits(code, {'type':type, 'return':0}).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "hits")) {
                    this.currentVideoHits = response.data.hits;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    getRelatedPost(code, param) {
      PublicService.getaRelatedPost(code, param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.relatedPost = response.data;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    displayPostTags: function (tags, tagsView) {
      return FunctionService.displayPostTags(tags, tagsView);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    formatTimeDetail: function (time, locale) {
      return FunctionService.formatTimeDetail(time, locale);
    },
    genPrintLink: function (type, id) {
      return FunctionService.genPrintLink(type, id);
    },
  },
};
</script>

<style></style>
