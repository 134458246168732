<template>
    <section class="section-wrapper section-main-footer-wrapper">
        <!--
        <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
        <pre>{{ JSON.stringify(blockData, null, 2) }}</pre>
       -->
        <!--<pre>{{ blockData}}</pre>-->
        <div class="container-fluid section-main-footer-container" :style="sectionStyle">
            <div class="row">
              <div class="col-12">
                <div class="container-fluid section-main-footer-content">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 section-main-footer-1">
                      <div class="section-main-footer-info-wrapper">
                        <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                        <template v-for="(post, index) in blockData[0]"
                        :key="index"
                        >
                          <div v-if="post.images" class="section-main-footer-info section-main-footer-info-image">
                            <a v-if="post.link" :href="post.link" class="nav-link main-nav-link"><img :src="getImages('',post.images)"/></a>
                            <a v-else :href="'/cat/'+post.code" class="nav-link main-nav-link"><img :src="getImages('',post.images)"/></a>
                          </div>
                          <div v-else class="section-main-footer-info section-main-footer-info-text" v-html="displayPostContent(post.content)">
                          </div>
                      </template>
                      <!-- eslint-enable -->
                      </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 section-main-footer-2">
                        <div class="section-main-footer-link-wrapper">
                        <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                        <template v-for="(post, index) in blockData[1]"
                        :postitem="post" :key="index"
                        >
                          <div v-if="typeof(post.sub)=='object' && (post.sub).length>0" class="has-children footer-menu-item">
                            <a v-if="post.link" :href="post.link" class="nav-link main-nav-link">{{displayEntity(post.title)}}</a>
                            <a v-else :href="'/cat/'+post.code" class="nav-link main-nav-link">{{displayEntity(post.title)}}</a>
                            <ul class="dropdown arrow-top">
                              <template v-for="(sub, index1) in post.sub"
                              :key="index1"
                              >
                                <li>
                                  <a v-if="sub.link" :href="sub.link" class="nav-link sub-nav-link">{{displayEntity(sub.title)}}</a>
                                  <a v-else :href="'/cat/'+sub.code" class="nav-link sub-nav-link">{{displayEntity(sub.title)}}</a>
                                </li>
                              </template>
                            </ul>
                          </div>
                          <div v-else class="footer-menu-item">
                            <a v-if="post.link" :href="post.link" class="nav-link main-nav-link">{{displayEntity(post.title)}}</a>
                            <a v-else :href="'/cat/'+post.code" class="nav-link main-nav-link">{{displayEntity(post.title)}}</a>
                          </div>
                      </template>
                      <!-- eslint-enable -->
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-xs-12 section-main-footer-3">
                      <div class="section-main-footer-contact-wrapper">
                        <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                        <template v-for="(post, index) in blockData[2]"
                        :key="index"
                        >
                          <div v-if="post.images" class="section-main-footer-contact section-main-footer-contact-image">
                            <a v-if="post.link" :href="post.link" class="nav-link main-nav-link"><img :src="post.images"/></a>
                            <a v-else :href="'/cat/'+post.code" class="nav-link main-nav-link"><img :src="post.images"/></a>
                          </div>
                          <div v-else class="section-main-footer-contact section-main-footer-contact-text" v-html="post.content">
                          </div>
                      </template>
                      <!-- eslint-enable -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
    </section>
</template>

<script>

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import "jquery-sticky"

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData", "allData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      posts: [],
      blockData: [],
    };
  },
  created(){ 
    var vm = this;
    if(typeof(this.componentData.data.dataList)=='object'){
      this.getBlockData(this.componentData, vm);
    }
  },
  mounted(){
    /*
    var siteSticky = function() {
      $(".js-sticky-header").sticky({topSpacing:0});
    };
    siteSticky();
    */

    var siteMenuClone = function() {

      $('.js-clone-nav').each(function() {
        var $this = $(this);
        $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
      });


      setTimeout(function() {
        //console.log('menu log');
        var counter = 0;
        $('.site-mobile-menu .has-children').each(function(){
          var $this = $(this);
          
          $this.prepend('<span class="arrow-collapse collapsed">');

          $this.find('.arrow-collapse').attr({
            'data-toggle' : 'collapse',
            'data-target' : '#collapseItem' + counter,
          });

          $this.find('> ul').attr({
            'class' : 'collapse',
            'id' : 'collapseItem' + counter,
          });

          counter++;

        });

      }, 1000);

      $('body').on('click', '.arrow-collapse', function(e) {
        var $this = $(this);
        if ( $this.closest('li').find('.collapse').hasClass('show') ) {
          $this.removeClass('active');
        } else {
          $this.addClass('active');
        }
        e.preventDefault();  
        
      });

      $(window).resize(function() {
        var $this = $(this),
          w = $this.width();

        if ( w > 768 ) {
          if ( $('body').hasClass('offcanvas-menu') ) {
            $('body').removeClass('offcanvas-menu');
          }
        }
      })

      $('body').on('click', '.js-menu-toggle', function(e) {
        var $this = $(this);
        e.preventDefault();

        if ( $('body').hasClass('offcanvas-menu') ) {
          $('body').removeClass('offcanvas-menu');
          $this.removeClass('active');
        } else {
          $('body').addClass('offcanvas-menu');
          $this.addClass('active');
        }
      }) 

      // click outisde offcanvas
      $(document).mouseup(function(e) {
        var container = $(".site-mobile-menu");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ( $('body').hasClass('offcanvas-menu') ) {
            $('body').removeClass('offcanvas-menu');
          }
        }
      });
    }; 
    siteMenuClone();
  },
  computed: {
    sectionStyle() {
      let newStyle = {};
      if (this.componentData.data.bgImg.length > 0) {
        newStyle.backgroundImage = "url(" + this.componentData.data.bgImg + ")";
        if (
          this.componentData.data.bgImgFixed.length > 0 &&
          this.componentData.data.bgImgFixed == 1
        ) {
          newStyle.backgroundAttachment = "fixed";
        }
      } else {
        if (this.componentData.data.bgColor.length > 0) {
          newStyle.backgroundColor = this.componentData.data.bgColor;
        }
      }
      return newStyle;
    },
  },
  components: {

  },
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
              vm.blockData.push(dt.data.child);
            }
            else{
              vm.blockData.push(dt.data);
            }
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>