<template>
  <section
    v-if="posts.length"
    class="section-wrapper section-banner-wrapper section-banner-wrapper-v2"
    :style="[
      componentData.data.bgColor.length > 0
        ? { 'background-color': componentData.data.bgColor }
        : {},
    ]"
  >
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.parse(parentData.data)['data'] }}</pre>-->
    <div v-if="componentData.data.headline.length > 0" class="row">
      <div class="col text-center">
        <h1 class="section-wrapper-title section-banner-wrapper-title">
          {{ componentData.data.headline }}
        </h1>
      </div>
    </div>
    <div v-if="posts.length" class="section-banner-container">
      <div>
        <section id="banner">
          <div
            id="owl-list"
            class="owl-list owl-theme owl-carousel"
            style="height: auto;" 
          >
            <!--<pre>{{ JSON.stringify(post, null, 2) }}</pre>-->
            <BannerItem v-for="(post, index) in posts"
              :postitem="post"
              :key="index"
              @hasVideo="updateHasVideo"
            ></BannerItem>

          </div>
        </section>
      </div>
    </div>
    
  </section>
</template>

<script>
import "owl.carousel";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import BannerItem from "./BannerItemCmpV2";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      parentData: [],
      parentBlockData: [],
      hasVideo: 0,
    };
  },
  computed: {},
  watch: {
    hasVideo(){
      alert(this.hasVideo);
      jQuery(".owl-list").owlCarousel('destroy'); 
    }
  },
  components: {
    BannerItem,
  },
  methods: {
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;
                  this.parentBlockData = typeof(this.parentData.data)=='string'?JSON.parse(this.parentData.data):[];

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case -1:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    checkHasVideo(data){
      var hasData = 0;
      if(typeof(data) == 'object'){
        data.forEach(postData => {
          if(typeof(postData.video)=='string' && (postData.video).length > 0){
            hasData = 1;
            return 1;
          }
        });
      }
      return hasData;
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length>0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {

      this.hasVideo = this.checkHasVideo(this.posts);
      //alert(this.hasVideo);

      //console.log(owlCarousel);
      var owl = jQuery(".owl-list");
      if (owl.length > 0) {
        owl.owlCarousel({
          dots: true,
          items: 1,
          loop: true,
          //autoplay: true,
          //autoplayTimeout: 5000,
          //autoplayHoverPause: true,
          //slideSpeed: 300,
          video: true,
          videoWidth: false, // Default false; Type: Boolean/Number
          videoHeight: false, // Default false; Type: Boolean/Number
          nav: true,
          navText: [
            "<button type='button' class='btn btn-info btn-sm'>Previos</button>",
            "<button type='button' class='btn btn-info btn-sm'>Next</button>",
          ],
          animateOut: "fadeOut",
          animateIn: "fadeIn",
        });

        if(!this.hasVideo){
          //alert(this.parentBlockData.data.slideInterval)
          
          if(typeof(this.parentBlockData.data.slideInterval)=='string' && typeof(parseInt(this.parentBlockData.data.slideInterval)=='number')){
            owl.data('owl.carousel').options.autoplay = true;
            owl.data('owl.carousel').options.slideSpeed = true;
            owl.trigger( 'refresh.owl.carousel' );
          }
        }

        owl.on("changed.owl.carousel", function (event) {
          var item = event.item.index - 2; // Position of the current item
          jQuery("h1").removeClass("animated fadeInUp");
          jQuery(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h1")
            .addClass("animated fadeInUp");

          //console.log(event);
          //console.log(item);
          var current = event.item.index;
          /*
          console.log(
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length
          );
          */
          if (
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length > 0
          ) {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }

            var currentIframe = jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find("iframe");
            //console.log(currentIframe);
            if (currentIframe.length > 0) {
              var src = currentIframe.attr("src");
              console.log(src);
              currentIframe.attr("src", src + "&autoplay=1");
              currentIframe.addClass("playing-video");
            }
          } else {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }
          }
        });
      }
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    updateHasVideo(variable){
      this.hasVideo = variable;
    }
  },
  created() {
    //this.get();
    this.getAPIDataByType(this.componentData);
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>