<template>
  <div v-if="typeof postitem == 'object'">
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <PostBreadCrumbCmp :code="parentComponentData.code" :type="parentComponentData.type"></PostBreadCrumbCmp>
        </div>
      </div>
      <div class="row">
        <template v-for="(post, index) in postitem" :key="index">
          <div
            v-if="index == 0"
            class="section-default-list-video-item section-default-list-video-item-first col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-video-item-wrapper section-default-list-video-item-first-wrapper">
              <div class="row">
                <div class="col-12">
                  <a
                      class="section-default-list-video-post-info-title-link"
                      :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                    >
                      <h1
                        class="section-post-info-title section-default-list-video-post-info-title"
                      >
                        {{ displayEntity(post.title) }}
                      </h1>
                    </a>
                </div>
                <div class="col-12">
                  <div class="post-publish-date-wrapper">
                    {{$t('update_label')}}: <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">{{formatTime(post.publishup)}}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="post-view-wrapper">
                    {{$t('view_label')}}: <span v-if="(post.hits).length>0 && typeof(parseInt(post.hits))=='number'" class="post-view">{{post.hits}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="section-default-list-video-item-container col-xl-12 section-default-list-video-item-container-left"
                >
                  <div class="section-default-list-video-item-img-wrapper">
                    <div
                      class="section-default-list-video-item-video-container section-default-list-video-item-first-video-container"
                    >
                      {{updateHits(post.id, post.type)}}
                      <div v-if="post.videoembed" class="embed-responsive embed-responsive-16by9 video-play" v-html="post.videoembed">
                      </div>
                      <div v-else class="embed-responsive embed-responsive-16by9 video-play">
                        {{setCurrentVideo(post)}}
                        <video controls="" :poster="getImages('',post.poster)" loop class="embed-responsive-item" preload="none">
                          <source :src="getVideo(currentVideo.video)" :type="currentVideo.type">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="section-default-list-video-item section-default-list-video-item-second col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-video-item-wrapper section-default-list-video-item-second-wrapper">
              <div class="row">
                <div
                  class="section-default-list-video-item-container col-xl-12 section-default-list-video-item-container-left"
                >
                  <div class="section-default-list-video-item-img-wrapper">
                    <div
                      class="section-default-list-video-item-img-container section-default-list-video-item-second-img-container image-wrapper" v-lazy-container="{ selector: 'img' }"
                    >
                      <img
                        :data-src="getImages(post.summary + post.content, post.images)"
                        class="section-default-list-video-item-img section-default-list-video-item-second-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <a
                      class="section-default-list-video-post-info-title-link"
                      :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                    >
                      <h1
                        class="section-post-info-title section-default-list-video-post-info-title"
                      >
                        {{ shorten(displayEntity(post.title), 50) }} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                      </h1>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import PostBreadCrumbCmp from "./PostBreadCrumbV2Cmp";

export default {
  data() {
    return {
      isLoading: 1,
      postData: {},
      albumData: {},
      currentVideo: {},
      currentVideoHits:{},
      counter: this.$.vnode.key,
      perPage: parseInt(this.parentComponentData.info.perPage),
      totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  components: {
    PostBreadCrumbCmp
  },
  created(){
    //this.doGetAlbum(this.postitem);
  },
  computed: {},
  onMounted() {},
  mounted() {
  
  },
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    }
  },
  methods: {
    setCurrentVideo(video){
      this.currentVideo = this.getVideoFromPost(video);
    },
    getVideoFromPost: function (video) {
      return FunctionService.getVideoFromPost(video);
    },
    updateHits(code, type){
      PublicService.updatePostHits(code, {'type':type, 'return':0}).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "hits")) {
                    this.currentVideoHits = response.data.hits;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl, pos) {
      return FunctionService.getImages(str, imgUrl, pos);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    getVideo: function (video, opt=0) {
      return FunctionService.getVideo(video, opt);
    },
  },
};
</script>

<style></style>
