import { createRouter, createWebHistory } from 'vue-router'
//import { baseUrl, siteUrl } from '@/helpers/url';

//import BasicLayout from '@/layouts/BasicLayout.vue'
/*
import Home from '@/pages/HomePage.vue'
import About from '@/pages/AboutPage.vue'
import NotFound from '@/pages/NotFound.vue'
*/

import DefaultTheme from "./pages/ThemePage.vue";

import Home from "./components/HomeCmp.vue";
import Login from "./components/LoginCmp.vue";
import Register from "./components/RegisterCmp.vue";

//import Admin from "./pages/AdminPage.vue";
import Post from "./pages/PostPage.vue";
import Guides from "./pages/GuidePage.vue";
import Video from "./pages/VideoPage.vue";
import Album from "./pages/AlbumPage.vue";
import Docs from "./pages/DocPage.vue";
import Cat from "./pages/CatPage.vue";
import Sub from "./pages/SubPage.vue";
import Page from "./pages/PageTemp.vue";
import Tags from "./pages/TagPage.vue";
import Notice from "./pages/NoticePage.vue";
import Newest from "./pages/NewestPage.vue";
import Database from "./pages/DatabasePage.vue";
import Printer from "./pages/PrinterPage.vue";
//import DownloadPage from "./pages/DownloadPage.vue";

import Search from "./pages/SearchPage.vue";

import Statistic from "./pages/StatisticPage.vue";

//import VueI18n from 'vue-i18n';

// lazy-loaded
const Profile = () =>
    import ("./components/ProfileCmp.vue")
    /*
    const BoardAdmin = () =>
        import ("./components/BoardAdmin.vue")
    */
const BoardModerator = () =>
    import ("./components/BoardModerator.vue")
const BoardUser = () =>
    import ("./components/BoardUser.vue")
    /*
    const routes = [{
            path: "/",
            name: "home",
            component: Home,
            alias: [
                //siteUrl,
                //baseUrl
            ]
        },
        {
            path: "/home",
            component: Home,
        },
        {
            path: "/cat/:id",
            component: Cat,
            name: "Category",
            props: true,
            //alias: '/page'
            redirect: '/page/:id'
        }, {
            path: "/page/:id",
            component: Page,
            name: "Page",
            props: true,
        }, {
            path: "/admin",
            component: Admin,
            name: "Admin Home",
        },
        {
            path: "/post/:id",
            component: Post,
            name: "Post",
            alias: '/items/:id'
        },
        {
            path: "/login",
            component: Login,
        },
        {
            path: "/register",
            component: Register,
        },
        {
            path: "/profile",
            name: "profile",
            // lazy-loaded
            component: Profile,
        },
        {
            path: "/admin",
            name: "admin",
            // lazy-loaded
            component: BoardAdmin,
        },
        {
            path: "/mod",
            name: "moderator",
            // lazy-loaded
            component: BoardModerator,
        },
        {
            path: "/user",
            name: "user",
            // lazy-loaded
            component: BoardUser,
        },
    ];
    */

const routes = [{
        path: "/:lang(en|jp)?",
        name: "Default",
        component: DefaultTheme,
        //redirect: '/welcome',
        //base: location.pathname,
        //base: '/',
        alias: [
            //siteUrl,
            //baseUrl
        ],
        children: [{
                path: "/",
                name: "Default",
                component: DefaultTheme,
            },
            {
                path: "post/:id",
                component: Post,
                name: "Post",
                alias: '/items/:id',
                children: [{
                    path: "/:lang(en|jp)?/post/:id/:page",
                    component: Post,
                    name: "Post",
                    //props: true,
                }, ]
            },
            {
                path: "guides/:id",
                component: Guides,
                name: "Guides",
                //alias: '/items/:id'
                children: [{
                    path: "/:lang(en|jp)?/guides/:id/:page",
                    component: Guides,
                    name: "Guides",
                }, ]
            },
            {
                path: "video/:id",
                component: Video,
                name: "Video",
                //alias: '/items/:id'
                children: [{
                    path: "/:lang(en|jp)?/video/:id/:page",
                    component: Video,
                    name: "Video",
                }, ]
            },
            {
                path: "album/:id",
                component: Album,
                name: "Album",
                //alias: '/items/:id'
                children: [{
                    path: "/:lang(en|jp)?/album/:id/:page",
                    component: Album,
                    name: "Album",
                }, ]
            },
            {
                path: "docs/:id",
                component: Docs,
                name: "Docs",
                //alias: '/items/:id'
                children: [{
                    path: "/:lang(en|jp)?/docs/:id/:page",
                    component: Docs,
                    name: "Docs",
                }, ]
            },
            {
                path: "cat/:id",
                component: Cat,
                name: "Category",
                //props: true,
                children: [{
                    path: "/:lang(en|jp)?/cat/:id/:page",
                    component: Cat,
                    name: "Category",
                    //props: true,
                }, ]
            },
            {
                path: "sub/:id",
                component: Sub,
                name: "Subcategory",
                //props: true,
                children: [{
                    path: "/:lang(en|jp)?/sub/:id/:page",
                    component: Sub,
                    name: "Subcategory",
                }, ]
            },
            {
                path: "search",
                component: Search,
                name: "Search",
                //props: true,
                children: [{
                    path: "/:lang(en|jp)?/search",
                    component: Search,
                    name: "Search",
                    //props: true,
                }, ]
            },
            {
                path: "tags/:id",
                component: Tags,
                name: "Tags",
                //props: true,
                children: [{
                    path: "/:lang(en|jp)?/tags/:id/:page",
                    component: Tags,
                    name: "Tags",
                }, ]
            },
            {
                path: "statistic/:id",
                component: Statistic,
                name: "Statistic",
                //props: true,
            },
            {
                path: "statistic",
                component: Statistic,
                name: "Statistic",
                //props: true,
                children: [{
                    path: "/:lang(en|jp)?/statistic/:id",
                    component: Statistic,
                    name: "Statistic",
                    //props: true,
                }, ]
            },
            {
                path: "database",
                component: Database,
                name: "Database",
                //props: true,
            },
            {
                path: "page/:id",
                component: Page,
                name: "Page",
                //props: true,
            },
            {
                path: "notice",
                component: Notice,
                name: "Notice",
                //props: true,
            },
            {
                path: "newest",
                component: Newest,
                name: "Newest",
                //props: true,
            },
            {
                path: "printer/:id",
                component: Printer,
                name: "Printer",
            },
            {
                path: "login",
                component: Login,
                name: "Login",
            },
            {
                path: "register",
                component: Register,
                name: "Register",
            },
        ]
    },
    {
        path: "/welcome",
        component: Home,
    },
    {
        path: "/en",
        name: "HomeEn",
        component: Home,
    },
    {
        path: "/jp",
        component: Home,
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/search",
        component: Search,
        name: "Search",
        //props: true,
        children: [{
            path: "/:lang(en|jp)?/search",
            component: Search,
            name: "Search",
            //props: true,
        }, ]
    },
    /*
    {
        path: "/admin",
        component: Admin,
        name: "Admin Home",
    },
    */
    {
        path: "/post1/:id",
        component: Post,
        name: "Post1",
        alias: '/items1/:id'
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/register",
        component: Register,
    },
    {
        path: "/profile",
        name: "profile",
        // lazy-loaded
        component: Profile,
    },
    /*
    {
        path: "/admin",
        name: "admin",
        // lazy-loaded
        component: BoardAdmin,
    },
    */
    {
        path: "/mod",
        name: "moderator",
        // lazy-loaded
        component: BoardModerator,
    },
    {
        path: "/user",
        name: "user",
        // lazy-loaded
        component: BoardUser,
    },
    {
        path: "/index.php/cat/:id",
        redirect: { name: 'Category' },
    },
    {
        path: "/index.php/cat/:id/:page",
        redirect: { name: 'Category' },
    },
    {
        path: "/index.php/sub/:id",
        redirect: { name: 'Subcategory' },
    },
    {
        path: "/index.php/sub/:id/:page",
        redirect: { name: 'Subcategory' },
    },
    {
        path: "/index.php/items/:id",
        redirect: { name: 'Post' },
        /*
        component: Post,
        name: "Post",
        alias: '/items/:id',
        children: [{
            path: "/:lang(en|jp)?/post/:id/:page",
            component: Post,
            name: "Post",
            //props: true,
        }, ]
        */
    },
    {
        path: "/index.php/items/:id/:page",
        redirect: { name: 'Post' },
    },
    {
        path: "/index.php/album/:id",
        redirect: { name: 'Album' },
    },
    {
        path: "/index.php/album/:id/:page",
        redirect: { name: 'Album' },
    },
    {
        path: "/index.php/video/:id",
        redirect: { name: 'Video' },
    },
    {
        path: "/index.php/video/:id/:page",
        redirect: { name: 'Video' },
    },
    {
        path: "/index.php/docs/:id",
        redirect: { name: 'Docs' },
    },
    {
        path: "/index.php/docs/:id/:page",
        redirect: { name: 'Docs' },
    },
    {
        path: "/index.php/guides/:id",
        redirect: { name: 'Guides' },
    },
    {
        path: "/index.php/guides/:id/:page",
        redirect: { name: 'Guides' },
    },
    {
        path: "/index.php/tags/:id",
        redirect: { name: 'Tags' },
    },
    {
        path: "/index.php/tags/:id/:page",
        redirect: { name: 'Tags' },
    },
    {
        path: "/english",
        redirect: { name: 'HomeEn' },
    },
    {
        path: "/english/index.php/cat/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/cat/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/cat/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/cat/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/sub/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/sub/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/sub/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/sub/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/items/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/post/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/items/:id/:page",
        beforeEnter: (to, from, next) => {
            let uid = to.params.id;
            next({ path: '/en/post/' + uid })
        }
    },
    {
        path: "/english/index.php/album/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/album/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/album/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/album/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/video/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/video/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/video/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/video/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/docs/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/docs/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/docs/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/docs/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/guides/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/guides/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/guides/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/guides/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/tags/:id",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/tags/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    {
        path: "/english/index.php/tags/:id/:page",
        beforeEnter: (to, from, next) => {
                let uid = to.params.id;
                next({ path: '/en/tags/' + uid })
            }
            //redirect: '/en/cat/:id',
    },
    /*
    {
        path: "/index.php/cat/:id",
        component: Cat,
        name: "Category",
        props: true,
        //redirect: { name: 'Category', params: { id: 'id' } }.
        children: [{
            path: "/english/index.php/cat/:id",
            component: Cat,
            name: "Category",
            props: true,
        }, ]
    },
    {
        path: "/index.php/sub/:id",
        component: Sub,
        name: "Subcategory",
        props: true,
        //redirect: { name: 'Category', params: { id: 'id' } }.
        children: [{
            path: "/english/index.php/sub/:id",
            component: Sub,
            name: "Subcategory",
            props: true,
        }, ]
    },
    {
        path: "/index.php/items/:id",
        component: Post,
        name: "Post",
        props: true,
        children: [{
            path: "/english/index.php/items/:id",
            component: Post,
            name: "Post",
            props: true,
        }, ]
    },
    */

    /*
    {
        path: "/:lang(en|jp)/statistic/",
        component: Statistic,
        name: "Statistic",
        props: true,
        children: [{
            path: "/statistic/:id",
            component: Statistic,
            name: "Statistic",
            props: true,
        }, ]
    },
    */
    /*
    {
        path: "/statistic/",
        component: Statistic,
        name: "Statistic",
        props: true,
        children: [{
            path: "/statistic/:id",
            component: Statistic,
            name: "Statistic",
            props: true,
        }, ]
    },
    */
    /*
    {
        path: "/docs/download/:id",
        name: "Download",
        component: DownloadPage,
    },
    */
];
// eslint-disable-next-line no-unused-vars
function setLocale(to, from) {
    /*
    console.log('************************************');
    console.log(to);
    console.log(from);
    console.log(to.params.lang);
    //alert(to.params.lang);
    console.log('************************************');
    */
    //let { locale } = to.params.lang;
    let locale = to.params.lang;
    let storedLocale = localStorage.getItem('lang');
    //let { locale } = to.redirectedFrom.params.lang;
    if (typeof(locale) != 'string' || locale == '') {
        var langArr = ["/en/ ", "/jp/"];
        var langStr = to.path.substring(0, 4);
        var filteredMatches = langArr.filter(function(val) {
            return val.indexOf(langStr) == 0;
        });
        //alert(JSON.stringify(filteredMatches));
        if (filteredMatches.length == 1) {
            //locale = '';
            locale = filteredMatches[0].replace(/\//g, '');
            locale = locale.replace(' ', '');
        } else {
            locale = 'vi';
        }

        if (typeof(storedLocale) == 'string' && storedLocale.length > 0) {
            //locale = storedLocale;
        } else {
            //locale = 'vi';
        }
    }
    //alert(locale);
    localStorage.setItem('lang', locale);
    //alert(localStorage.getItem('lang'));
    // Do something with locale, check availability of messages etc.
    //i18n.locale = locale;
    //next();

}

const router = createRouter({
    history: createWebHistory(),
    //history: createWebHistory('/en/'),
    //mode: "history",
    //base: "/en/",
    //base: "/",
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

router.beforeEach((to, from, next) => {
    setLocale(to, from, next);
    next();
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;



/*
const routes = [{
        path: siteUrl + '/site',
        alias: [
            siteUrl,
            baseUrl
        ],
        component: BasicLayout,
        children: [{
                path: '',
                name: 'Home',
                component: Home
            },
            {
                path: 'about',
                name: 'About',
                component: About
            }
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
]

export default createRouter({
    history: createWebHistory(),
    routes
})
*/