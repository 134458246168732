<template>
  <div id="default-theme-wrapper" :class="'page-wrapper-'+lang">
    <div class="wrapper">
        <router-view v-if="routeComponent!=0"></router-view>
        <div v-else>
          <component :is="components[0].name" :ref="el => setComponents(0,el)"/>     
        </div>

    </div>
    <!--/.wrapper-->
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { defineAsyncComponent } from 'vue'
import RouteConfig from "../configs/routeconfig"
export default {
  components: {
      "Cover" : defineAsyncComponent(() => import('../components/HomeCmp.vue')),
      //"Home" : defineAsyncComponent(() => import('../components/HomeCmp.vue')),
      //"PostItem" : defineAsyncComponent(() => import('./PostPage.vue')),
      //"CatItem" : defineAsyncComponent(() => import('./CatPage.vue')),
  },
  setup() {
    /*
    const route = useRoute()
    var routeComponent = RouteConfig.getRouteView(route.name);
    console.log('route.name: ' + route.name);
    console.log('routeComponent: '+ routeComponent);
    */

    const components = [
      {name: 'Cover', key: "0"},
      {name: 'Home', key: "1"},
      {name: 'PostItem', key: "2"},
      {name: 'CatItem', key: "3"},
    ];
    const comRefs = {};
    const setComponents = (key, el) => {
      //console.log('setComponents', key, el);
      comRefs[key] = el;
    }
    
    return {
      components,
      setComponents
    }
  },
  data() {
    const route = useRoute()
    //route.params.id;
    //console.log(route.name);
    var routeComponent = RouteConfig.getRouteView(route.name);
    //console.log(routeComponent);
    const lang = localStorage.getItem('lang');
    return {
      post: null,
      posts: [],
      routeComponent: routeComponent,
      lang
    };
  },
  computed: {
    imgPreview() {
      return this.images == "" ? this.imgPlaceholder : this.images;
    },
    strippedHtml() {
        let regex = /(<([^>]+)>)/ig;
        return this.summary.content.rendered.replace(regex, "");
    }
  },
  methods: {
    getImages(str, imgUrl){
      var imgSrc = str.match(/<img [^>]*src="[^"]*"[^>]*>/gm).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
      return typeof(imgSrc[0])=='string'?imgSrc[0]:imgUrl;
    },
    get() {
      
    },
  },
  created() {
    //this.get();
    this.$root.$i18n.locale = localStorage.getItem('lang');
  }
}
</script>

<style lang="sass" scoped>
.counter
  font-size: 80px
  margin: 0
</style>