import { createStore } from 'vuex'
import { auth } from "./auth.module";
import counterModule from './modules/counter'
//import AppConstants from '../constant'

/*
import { isProxy, toRaw } from 'vue';
if (isProxy(AppConst)) {
    const AppConst = toRaw(this.$store.state.AppConst);
    console.log(AppConst);
}
*/
//console.log(AppConst);

/*
export default createStore({
  modules: {
    counter: counterModule
  }
})
*/

const store = createStore({
    modules: {
        auth,
        counter: counterModule,
        //AppConst: AppConstants
    },
});

export default store;