class RouteConfig {
    getRouteView(name) {
        var viewPath = '';
        switch (name) {
            case "Cover":
                viewPath = 0;
                break;
            case "Home":
                viewPath = 1;
                break;
            case "Post":
                viewPath = 2;
                break;
            case "Guides":
                viewPath = 3;
                break;
            case "Video":
                viewPath = 4;
                break;
            case "Album":
                viewPath = 5;
                break;
            case "Docs":
                viewPath = 6;
                break;
            case "Category":
                viewPath = 9;
                break;
            case "Subcategory":
                viewPath = 10;
                break;
            case "Tags":
                viewPath = 11;
                break;
            case "Statistic":
                viewPath = 12;
                break;
            case "Notice":
                viewPath = 13;
                break;
            case "Newest":
                viewPath = 14;
                break;
            case "Database":
                viewPath = 15;
                break;
            case "Printer":
                viewPath = 16;
                break;
            case "Search":
                viewPath = 99;
                break;
            case "Login":
                viewPath = 110;
                break;
            case "Register":
                viewPath = 111;
                break;
            default:
        }
        return viewPath
    }
}

export default new RouteConfig();