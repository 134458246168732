<template>
  <div v-if="typeof(postData) == 'object' && Object.keys(postData).length>0" :class="typeof(postData.video)=='string' && (postData.video).length>0?'video-item item':'item'">
    <div v-if="typeof(postData.video)=='string' && (postData.video).length > 0" class="video-background">
      <div class="video-foreground">
        <div v-html="postData.video"> </div>
      </div>
    </div>
    <div v-if="typeof(postData.video)=='string' && (postData.video).length == 0" class="section-9-item-wrapper">
      <span v-if="(postData.images).length>0"
        :style="
          `background-image: url(${getImages('',postData.images)})`
        "
      ></span>
      <div class="container section-9-container">
        <div class="section-9-info">
          <div v-if="(postData.content).length>0">
            <div class="section-9-content">
              <div v-html="postData.content"> </div>
            </div>
          </div>
          <div v-else class="caption">
            <h1 v-if="(postData.summary).length>0" class="animated fadeInUp">{{removeHTML(postData.summary)}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

export default {
  data() {
    return {
      postData: {},
    };
  },
  props: ["postitem"],
  computed: {},
  mounted() {
    this.postData = this.postitem;
  },
  methods: {
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
  },
};
</script>

<style></style>
