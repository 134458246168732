<template>
  <div v-if="typeof(postData) == 'object' && Object.keys(postData).length>0" :class="typeof(postData.video)=='string' && (postData.video).length>0?'video-item':'item'">
    <div v-if="typeof(postData.video)=='string' && (postData.video).length > 0" class="video-background">
      <div class="video-foreground">
        <div v-html="postData.video"></div>
      </div>
    </div>
    <div v-if="typeof(postData.video)=='string' && (postData.video).length == 0" :class="'banner-item-wrapper'+' banner-item-wrapper-'+postData.id">
      <img class="img-size" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="img-size" v-else-if="typeof(cItemData.images) != 'object' && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="multi-background-banner d-xxl-none d-xl-none d-lg-none d-md-none d-sm-none d-block" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length>0 && typeof(cItemData.images['images_xs'])=='string' && (cItemData.images['images_xs']).length>0"
        :src="getImages('',cItemData.images['images_xs'])"
      >
      <img class="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-none d-block" v-else-if="typeof(cItemData.images) == 'object' && (cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="multi-background-banner d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-none" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length>0 && typeof(cItemData.images['images_sm'])=='string' && (cItemData.images['images_sm']).length>0"
        :src="getImages('',cItemData.images['images_sm'])"
      >
      <img class="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-none" v-else-if="typeof(cItemData.images) == 'object' && (cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="multi-background-banner d-xxl-none d-xl-none d-lg-none d-md-block d-sm-none d-none" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length>0 && typeof(cItemData.images['images_md'])=='string' && (cItemData.images['images_md']).length>0"
        :src="getImages('',cItemData.images['images_md'])"
      >
      <img class="d-xxl-none d-xl-none d-lg-none d-md-block d-sm-none d-none" v-else-if="typeof(cItemData.images) == 'object' && (cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="multi-background-banner d-xxl-none d-xl-none d-lg-block d-md-none d-sm-none d-none" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length>0 && typeof(cItemData.images['images_lg'])=='string' && (cItemData.images['images_lg']).length>0"
        :src="getImages('',cItemData.images['images_lg'])"
      >
      <img class="d-xxl-none d-xl-none d-lg-block d-md-none d-sm-none d-none" v-else-if="typeof(cItemData.images) == 'object' && (cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="multi-background-banner d-xxl-none d-xl-block d-lg-none d-md-none d-sm-none d-none" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length>0 && typeof(cItemData.images['images_xl'])=='string' && (cItemData.images['images_xl']).length>0"
        :src="getImages('',cItemData.images['images_xl'])"
      >
      <img class="d-xxl-none d-xl-block d-lg-none d-md-none d-sm-none d-none" v-else-if="typeof(cItemData.images) == 'object' && (cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <img class="multi-background-banner d-xxl-block d-xl-none d-lg-none d-md-none d-sm-none d-none" v-if="typeof(cItemData.images) == 'object' && Object.keys(cItemData.images).length>0 && typeof(cItemData.images['images_xxl'])=='string' && (cItemData.images['images_xxl']).length>0"
        :src="getImages('',cItemData.images['images_xxl'])"
      >
      <img class="d-xxl-block d-xl-none d-lg-none d-md-none d-sm-none d-none" v-else-if="typeof(cItemData.images) == 'object' && (cItemData.images).length==0 && (postData.images).length>0"
        :src="getImages('',postData.images)"
      >
      <div class="home-multiple-greeting-item">
        <div class="home-multiple-greeting-item-content" v-if="(postData.content).length">
          <div class="home-multiple-greeting-item-content-inner" v-html="postData.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import PublicService from "../services/public.service";
import FunctionService from "../../services/function.service";

export default {
  data() {
    return {
      postData: {},
      cItemData: [],
    };
  },
  props: ["postitem"],
  computed: {},
  mounted() {
    this.postData = this.postitem;
    try {
      if(typeof(this.postitem.data)=='string' && (this.postitem.data).length>0){
        this.cItemData = JSON.parse(this.postitem.data);
      }
    }
    catch(err) {
      //document.getElementById("demo").innerHTML = err.message;
    } 
  },
  methods: {
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    updateHasVideo(slideHasVideo){
      this.$emit("hasVideo", slideHasVideo);
    }
  },
};
</script>

<style></style>
