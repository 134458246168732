<template>
  <section class="section-wrapper section-default-printer-content-container">
    <!--
    <pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(parentComponentDataItem, null, 2) }}</pre>
    -->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div class="container-fluid default-printer-content-container">
      <div>
        <div
          v-if="layout == 0"
          class="container section-default-list-content-container-1"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col"
            >
              <div class="section-default-list-content-container-content-outter">
                <div v-if="pageType == 1">
                  <div class="section-default-list-content-container-content">
                    <PrinterDisplayCmp
                      :parentComponentData="parentComponentData"
                      :postitem="parentComponentDataItem"
                      :pageType="pageType"
                      @content-page-clicked="contentPageChanged"
                    ></PrinterDisplayCmp>
                  </div>
                </div>
                <div v-else-if="pageType == 2">
                </div>
                <div v-else-if="pageType == 3">
                </div>
                <div v-else-if="pageType == 4">
                </div>
                <div v-else-if="pageType == 5">
                </div>
                <div v-else-if="pageType == 6"></div>
                <div v-else-if="pageType == 7"></div>
                <div v-else-if="pageType == 8"></div>
                <div v-else-if="pageType == 9"></div>
                <div v-else-if="pageType == 18">
                </div>
                <div v-else-if="pageType == 19"></div>
                <div v-else></div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 1"
          class="container section-default-list-content-container-1"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div v-if="pageType == 1">
                  <div class="section-default-list-content-container-content">
                    <PrinterDisplayCmp
                      :parentComponentData="parentComponentData"
                      :postitem="parentComponentDataItem"
                      :pageType="pageType"
                      @content-page-clicked="contentPageChanged"
                    ></PrinterDisplayCmp>
                  </div>
                </div>
                <div v-else-if="pageType == 2">
                </div>
                <div v-else-if="pageType == 3">
                </div>
                <div v-else-if="pageType == 4">
                </div>
                <div v-else-if="pageType == 5">
                </div>
                <div v-else-if="pageType == 6"></div>
                <div v-else-if="pageType == 7"></div>
                <div v-else-if="pageType == 8"></div>
                <div v-else-if="pageType == 9"></div>
                <div v-else-if="pageType == 18">
                </div>
                <div v-else-if="pageType == 19"></div>
                <div v-else></div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute } from "vue-router";

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";


import PrinterDisplayCmp from "./cmpsItem/PrinterDisplayNewsCmp";

/*
import DefaultPostSectionNewsCmp from "./cmpsItem/DefaultPostSectionNewsCmp";
import DefaultPostSectionAlbumCmp from "./cmpsItem/DefaultPostSectionAlbumCmp";
import DefaultPostSectionVideoCmp from "./cmpsItem/DefaultPostSectionVideoCmp";
import DefaultPostSectionDocCmp from "./cmpsItem/DefaultPostSectionDocCmp";
import DefaultPostSectionGuideCmp from "./cmpsItem/DefaultPostSectionGuideCmp";

import DefaultListSectionSubList1Cmp from "../cmps/DefaultListSectionSubList1Cmp";
import WidgetBlock1 from "../cmps/WidgetBlock1";
import WidgetBlock2 from "../cmps/WidgetBlock2";
import WidgetBlock3 from "../cmps/WidgetBlock3";
import WidgetBlock4 from "../cmps/WidgetBlock4";
*/

import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: [
    "loggedIn",
    "componentData",
    "parentComponentData",
    "parentComponentDataItem",
  ],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      content: [],
      parentData: [],
      isLoading: 1,
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      layout: 1,
      pageType: this.parentComponentData.type,
      endpoint: "PageConfig/getPageConfig",
      param: {},
    };
  },
  computed: {},
  components: {
    PrinterDisplayCmp,
    /*
    DefaultPostSectionNewsCmp,
    DefaultPostSectionAlbumCmp,
    DefaultPostSectionVideoCmp,
    DefaultPostSectionDocCmp,
    DefaultPostSectionGuideCmp,
    DefaultListSectionSubList1Cmp,
    WidgetBlock1,
    WidgetBlock2,
    WidgetBlock3,
    WidgetBlock4,
    */
  },
  async created() {
    //this.get();
    //this.getAPIDataByType(this.componentData);
    this.param = {'offset': this.currentPage, 'callType':1};
    await this.getPageDataFromApi(
      this.cRoute,
      this.cCode,
      this.endpoint,
      this.param
    );
  },
  methods: {
    contentPageChanged(page) {
      this.$emit("content-page-clicked", page);
    },
    searchFilterChanged(param){
      this.$emit("search-filter-changed", param);
    },
    getPageDataFromApi(type, code, endpoint, param) {
      //param['callType'] = 1;
      param = { type: type, position: "contentRight" };
      PublicService.getPublicPageConfig(code, endpoint, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.content = response.data;
            this.isLoading = 0;
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length > 0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {},
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    get() {
      const route = useRoute();
      const id = route.params.id;
      PublicService.getCatPublicContent(id).then(
        (response) => {
          //console.log("DefaultPostSectionCmp");
          //console.log(response);
          //this.content = response.data;
          this.posts = response.data.child;
          this.cat = response.data.parent;
          //console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>