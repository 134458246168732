<template>
  <section
    v-if="componentData"
    class="section-wrapper section-default-list-content-wrapper"
    :style="[
      componentData.data.bgColor.length > 0
        ? { 'background-color': componentData.data.bgColor }
        : {},
    ]"
  >
    <!--
    <pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(parentComponentDataItem, null, 2) }}</pre>
    -->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div
      class="container-fluid section-default-list-content-container"
    >
      <div>
        <div
          v-if="layout == 0"
          class="container section-default-list-content-container-1"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>
            </div>
            <div
              class="container section-default-list-content-container-right col"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 1"
          class="container section-default-list-content-container-1"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>

            </div>
            <div
              class="container section-default-list-content-container-right col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 2"
          class="container section-default-list-content-container-2"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>
            </div>
            <div
              class="container section-default-list-content-container-right col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 3"
          class="container section-default-list-content-container-3"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>
            </div>
            <div
              class="container section-default-list-content-container-right col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-default-list-content-container-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-default-list-content-container-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 9"
          class="container section-default-list-content-container-9"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 10"
          class="section-default-list-content-container-0"
        >
          <div class="row">
            <div
              class="container section-default-list-content-container-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div class="section-default-list-content-container-content-outter">
                <div class="section-default-list-content-container-content">
                  <DefaultTagSectionResultCmp
                    :componentData="this.componentData"
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultTagSectionResultCmp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute } from "vue-router";

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import DefaultTagSectionResultCmp from "./cmpsItem/DefaultTagSectionResultCmp";

import DefaultListSectionSubList1Cmp from "../cmps/DefaultListSectionSubList1Cmp";
import WidgetBlock1 from "../cmps/WidgetBlock1";
import WidgetBlock2 from "../cmps/WidgetBlock2";
import WidgetBlock3 from "../cmps/WidgetBlock3";
import WidgetBlock4 from "../cmps/WidgetBlock4";

import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: [
    "loggedIn",
    "componentData",
    "parentComponentData",
    "parentComponentDataItem",
  ],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      content: [],
      parentData: [],
      isLoading: 1,
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      layout: this.componentData.data.layout,
      pageType: this.parentComponentData.type,
      endpoint: "PageConfig/getSearchPageConfig",
      param: {},
    };
  },
  computed: {},
  components: {
    DefaultTagSectionResultCmp,
    DefaultListSectionSubList1Cmp,
    WidgetBlock1,
    WidgetBlock2,
    WidgetBlock3,
    WidgetBlock4,
  },
  async created() {
    //this.get();
    //this.getAPIDataByType(this.componentData);
    this.param = {'offset': this.currentPage, 'callType':1};
    await this.getPageDataFromApi(
      this.cRoute,
      this.param
    );
  },
  methods: {
    contentPageChanged(page) {
      this.$emit("content-page-clicked", page);
    },
    searchFilterChanged(param){
      this.$emit("search-filter-changed", param);
    },
    getPageDataFromApi(type, param){
      param = {'type':type, 'position':'contentRight'};
            PublicService.getSearchPageConfig('PageConfig/getSearchPageConfig',param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.content = response.data;
                    this.isLoading = 0;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length > 0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {},
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    get() {
      const route = useRoute();
      const id = route.params.id;
      PublicService.getCatPublicContent(id).then(
        (response) => {
          //console.log("DefaultListSectionCmp");
          //console.log(response);
          //this.content = response.data;
          this.posts = response.data.child;
          this.cat = response.data.parent;
          //console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
