<template>
  <div id="app" class="app-wrapper">
    <div class="fluid-container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang'),
    };
  },
  created(){ 
    const html = document.documentElement
    html.setAttribute('lang', this.lang)
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
