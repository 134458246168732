<template>
  <div class="row post mb-5">
    <div class="col-12 col-sm-4 text-center">
      <div class="list-item-img-wrapper">
        <img
          v-if="checkImg"
          class="img-fluid list-item-img list-item-landscape"
          :src="getImages(post.summary+post.content, post.images)"
          alt
        />
        <img v-else :src="imgPlaceholder" alt />
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <h4><router-link class="" :to="'/post/' + post.id">{{displayEntity(post.title)}}</router-link></h4>
      <p>{{displayHtmlContent(post.summary, 200)}}</p>
    </div>
    <div class="col-12">
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  props: ["post", "index", "imgPlaceholder"],
  computed: {
    checkImg(){
      return this.images != '';
    },
    strippedHtml() {
        let regex = /(<([^>]+)>)/ig;
        if(typeof(this.summary)=='string' && this.summary.length>0){
          return this.summary.replace(regex, "");
        }
        else{
          return this.summary;
        }
    }
  },
  methods: {
    removeHTML(content) {
      let regex = /(<([^>]+)>)/ig;
      if(typeof(content)=='string' && content.length>0){
        return content.replace(regex, "");
      }
      else{
        return content;
      }
    },
    displayEntity(str){
      var textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    },
    displayHtmlContent(content,limit=0){
      content = this.removeHTML(content);
      content = this.displayEntity(content);
      if(limit){
        //content = this.shorten(content, limit);
        const truncate = (str, max, suffix) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
        content = truncate(content, limit, '...'); 
      }
      return content;
    },
    getImages(str, imgUrl){
      if(typeof(str)=='string' && str.length>0){
        var imgArr = str.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
        var imgSrc = Array();
        //console.log(imgArr);
        //console.log(typeof(imgArr));
        if(typeof(imgArr)=='object'){
            if(imgArr != null && Object.keys(imgArr).length>0){
              imgSrc = imgArr.map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
              //alert(imgSrc[0]);
              //console.log(typeof(imgSrc[0]));
              return typeof(imgSrc[0])=='string'?'https://a99d2de44ad7485.cmccloud.com.vn/vn'+imgSrc[0]:imgUrl;
            }
            else{
              return imgUrl;
            }
        }
        else{
          return imgUrl;
        }
      }
      else{
        return imgUrl;
      }
    },
    shorten(str, maxLen, separator = ' ') {
      if (str.length <= maxLen) return str;
      return str.substr(0, str.lastIndexOf(separator, maxLen));
    },
    logData(data){
        console.log(data);
    },
  }
};
</script>

<style>
</style>