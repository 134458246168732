const PAGE_LANG = 'vi'
const PAGE_LOCALE = 'vi-VN'
const BASE_URL = window.location.origin
    //const BASE_ALIAS = '/en'
const BASE_ALIAS = ''
const NO_IMAGE_HOLDER = "https://images.vietnamtourism.gov.vn/vn/images/e-logo.jpg"
const CDN_URL_ALLOW = 1
const FILE_CDN_URL_ALLOW = 1
const REPLACE_IMG_BASE_URL_ALLOW = 1
const REPLACE_FILE_BASE_URL_ALLOW = 1
const CDN_URL = "https://images.vietnamtourism.gov.vn/"
const FILE_URL = "https://images.vietnamtourism.gov.vn/vn/"
const SECOND_API_URL = "http://demogov.leanhtuan.work/"
const IMG_FILTER = ["jpg", "jpeg", "png", "gif", "bmp"];
const FILE_FILTER = ["doc", "docx", "pdf", "xls", "xlsx", "mp4", "flv", "ogg", "webm", "mp3", "jpg", "jpeg", "png", "gif", "bmp", "rar", "zip"];
const REDIS_ALLOW = 1
const REDIS_EXP = 300
const CHECK_REMOTE_EXIST = 0
const POST_TYPE_LINK = { 1: "post", 2: "video", 3: "album", 4: "docs", 5: "guides", 6: "clicks", 7: "draft", 8: "guides", 18: "event" };
const CAT_TYPE_LINK = { 1: "cat", 2: "cat", 3: "cat", 4: "cat", 7: "cat", 8: "cat", 18: "cat" };
const SUBCAT_TYPE_LINK = { 1: "sub", 2: "sub", 3: "sub", 4: "sub", 7: "sub", 8: "sub", 18: "sub" };
const SITE_META = {
    "title": "Cục Du lịch Quốc gia Việt Nam",
    "desc": "Trang thông tin điện tử chính thức của Cục Du lịch Quốc gia Việt Nam phục vụ công tác quản lý nhà nước về du lịch",
    "appName": "vietnamtourism.gov.vn",
    "keywords": "TITC, Trung tam Thong tin du lich, Cuc Du lich Quoc gia Viet Nam, Du lich Viet Nam, du lịch việt nam, Viet Nam, Vietnam, Du lich, Du lich Van hoa, Lu hanh, le hoi, diem du lich tai viet nam, diem tham quan, khu du lich, du lich sinh thai, nghi mat, du lich nghi duong, di san, cong ty du lich, khach san, nha hang, co so luu tru, danh thang, vui choi, giai tri, Portal, Hanoi, Ha Noi, Saigon, Sai gon, Thanh pho Ho Chi Minh, Tin tuc, Tintuc, Van ban, VanBan, tin lanh dao, tin hoat dong, Van ban quan ly nha nuoc, Livefully in Vietnam, Viet Nam ve dep bat tan",
    "image": "https://images.vietnamtourism.gov.vn/vn/images/logo/LogoTimelesscharm.jpg",
    "icon": "https://vietnamtourism.gov.vn/application/views/2019/images/2018/favicon.png",
    "author": "TITC",
    "copyright": "TITC",
    "email": "contact@vietnamtourism.gov.vn",
    "robots": "INDEX,FOLLOW,ALL",
    "country": "VietNam",
    "revisitAfter": "1 day",
    "documentRating": "General",
    "documentDistribution": "Global",
    "distribution": "Global",
    "area": "News, Document",
    "placename": "Vietnam",
    "resourceType": "Document",
    "owner": "vietnamtourism.gov.vn",
    "classification": "Vietnam",
    "rating": "All",
    "twitterCard": "summary_large_image",
    "title_en": "Viet Nam National Authority of Tourism",
    "desc_en": "Official website of the Viet Nam National Authority of Tourism",
    "keywords_en": "TITC, Tourism Information Technology Center, Viet Nam National Authority of Tourism, vietnam, vietnam tourism, tourist, tourism, travel, vietnam Travel, travel to vietnam, festival in vietnam, hotel, hotel in vietnam, tourist office, travel agency, tourist company, tourist company in vietnam, tour, tour in vietnam, booking, reservation, restaurant, restaurant in vietnam, destination, destination in vietnam, cultural tourism, ecotourism, world heritage sites, patrimoine mondial au vietnam, entertainment, entertainment in vietnam, visa, embassy, embassies, information, useful reading, transport, customs, guide, agence, trip, excursion, flight, voyage, Cultural tourism, travel, festival, tourist spots in Vietnam, tourist sites, ecotourism, vacation, leisure travel, heritage, travel companies, hotels, restaurants, tourist accommodations, tourist attractions, leisure, entertainment, Portal, Hanoi, Ha Noi, Saigon, Sai gon, Ho Chi Minh City, news, documents, leadership news, latest news, state management documents, Livefully in Vietnam, Vietnam Timeless charm",
};

/*
1 post
2 video
3 album
4 docs
7 draft
8 guides
18 event
*/

//import { createStore } from 'vuex'

/*
const AppConst = createStore({
    modules: {
        BASE_URL: BASE_URL,
        NO_IMAGE_HOLDER: NO_IMAGE_HOLDER,
        CDN_URL_ALLOW: CDN_URL_ALLOW,
        FILE_CDN_URL_ALLOW: FILE_CDN_URL_ALLOW,
        REPLACE_IMG_BASE_URL_ALLOW: REPLACE_IMG_BASE_URL_ALLOW,
        REPLACE_FILE_BASE_URL_ALLOW: REPLACE_FILE_BASE_URL_ALLOW,
        CDN_URL: CDN_URL,
        FILE_URL: FILE_URL,
        IMG_FILTER: IMG_FILTER,
        FILE_FILTER: FILE_FILTER,
        REDIS_ALLOW: REDIS_ALLOW,
        REDIS_EXP: REDIS_EXP,
        CHECK_REMOTE_EXIST: CHECK_REMOTE_EXIST,
    },
});
*/

const AppConst = {
    PAGE_LANG: PAGE_LANG,
    PAGE_LOCALE: PAGE_LOCALE,
    BASE_URL: BASE_URL,
    BASE_ALIAS: BASE_ALIAS,
    NO_IMAGE_HOLDER: NO_IMAGE_HOLDER,
    CDN_URL_ALLOW: CDN_URL_ALLOW,
    FILE_CDN_URL_ALLOW: FILE_CDN_URL_ALLOW,
    REPLACE_IMG_BASE_URL_ALLOW: REPLACE_IMG_BASE_URL_ALLOW,
    REPLACE_FILE_BASE_URL_ALLOW: REPLACE_FILE_BASE_URL_ALLOW,
    CDN_URL: CDN_URL,
    FILE_URL: FILE_URL,
    SECOND_API_URL: SECOND_API_URL,
    IMG_FILTER: IMG_FILTER,
    FILE_FILTER: FILE_FILTER,
    REDIS_ALLOW: REDIS_ALLOW,
    REDIS_EXP: REDIS_EXP,
    CHECK_REMOTE_EXIST: CHECK_REMOTE_EXIST,
    POST_TYPE_LINK: POST_TYPE_LINK,
    CAT_TYPE_LINK: CAT_TYPE_LINK,
    SUBCAT_TYPE_LINK: SUBCAT_TYPE_LINK,
    SITE_META: SITE_META,
}

//export default Object.freeze(AppConst);
export default AppConst;