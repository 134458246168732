<template>
  <div v-if="typeof(postitem) == 'object' && Object.keys(postitem).length>0">
    <!--<pre>{{ JSON.stringify(albumData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    {{updateHits(postitem.id, postitem.type)}}
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <PostBreadCrumbCmp :code="postitem.catcode" :type="postitem.type"></PostBreadCrumbCmp>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content-wrapper post-content-wrapper">
                  <main v-if="postitem.hasTemplate==0" id="content" class="neve-main" role="main">
                      <div class="container single-post-container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="main-content-wrapper">
                              <div class="share-network-list-fixed-wrapper">
                                <div class="share-network-list-fixed-container">
                                  <div id="share-network-list-fixed">
                                    <ShareNetwork
                                                        v-for="network in networks"
                                                        :network="network.network"
                                                        :key="network.network"
                                                        :style="{backgroundColor: network.color}"
                                                        :url="sharing.url"
                                                        :title="sharing.title"
                                                        :description="sharing.description"
                                                        :quote="sharing.quote"
                                                        :hashtags="sharing.hashtags"
                                                        :twitterUser="sharing.twitterUser"
                                                      >
                                                        <i :class="network.icon"></i>
                                                        <span>{{ network.name }}</span>
                                    </ShareNetwork>
                                    <div class="share-network-zalo" data-v-0141c77e="" style="">
                                        <div class="zalo-share-button" :data-href="genPostLink(pageType, postitem.id)" data-oaid="3776018789323949240" data-layout="3" data-color="white" data-customize="true" style=""><img src="/assets/img/ic_zalo.png" style=""/></div>
                                    </div>
                                    <a class="share-network-copy" href="javascript:void(0)" data-v-0141c77e="" style="background-color: rgb(51, 51, 51);">
                                        <i class="fa fa-lg fa-files-o" data-v-0141c77e="" @click="copyUrl" :data-toggle="$t('copy_link_message')" :title="$t('copy_link_message')"></i>
                                        <span data-v-0141c77e=""></span>
                                    </a>
                                    <a class="share-network-print" :href="genPrintLink(pageType, postitem.id)" style="background-color: rgb(51, 51, 51);" target="_blank">
                                        <i class="fa fa-lg fa-print"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <article :id="'post-'+postitem.id" class="post-data type-post">
                                        <div class="nv-content-wrap entry-content">
                                            <div class="post-content-container">
                                              <div class="row">
                                                <div class="col-12">
                                                  <a
                                                      class="section-default-list-album-post-info-title-link"
                                                      :href="genPostLink(pageType, postitem.id)" :title="displayEntity(postitem.title)"
                                                    >
                                                      <h1
                                                        class="section-post-info-title section-default-list-album-post-info-title"
                                                      >
                                                        {{ displayEntity(postitem.title) }}
                                                      </h1>
                                                    </a>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-title-wrapper">
                                                  <div class="post-publish-date"><span v-if="(postitem.publishup).length>0 && typeof(parseInt(postitem.publishup))=='number'" class="post-publish-date">{{formatTimeDetail(postitem.publishup)}}</span></div>
                                                  <!--<div class="post-hits">{{$t('view_label')}}: <span>{{postitem.hits}}</span></div>-->
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-summary-wrapper">
                                                  <div>{{displayHtmlContent(postitem.summary)}}</div>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                  <div class="d-xl-block d-lg-block d-md-block d-sm-none d-xs-none d-none">
                                                    <div id="album-gallery" :style="hasCurrentAlbum?'display:block;':'display:none'">
                                                      <lightgallery v-if="typeof(albumData[postitem.id])=='object' && (albumData[postitem.id]).length>0"
                                                          :settings="{ speed: 500, plugins: plugins, thumbnail: true, licenseKey: '0000-0000-000-0000', container: galleryContainer, closable: false}"
                                                          :onInit="onInit"
                                                          :onBeforeSlide="onBeforeSlide"
                                                          class="inline-gallery-container"
                                                      >
                                                        <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                                                        <template v-for="(pic, picindex) in albumData[postitem.id]"
                                                          :key="picindex"
                                                          >
                                                          <a v-if="(pic.images).length>0" :href="getImages('', pic.images)" class="gallery-item" :data-sub-html="shorten(displayEntity(pic.title),200)">
                                                              <img :src="getImages('', pic.images)" class="gallery-item-img"/>
                                                          </a>
                                                        </template>
                                                        <!-- eslint-enable -->
                                                          
                                                      </lightgallery>  
                                                    </div>
                                                  </div>
                                                  <div class="d-xl-none d-lg-none d-md-none d-sm-block d-xs-block d-block">
                                                    <div id="album-gallery" :style="hasCurrentAlbum?'display:block;':'display:none'">
                                                        <template v-for="(pic, picindex) in albumData[postitem.id]"
                                                          :key="picindex"
                                                          >
                                                          <div v-if="(pic.images).length>0" :class="gallery-item-mobile" :data-sub-html="displayEntity(pic.title)">
                                                              <img :src="getImages('', pic.images)" class="gallery-item-img"/>
                                                              <div class="gallery-item-mobile-title">
                                                                <p>{{ pic.title }}</p>
                                                              </div>
                                                          </div>
                                                        </template>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-content-wrapper">
                                                  <div v-html="displayPostContent(postitem.content)"></div>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-content-source">
                                                    <div class="post-content-source-wrapper"><p>{{postitem.source}}</p></div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-tags-wrapper">
                                                  <div v-if="typeof(postitem.tags) == 'string' && (postitem.tags).length>0" class="post-tags-container">{{$t('tags')}}: <div class="post-tags-list" v-html="displayPostTags(postitem.tags, postitem.tags_view)"></div></div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-share-wrapper">
                                                  <div id="share-network-list" class="share-network-list">
                                                    <ShareNetwork
                                                      v-for="network in networks"
                                                      :network="network.network"
                                                      :key="network.network"
                                                      :style="{backgroundColor: network.color}"
                                                      :url="sharing.url"
                                                      :title="sharing.title"
                                                      :description="sharing.description"
                                                      :quote="sharing.quote"
                                                      :hashtags="sharing.hashtags"
                                                      :twitterUser="sharing.twitterUser"
                                                    >
                                                      <i :class="network.icon"></i>
                                                      <span>{{ network.name }}</span>
                                                    </ShareNetwork>
                                                    <div class="share-network-zalo" data-v-0141c77e="" style="">
                                                        <div class="zalo-share-button" :data-href="genPostLink(pageType, postitem.id)" data-oaid="3776018789323949240" data-layout="3" data-color="white" data-customize="true" style=""><img src="/assets/img/ic_zalo.png" style=""/></div>
                                                    </div>
                                                    <a class="share-network-copy" href="javascript:void(0)" data-v-0141c77e="" style="background-color: rgb(51, 51, 51);">
                                                      <i class="fa fa-lg fa-files-o" data-v-0141c77e="" @click="copyUrl" :data-toggle="$t('copy_link_message')" :title="$t('copy_link_message')"></i>
                                                      <span data-v-0141c77e=""></span>
                                                    </a>
                                                    <a class="share-network-print" :href="genPrintLink(pageType, postitem.id)" style="background-color: rgb(51, 51, 51);" target="_blank">
                                                        <i class="fa fa-lg fa-print"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </article>
                                  </div>
                              </div>
                              </div>  
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <DefaultPostMoreSectionAlbumCmp
                                :parentComponentData="parentComponentData"
                                :postitem="postitem"
                                :pageType="pageType"
                                @content-page-clicked="contentPageChanged"
                              ></DefaultPostMoreSectionAlbumCmp>
                            </div>
                          </div>
                      </div>
                  </main> 
                  <div v-else-if="postitem.hasTemplate==1" class="single-post-container" id="elementor-container">
                  
                  </div>
                  <main v-else-if="postitem.hasTemplate==2" id="content" class="neve-main" role="main">
                      <div class="single-post-container" v-html="displayPostContent(postitem.content)">
                      </div>
                  </main>
            </div>
        </div>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

//import DefaultPostRelatedSectionVideoCmp from "./DefaultPostRelatedSectionVideoCmp";
import DefaultPostMoreSectionAlbumCmp from "./DefaultPostMoreSectionAlbumCmp";

import PostBreadCrumbCmp from "./PostBreadCrumbCmp";

//import { Options, Vue } from 'vue-class-component';
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import lgHash from 'lightgallery/plugins/hash';
//import lightGallery from 'lightgallery';

// If you are using scss you can skip the css imports below and use scss instead
//import styles from 'lightgallery/scss/lightgallery.scss';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-share.css';
//import 'lightgallery/css/lg-hash.css';

import '../../../node_modules/lightgallery/lib/lightgallery.js'
import '../../../node_modules/lightgallery/css/lightgallery.css'

//import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.min.js";
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js'
//import { Tooltip } from bootstrap
//import { Popover } from 'bootstrap'

//import Sticky from 'vue-sticky-directive'

import "jquery-sticky"

/*directives: {Sticky},*/
export default {
  
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      currentVideo: {},
      currentVideoHits:{},
      albumData: {},
      hasCurrentAlbum: false,
      //perPage: parseInt(this.parentComponentData.info.perPage),
      //totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
      sharing: {
        url: this.genPostLink(this.postitem.type, this.postitem.id),
        title: this.postitem.title,
        description: this.removeHTML(this.postitem.summary),
        quote: '',
        hashtags: '',
        twitterUser: ''
      },
      networks: [
        { network: 'facebook', name: '', icon: 'fa fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'twitter', name: '', icon: 'fa fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'email', name: '', icon: 'fa fa-lg fa-envelope', color: '#333333' },
        //{ network: 'viber', name: 'Viber', icon: 'fa fa-lg fa-viber', color: '#59267c' },
        //{ network: 'messenger', name: 'Messenger', icon: 'fa fa-lg fa-facebook-messenger', color: '#0084ff' },
        //{ network: 'telegram', name: 'Telegram', icon: 'fa fa-lg fa-telegram-plane', color: '#0088cc' },
        //{ network: 'pinterest', name: 'Pinterest', icon: 'fa fa-lg fa-pinterest', color: '#bd081c' },
        //{ network: 'sms', name: 'SMS', icon: 'fa fa-lg fa-comment-dots', color: '#333333' },
        //{ network: 'tumblr', name: 'Tumblr', icon: 'fa fa-lg fa-tumblr', color: '#35465c' },
        //{ network: 'email', name: '', icon: 'fa fa-lg fa-envelope', color: '#333333' },
        //{ network: 'fakeblock', name: '', icon: 'fa fa-lg fa-envelope', color: '#333333' },
      ],
      plugins: [lgThumbnail, lgZoom, lgShare, lgHash],
      galleryContainer: document.getElementById('album-gallery'),
      lightGalleryInstance: null,
      copyMess: this.$t('copy_link_message'),
      bsPopover: null,
      options: {
        topSpacing: 20
      }
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  components: {
    //DefaultPostRelatedSectionVideoCmp,
    DefaultPostMoreSectionAlbumCmp,
    Lightgallery,
    PostBreadCrumbCmp
  },
  created(){
    this.doGetAlbum(this.postitem);
  },
  computed: {},
  onMounted() {
    
  },
  mounted() {
    this.galleryContainer = document.getElementById('album-gallery');
    console.log('galleryContainer');
    console.log(this.galleryContainer);
    
    /*
    var siteSticky = function() {
      var bSpacing = $('body').height() - $('.post-content-container').height() - $('#album-gallery').height();
      alert(bSpacing);
      $("#share-network-list-fixed").sticky({topSpacing:100, bottomSpacing:bSpacing});
    };
    siteSticky();
    */
    setTimeout(() => {
      
      var siteSticky = function() {
        //var bSpacing = $('body').height() - ($('.post-content-container').height() + $('#album-gallery').height());
        
        //var link = $('#share-network-list');
        //var position = link.position(); 
        //var bSpacing = $('html').height() + $('#album-gallery').height() - link.height();
        //var bSpacing = $('html').height() + $('#album-gallery').height() - $('.post-content-container').height();
        //var bSpacing = $('html').height() + $('#album-gallery').height() - ($('.main-content-wrapper').offset().top + $('.main-content-wrapper').height());
        //alert($('html').height() + $('#album-gallery').height());

        var bSpacing = $('html').height() - $('.main-content-wrapper').height() - $('#share-network-list-fixed').height();  

        //alert($('#album-gallery').height() + $('#album-gallery').offset().top);
        //alert($('.main-content-wrapper').offset().top + $('.main-content-wrapper').height());
        //alert(bSpacing);

        //alert(bSpacing);
        $("#share-network-list-fixed").sticky({topSpacing:90, bottomSpacing:bSpacing});
        //$("#share-network-list-fixed").sticky({topSpacing:100});
      };
      siteSticky();

    }, 1000)
    

    //this.initTooltips();
  },
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    },
    albumData(){
      if(typeof(this.albumData[this.postitem.id])=='object' && (this.albumData[this.postitem.id]).length>0){
        //this.hasCurrentAlbum=true;
      }
    }
  },
  methods: {
    copyUrl: function(event) {
      //alert(this.$router.currentRoute.value.path);
      //alert(this.$baseUrl);
      //window.clipboardData.setData('Text', this.$baseUrl+this.$router.currentRoute.value.path);
      window.navigator.clipboard.writeText(this.$baseUrl+this.$router.currentRoute.value.path);
      //alert(this.copyMess);
      //this.$notify(this.copyMess);
      //if(typeof(this.bsPopover)!='object'){
        this.bsPopover = new Popover(
          event.target, 
          {
            placement: 'top', 
            trigger: 'click',
            offset: [0, 20],
          },
          )
        this.bsPopover.show()
        //setTimeout(function(this){ console.log(this.bsPopover); }, 2000);
        setTimeout(() => this.bsPopover.hide(), 1000);
      //}
    },
    onInit: (detail) => {
      console.log('Lightgallery Init');
      if(typeof(document.getElementById('album-gallery'))=='object' && document.getElementById('album-gallery')!=null){
        var lightGalleryInstance = detail.instance;
        lightGalleryInstance.refresh();
        lightGalleryInstance.openGallery();
      }

      //this.$nextTick(function () {});
      
      
    },
    onBeforeSlide: () => {
      console.log('calling before slide');
    },
    async doGetAlbum(post){
      this.albumData = {};
      if(typeof(post) == 'object' && Object.prototype.hasOwnProperty.call(post, "id")){
        PublicService.getaAlbumPictures(post.id, {"limit":100,"offset":0}).then(response => {
            if(Object.prototype.hasOwnProperty.call(response, 'data') && response.data.length>0){
              //console.log('doGetAlbum');
              //console.log(response);
              //returnData.push(response);
              this.albumData[post.id] = response.data;
              this.hasCurrentAlbum=true;
            }
        });
      }
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    setCurrentVideo(video){
      this.currentVideo = this.getVideoFromPost(video);
    },
    getVideoFromPost: function (video) {
      return FunctionService.getVideoFromPost(video);
    },
    updateHits(code, type){
      PublicService.updatePostHits(code, {'type':type, 'return':0}).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "hits")) {
                    this.currentVideoHits = response.data.hits;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    getRelatedPost(code, param) {
      PublicService.getaRelatedPost(code, param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.relatedPost = response.data;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    displayPostTags: function (tags, tagsView) {
      return FunctionService.displayPostTags(tags, tagsView);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    formatTimeDetail: function (time, locale) {
      return FunctionService.formatTimeDetail(time, locale);
    },
    getVideo: function (video, opt=0) {
      return FunctionService.getVideo(video, opt);
    },
    genPrintLink: function (type, id) {
      return FunctionService.genPrintLink(type, id);
    },
    initTooltips(){
      $(document).ready(function() {
          $('.share-network').tooltip({
            trigger: 'manual'
          });
      });
    },
  },
};
</script>

<style lang="css" scoped>
    @import 'lightgallery/css/lightgallery.css';
    @import 'lightgallery/css/lg-thumbnail.css';
    @import 'lightgallery/css/lg-zoom.css';
    
    .inline-gallery-container {
      /*width: 700px;*/
      /*height: 500px;*/
      position: relative;
      display: none;
    }

    .lightGallery-captions h4 {
      transform: translate3d(60px, 0, 0px);
    }
    .lightGallery-captions p {
      transform: translate3d(-60px, 0, 0px);
    }
    .lightGallery-captions h4,
    .lightGallery-captions p {
      opacity: 0;
    }

    .lg-current .lightGallery-captions h4,
    .lg-current .lightGallery-captions p {
      transform: translate3d(0, 0, 0px);
    }
    .lg-current .lightGallery-captions h4,
    .lg-current .lightGallery-captions p {
      opacity: 1;
    }

    .lg-slide-progress .lightGallery-captions h4 {
      transform: translate3d(-60px, 0, 0px);
    }
    .lg-slide-progress .lightGallery-captions p {
      transform: translate3d(60px, 0, 0px);
    }
    .lg-slide-progress .lightGallery-captions h4,
    .lg-slide-progress .lightGallery-captions p {
      opacity: 0;
    }

    .lightGallery-captions h4,
    .lightGallery-captions p {
      transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
    }

    .lg-current .lightGallery-captions h4,
    .lg-current .lightGallery-captions p {
      transition-delay: 500ms;
    }
    .lg-current.lg-slide-progress .lightGallery-captions h4,
    .lg-current.lg-slide-progress .lightGallery-captions p {
      transition-delay: 0ms;
    }
    .gallery-item {
      width: 33%;
      padding: 5px;
      display: inline-flex;
    }
    .gallery-item-img {
      width: 100%;
    }
</style>


