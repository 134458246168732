<template>
  <section
    v-if="componentData"
    class="section-wrapper section-default-list-content-wrapper section-detail-content-v2-wrapper"
    :style="[
      componentData.data.bgColor.length > 0
        ? { 'background-color': componentData.data.bgColor }
        : {},
    ]"
  >
    <!--
    <pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(parentComponentDataItem, null, 2) }}</pre>
    -->
    <!--<pre>{{ JSON.stringify(posts, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(parentData, null, 2) }}</pre>-->
    <div
      v-if="typeof parentComponentData == 'object'"
      class="section-detail-content-container-v2"
    >
      <div :style="sectionStyle">
        <div v-if="blockData.length>0 && typeof(blockData[0])=='object'">
          <div class="section-list-top-content">
            <div v-if="blockData[0].images.length>0">
              <img :src="getImages('',blockData[0].images)" class="section-list-top-content-img"/>
            </div>
            <div v-else-if="blockData[0].content.length>0" class="section-list-top-content-html">
              <div v-html="blockData[0].content"></div>
            </div>
          </div>
        </div>
        <div
          v-if="layout == 0"
          class="container section-detail-content-container-v2-1"
        >
          <div class="row">
            <div
              class="container section-detail-content-container-v2-left col"
            >
              <div v-if="pageType == 1">
                <div class="section-detail-content-container-v2-content">
                  <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
                </div>
              </div>
              <div v-else-if="pageType == 2">
                <DefaultPostSectionVideoCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionVideoCmp>
              </div>
              <div v-else-if="pageType == 3">
                <DefaultPostSectionAlbumCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionAlbumCmp>
              </div>
              <div v-else-if="pageType == 4">
                <DefaultPostSectionDocCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionDocCmp>
              </div>
              <div v-else-if="pageType == 5">
                <DefaultPostSectionGuideCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionGuideCmp>
              </div>
              <div v-else-if="pageType == 6"></div>
              <div v-else-if="pageType == 7">Du thao</div>
              <div v-else-if="pageType == 8"></div>
              <div v-else-if="pageType == 9"></div>
              <div v-else-if="pageType == 18">
                <!-- Event -->
                <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
              </div>
              <div v-else-if="pageType == 19"></div>
              <div v-else></div>
            </div>
            <div
              class="container section-detail-content-container-v2-right col"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-detail-content-container-v2-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-detail-content-container-v2-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 1"
          class="container section-detail-content-container-v2-1"
        >
          <div class="row">
            <div
              class="container section-detail-content-container-v2-left col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="pageType == 1">
                <div class="section-detail-content-container-v2-content">
                  <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
                </div>
              </div>
              <div v-else-if="pageType == 2">
                <DefaultPostSectionVideoCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionVideoCmp>
              </div>
              <div v-else-if="pageType == 3">
                <DefaultPostSectionAlbumCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionAlbumCmp>
              </div>
              <div v-else-if="pageType == 4">
                <DefaultPostSectionDocCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionDocCmp>
              </div>
              <div v-else-if="pageType == 5">
                <DefaultPostSectionGuideCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionGuideCmp>
              </div>
              <div v-else-if="pageType == 6"></div>
              <div v-else-if="pageType == 7">Du thao</div>
              <div v-else-if="pageType == 8"></div>
              <div v-else-if="pageType == 9"></div>
              <div v-else-if="pageType == 18">
                <!-- Event -->
                <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
              </div>
              <div v-else-if="pageType == 19"></div>
              <div v-else></div>
            </div>
            <div
              class="container section-detail-content-container-v2-right col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-detail-content-container-v2-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-detail-content-container-v2-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 2"
          class="container section-detail-content-container-v2-2"
        >
          <div class="row">
            <div
              class="container section-detail-content-container-v2-left col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="pageType == 1">
                <div class="section-detail-content-container-v2-content">
                  <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
                </div>
              </div>
              <div v-else-if="pageType == 2">
                <DefaultPostSectionVideoCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionVideoCmp>
              </div>
              <div v-else-if="pageType == 3">
                <DefaultPostSectionAlbumCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionAlbumCmp>
              </div>
              <div v-else-if="pageType == 4">
                <DefaultPostSectionDocCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionDocCmp>
              </div>
              <div v-else-if="pageType == 5">
                <DefaultPostSectionGuideCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionGuideCmp>
              </div>
              <div v-else-if="pageType == 6"></div>
              <div v-else-if="pageType == 7">Du thao</div>
              <div v-else-if="pageType == 8"></div>
              <div v-else-if="pageType == 9"></div>
              <div v-else-if="pageType == 18">
                <!-- Event -->
                <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
              </div>
              <div v-else-if="pageType == 19"></div>
              <div v-else></div>
            </div>
            <div
              class="container section-detail-content-container-v2-right col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-detail-content-container-v2-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-detail-content-container-v2-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 3"
          class="container section-detail-content-container-v2-3"
        >
          <div class="row">
            <div
              class="container section-detail-content-container-v2-left col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="pageType == 1">
                <div class="section-detail-content-container-v2-content">
                  <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
                </div>
              </div>
              <div v-else-if="pageType == 2">
                <DefaultPostSectionVideoCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionVideoCmp>
              </div>
              <div v-else-if="pageType == 3">
                <DefaultPostSectionAlbumCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionAlbumCmp>
              </div>
              <div v-else-if="pageType == 4">
                <DefaultPostSectionDocCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionDocCmp>
              </div>
              <div v-else-if="pageType == 5">
                <DefaultPostSectionGuideCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionGuideCmp>
              </div>
              <div v-else-if="pageType == 6"></div>
              <div v-else-if="pageType == 7">Du thao</div>
              <div v-else-if="pageType == 8"></div>
              <div v-else-if="pageType == 9"></div>
              <div v-else-if="pageType == 18">
                <!-- Event -->
                <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
              </div>
              <div v-else-if="pageType == 19"></div>
              <div v-else></div>
            </div>
            <div
              class="container section-detail-content-container-v2-right col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="isLoading == 0 && content.length > 0" class="section-detail-content-container-v2-right-item-wrapper">
                <div v-for="(block, index) in content" :key="index" class="section-detail-content-container-v2-right-item">
                  <!--<pre>{{ JSON.stringify(block.data, null, 2) }}</pre>-->
                  <component
                    :is="block.data.component"
                    :block="block"
                    :key="block.data.id"
                    :componentData="block.data"
                    :parentComponentData="parentComponentData"
                    :parentComponentDataItem="parentComponentDataItem"
                    @content-page-clicked="contentPageChanged"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 9"
          class="container section-detail-content-container-v2-9"
        >
          <div class="row">
            <div
              class="container section-detail-content-container-v2-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="pageType == 1">
                <div class="section-detail-content-container-v2-content">
                  <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
                </div>
              </div>
              <div v-else-if="pageType == 2">
                <DefaultPostSectionVideoCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionVideoCmp>
              </div>
              <div v-else-if="pageType == 3">
                <DefaultPostSectionAlbumCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionAlbumCmp>
              </div>
              <div v-else-if="pageType == 4">
                <DefaultPostSectionDocCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionDocCmp>
              </div>
              <div v-else-if="pageType == 5">
                <DefaultPostSectionGuideCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionGuideCmp>
              </div>
              <div v-else-if="pageType == 6"></div>
              <div v-else-if="pageType == 7">Du thao</div>
              <div v-else-if="pageType == 8"></div>
              <div v-else-if="pageType == 9"></div>
              <div v-else-if="pageType == 18">
                <!-- Event -->
                <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
              </div>
              <div v-else-if="pageType == 19"></div>
              <div v-else></div>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout == 10"
          class="section-detail-content-container-v2-0"
        >
          <div class="row">
            <div
              class="container section-detail-content-container-v2-left col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            >
              <div v-if="pageType == 1">
                <div class="section-detail-content-container-v2-content">
                  <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
                </div>
              </div>
              <div v-else-if="pageType == 2">
                <DefaultPostSectionVideoCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionVideoCmp>
              </div>
              <div v-else-if="pageType == 3">
                <DefaultPostSectionAlbumCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionAlbumCmp>
              </div>
              <div v-else-if="pageType == 4">
                <DefaultPostSectionDocCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionDocCmp>
              </div>
              <div v-else-if="pageType == 5">
                <DefaultPostSectionGuideCmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                    @search-filter-changed="searchFilterChanged"
                  ></DefaultPostSectionGuideCmp>
              </div>
              <div v-else-if="pageType == 6"></div>
              <div v-else-if="pageType == 7">Du thao</div>
              <div v-else-if="pageType == 8"></div>
              <div v-else-if="pageType == 9"></div>
              <div v-else-if="pageType == 18">
                <!-- Event -->
                <DefaultPostSectionNewsV2Cmp
                    :parentComponentData="parentComponentData"
                    :postitem="parentComponentDataItem"
                    :pageType="pageType"
                    @content-page-clicked="contentPageChanged"
                  ></DefaultPostSectionNewsV2Cmp>
              </div>
              <div v-else-if="pageType == 19"></div>
              <div v-else></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute } from "vue-router";

import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import DefaultPostSectionNewsV2Cmp from "./cmpsItem/DefaultPostSectionNewsV2Cmp";
import DefaultPostSectionAlbumCmp from "./cmpsItem/DefaultPostSectionAlbumCmp";
import DefaultPostSectionVideoCmp from "./cmpsItem/DefaultPostSectionVideoCmp";
import DefaultPostSectionDocCmp from "./cmpsItem/DefaultPostSectionDocCmp";
import DefaultPostSectionGuideCmp from "./cmpsItem/DefaultPostSectionGuideCmp";

import DefaultListSectionSubList1Cmp from "../cmps/DefaultListSectionSubList1Cmp";
import WidgetBlock1 from "../cmps/WidgetBlock1";
import WidgetBlock2 from "../cmps/WidgetBlock2";
import WidgetBlock3 from "../cmps/WidgetBlock3";
import WidgetBlock4 from "../cmps/WidgetBlock4";

import "owl.carousel";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: [
    "loggedIn",
    "componentData",
    "parentComponentData",
    "parentComponentDataItem",
  ],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      id: null,
      title: "",
      type: "",
      code: "",
      posts: [],
      content: [],
      parentData: [],
      blockData: [],
      isLoading: 1,
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      layout: this.componentData.data.layout,
      pageType: this.parentComponentData.type,
      endpoint: "PageConfig/getPageConfig",
      param: {},
    };
  },
  computed: {
    sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          //newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    DefaultPostSectionNewsV2Cmp,
    DefaultPostSectionAlbumCmp,
    DefaultPostSectionVideoCmp,
    DefaultPostSectionDocCmp,
    DefaultPostSectionGuideCmp,
    DefaultListSectionSubList1Cmp,
    WidgetBlock1,
    WidgetBlock2,
    WidgetBlock3,
    WidgetBlock4,
  },
  async created() {
    var vm = this;
    this.getBlockData(this.componentData, vm);
    //this.get();
    //this.getAPIDataByType(this.componentData);
    this.param = {'offset': this.currentPage, 'callType':1};
    await this.getPageDataFromApi(
      this.cRoute,
      this.cCode,
      this.endpoint,
      this.param
    );
  },
  methods: {
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        
        resp.then(function (data) {
          /*
          console.log('getBlockData');
          console.log(data);
          */
          //alert(data);
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
          });
        });
        //console.log(this.blockData);
      }
    },
    contentPageChanged(page) {
      this.$emit("content-page-clicked", page);
    },
    searchFilterChanged(param){
      this.$emit("search-filter-changed", param);
    },
    getPageDataFromApi(type, code, endpoint, param) {
      //param['callType'] = 1;
      param = { type: type, position: "contentRight" };
      PublicService.getPublicPageConfig(code, endpoint, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data")) {
            this.content = response.data;
            this.isLoading = 0;
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getAPIDataByType(config) {
      switch (config.data.dataType) {
        case "":
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            ).then(
              (response) => {
                //console.log(response);
                if (
                  Object.prototype.hasOwnProperty.call(response, "data") &&
                  Object.prototype.hasOwnProperty.call(
                    response.data,
                    "child"
                  ) &&
                  Object.prototype.hasOwnProperty.call(response.data, "parent")
                ) {
                  //console.log("posts: ");
                  //console.log(response.data.child);
                  this.posts = response.data.child;
                  this.parentData = response.data.parent;

                  this.$nextTick(function () {
                    this.initOwl();
                  });
                }
              },
              (error) => {
                this.content =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          break;
        case 0:
          if (config.data.dataList.length > 0) {
            PublicService.getBlockPublicData(
              config.data.dataList[0].dataAPI,
              config.data.dataList[0]
            );
          }
          break;
        default:
      }
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length > 0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {},
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
    get() {
      const route = useRoute();
      const id = route.params.id;
      PublicService.getCatPublicContent(id).then(
        (response) => {
          //console.log("DefaultPostSectionCmp");
          //console.log(response);
          //this.content = response.data;
          this.posts = response.data.child;
          this.cat = response.data.parent;
          //console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style></style>
