<template>
  <div v-if="typeof postitem == 'object'">
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <PostBreadCrumbCmp :code="parentComponentData.code" :type="parentComponentData.type"></PostBreadCrumbCmp>
        </div>
      </div>
    </div>
    <div class="section-default-list-guide-search-wrapper">
      <form>
      <div class="section-default-list-guide-search-container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h1 class="section-default-list-guide-search-section-title">Tra cứu Thủ tục hành chính</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <select v-model="department" name="department_guides" id="department_guides" class="form-control search-filter-control">
              <option value="">Chọn Cơ quan thực hiện</option>
              <option v-for="(item, index) in department_guides" :value="index" :key="index">{{item}}</option>
            </select>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <select v-model="fieldcode" name="fieldcode_guides" id="fieldcode_guides" class="form-control search-filter-control">
              <option value="">Chọn Lĩnh vực</option>
              <option v-for="(item, index) in fieldcode_guides" :value="index" :key="index">{{item}}</option>
            </select>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <input v-model="title" type="text" name="title_guides" id="title_guides" class="form-control search-filter-control" :placeholder="$t('search_keyword_input')"/>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
            <button class="btn search-guide-btn">Tra cứu</button>
          </div>
        </div>
      </div>
      </form>
    </div>
    <div>
      <div class="row">
        <template v-for="(post, index) in postitem" :key="index">
          <div
            class="section-default-list-guide-item col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-guide-item-wrapper">
              <div class="section-default-list-guide-item-container">
                <div class="section-default-list-guide-item-title">
                  <a
                    class="section-default-list-guide-post-info-title-link"
                    :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                  >
                    <h1 class="section-post-info-title section-default-list-guide-post-info-title">
                      <i class="fa section-default-list-guide-post-info-icon section-default-list-guide-post-info-title-icon"></i> {{ displayEntity(post.title) }}
                    </h1>
                  </a>
                </div>
              </div>
              <div class="section-default-list-guide-post-info-wrapper">
                <div class="section-default-list-guide-post-info-container">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                      <div v-if="(post.summary).length>0" class="section-default-list-guide-post-info-summary">
                        {{displayEntity(displayHtmlContent(post.summary))}}
                      </div>
                      <div class="section-default-list-guide-post-info-button row">
                        <div class="section-default-list-guide-post-info-button-item col"><i class="fa section-default-list-guide-post-info-icon section-default-list-guide-post-info-button-item-field"></i> Lĩnh vực: {{fieldcode_guides[post.fieldcode]}}</div>
                        <div class="section-default-list-guide-post-info-button-item col"><i class="fa section-default-list-guide-post-info-icon section-default-list-guide-post-info-button-item-dept"></i> Cơ quan thực hiện: {{department_guides[post.department]}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import PostBreadCrumbCmp from "./PostBreadCrumbV2Cmp";

export default {
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      department: typeof(this.$route.query.department_guides)=='string' && (this.$route.query.department_guides).length>0?this.$route.query.department_guides:"",
      department_guides: [],
      fieldcode: typeof(this.$route.query.fieldcode_guides)=='string' && (this.$route.query.fieldcode_guides).length>0?this.$route.query.fieldcode_guides:"",
      fieldcode_guides: [],
      title: this.$route.query.title_guides,
      filterParam: {},
      perPage: parseInt(this.parentComponentData.info.perPage),
      totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  components: {
    PostBreadCrumbCmp
  },
  created(){
    this.fillFilter();
  },
  computed: {
  },
  onMounted() {},
  mounted() {},
  watch:{
    department(){
      this.filterParam = this.handleSearchChange();
    },
    fieldcode(){
      this.filterParam = this.handleSearchChange();
    },
    title(){
      this.filterParam = this.handleSearchChange();
    },
    filterParam(){
      this.emitFilterChange(this.filterParam)
    },
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    }
  },
  methods: {
    async fillFilter(){
      PublicService.getGuideFilter().then(response => {
        if(Object.prototype.hasOwnProperty.call(response, 'data')){
          //console.log(response);
          var filters = response.data;
          if(Object.keys(filters).length>0){
            Object.keys(filters).forEach(key => {
              //console.log(key, filters[key]);
              if(filters[key].length){
                var filterItem = {};
                Object.keys(filters[key]).forEach(fkey => {
                  //console.log(fkey, filters[key][fkey]);
                  filterItem[filters[key][fkey]['code']] = filters[key][fkey]['title'];
                });
                //console.log(filterItem);
                this.changeVal(key, filterItem);
              }
            });
          }
        }
      })
    },
    handleSearchChange(){
      var param = {};
      var searchFilter = document.getElementsByClassName('search-filter-control');
      if(typeof(searchFilter)=='object' && searchFilter.length>0){
        Array.prototype.forEach.call(searchFilter, function(el) {
            /*console.log(el.id);*/
            param[el.id] = el.value;
        });
      }
      //console.log(param);
      return param;
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      console.log(paramStr);
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    emitFilterChange(param){
      console.log(param);
      var fParam = FunctionService.buildParam(param);
      this.$router.push("?"+fParam);
      this.$emit('search-filter-changed', param);
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    genPostDownloadLink: function (type, id, endpoint) {
      return FunctionService.genPostDownloadLink(type, id, endpoint);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    changeVal(varName, newValue) {
        this[varName] = newValue;
    },
  },
};
</script>

<style></style>
