<template>
  <div class="section-printer-content-container">
    <!--
    <pre>{{ JSON.stringify(content, null, 2) }}</pre>
    <pre>{{this.$route.name}}</pre>
    <pre>{{cRoute}}</pre>
    {{cCode}}
    -->
    <!--
    <pre>{{ JSON.stringify(this.cat, null, 2) }}</pre>
    <pre>{{ JSON.stringify(this.post, null, 2) }}</pre>
    -->
    <div v-if="isLoading == 0">
      <MetaCmp
      :postdata="this.post"
      ></MetaCmp>
      <div class="printer-page-banner">
        <div :class="typeof(lang) == 'string'?'printer-page-banner-img printer-page-banner-img-'+lang:'printer-page-banner-img'"></div>
      </div>
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-12 col-sm-12" id="post-printer-wrapper">
              <div id="post-printer-container" class="post-printer-container">
                <a class="printer-cat-link" :href="genCatLink(this.cat.type,this.cat.code)">
                  <h3 class="printer-cat-title">{{displayEntity(this.cat.title)}}</h3>
                </a>
                <button class="print-page not-print">
                  <i class="fa fa-lg fa-print" @click="printPage()" :title="$t('print_button_label')">&nbsp;{{$t('print_button_label')}}</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <DefaultPrinterSectionCmp
          :parentComponentData="this.cat"
          :parentComponentDataItem="this.post"
          @content-page-clicked="contentPageChanged"
          @search-filter-changed="searchFilterChanged"
        ></DefaultPrinterSectionCmp>
      </div>
    </div>
  </div>
</template>

<script>

import { useRoute } from 'vue-router'
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import $ from "jquery";

//import MenuTopCmp from "../cmps/MenuTopCmp";
//import MenuTopCmpV2 from "../cmps/MenuTopCmpV2";
import MetaCmp from "../cmps/cmpsItem/MetaCmp";
import DefaultPrinterSectionCmp from "../cmps/DefaultPrinterSectionCmp";

export default {
  /*
  props: {
        definitions: Array
  },
  */
  setup() {

  },
  data() {
    return {
      //bannerImage: this.$AppConst.NO_IMAGE_HOLDER,
      //post: {title:'',summary:'',content:''},
      post: {},
      postTemplateData: {},
      bannerImage: '',
      cat_id: null,
      cat_index: null,
      posts: [],
      title: "",
      image: "",
      imgPlaceholder: "http://via.placeholder.com/300",
      cat: [],
      isLoading: 1,
      isLoaded: 0,
      content: {},
      cRoute: useRoute().name,
      cCode: this.$route.params.id,
      hasTemplate: 0,
      //perPage: 10,
      //totalItem: 100,
      currentPage: isNaN(this.$route.query.page)?1:this.$route.query.page,
      callType: 1,
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang'),
    };
  },
  components: {
    //MenuTopCmp,
    //MenuTopCmpV2,
    DefaultPrinterSectionCmp,
    MetaCmp
  },
  computed: {
      checkImg(){
        return this.images != '';
      },
      strippedHtml() {
          let regex = /(<([^>]+)>)/ig;
          return this.summary.replace(regex, "");
      }
    },
  async created() {
    this.get();
  },
  mounted() {
    
  },
  methods: { 
    printPage(){
      window.print();
    },
    getPageDataFromApi(type, code, param){
      //var param = {};
      //param['callType'] = 1;
      //alert(param.offset);
      PublicService.getPublicPageData(code, 1, param).then(
        (response) => {
          //console.log(response);
          if (Object.prototype.hasOwnProperty.call(response, "data") && Object.prototype.hasOwnProperty.call(response.data, "child")) {
            //this.posts = response.data.child;
            //this.cat = response.data.parent;
            //this.totalItem = typeof(response.data.parent.info) == "object" && typeof(response.data.parent.info.total) == "string"?response.data.parent.info.total:0;
            param = {'type':type, 'position':'home'};
            PublicService.getPublicPageConfig(code, 'PageConfig/getPageConfig',param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.content = response.data;
                    this.isLoading = 0;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
            /*
            if(typeof(response.data.parent)=='object' && typeof(response.data.parent.hasTemplate)=='string' && response.data.parent.hasTemplate==1){ // Có giao diện
              this.hasTemplate = 1;
            }
            else{ // Không có giao diện, dùng giao diện mắc định
              this.isLoading = 0;
              this.hasTemplate = 0;
            }
            */
            this.isLoading = 0;
            this.hasTemplate = 0;
            
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function(number){
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function(type){
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    occurrences: function(string, subString, allowOverlapping){
      return FunctionService.occurrences(string, subString, allowOverlapping);
    },
    logData(data){
      console.log(data);
    }, 
    get() {
      const route = useRoute()
      const id = route.params.id;
      PublicService.getPostPublicContent(id).then(
        (response) => {
          this.post = response.data;
          //this.content = response.data;
          //this.hasTemplate = this.post.hasTemplate;
          this.bannerImage = this.getImages(response.data.excerpt+response.data.content, response.data.images);
          this.hasTemplate = response.data.hasTemplate;
          //console.log('this.hasTemplate: ' + this.hasTemplate);
            //this.post = response.data;

            this.postTemplateData = response.data;
            this.bannerImage = this.getImages(this.post.summary+this.post.content, this.post.images);

            if (Object.prototype.hasOwnProperty.call(response.data, "catcode")) {
              var catParam = {'callType':10};
              PublicService.getCatPublicContent(response.data.catcode, 1, catParam).then(
                (responseCat) => {
                  //console.log('this.cat:');
                  //console.log(responseCat);
                  if (Object.prototype.hasOwnProperty.call(responseCat, "data")) {
                    this.cat = responseCat.data.parent;
                    //console.log(this.cat);
                    var param = this.$router.currentRoute.value.query;
                    if(Object.keys(param).length>0){
                      param.offset = this.currentPage;
                      param.callType = this.callType;
                    }
                    else{
                      param = {'offset': this.currentPage, 'callType': this.callType};
                    }
                    this.getPageDataFromApi(this.cRoute, this.cCode, param);

                  }
                },
                (error) => {
                    this.content =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
            }

        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    genCatLink: function (type, id) {
      return FunctionService.genCatLink(type, id);
    },
  },
};
</script>

<style>
</style>