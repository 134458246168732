<!--
<template>
  <div class="fluid-container"> 
    <BannerSection></BannerSection>
    <HomeSection1></HomeSection1>
    <HomeSection2></HomeSection2>
    <HomeSection3></HomeSection3>
    <HomeSection4></HomeSection4>
    <HomeSection5></HomeSection5>
    <HomeSection6></HomeSection6>
    <HomeSection7></HomeSection7>
    <HomeSection8></HomeSection8>
  </div>
</template>
-->
<template>
  <div :class="'page-wrapper-'+lang">
    <!--<pre>{{ JSON.stringify(content, null, 2) }}</pre>-->
    <div v-if="isLoading==0 && content.length>0">
      <div v-for="(block, index) in content" :key="index">
        <component :is="block.data.component" :block="block" :key="block.data.id" :componentData="block.data"></component>
      </div>
    </div>
    <!--
    <div v-for="(block, index) in content.body" :key="index">
      <component :is="block.component" :block="block" :key="block.id" :componentData="block.data"></component>
    </div>
    -->
  </div>
</template>

<script>
//import { useRoute } from "vue-router";
import 'owl.carousel';
//import Vue from 'vue'
import PublicService from "../services/public.service";

//import { defineAsyncComponent } from 'vue'
//import UserService from "../services/user.service";
//import PublicService from "../services/public.service";
//import PostItem from "../cmps/NewsItemCmp";

//window.$ = window.jQuery = require('jquery');
import jQuery from "jquery";

const $ = jQuery;
window.$ = $;

//import owlCarousel from 'owl.carousel' 


import MenuTopCmp from "../cmps/MenuTopCmp";
import MenuTopCmpV2 from "../cmps/MenuTopCmpV2";
import MainMenuCmp from "../cmps/MainMenuCmp";
import MainMenuCmpV2 from "../cmps/MainMenuCmpV2";
import BannerSectionCmp from "../cmps/BannerSectionCmp";
import BannerSectionCmpV2 from "../cmps/BannerSectionCmpV2";
import HomeSection1Cmp from "../cmps/HomeSection1Cmp";
import HomeSection2Cmp from "../cmps/HomeSection2Cmp";
import HomeSection3Cmp from "../cmps/HomeSection3Cmp";
import HomeSection4Cmp from "../cmps/HomeSection4Cmp";
import HomeSection5Cmp from "../cmps/HomeSection5Cmp";
import HomeSection6Cmp from "../cmps/HomeSection6Cmp";
import HomeSection7Cmp from "../cmps/HomeSection7Cmp";
import HomeSection7V2Cmp from "../cmps/HomeSection7V2Cmp";
import HomeSection8Cmp from "../cmps/HomeSection8Cmp";
import HomeSection9Cmp from "../cmps/HomeSection9Cmp";
import MainFooterCmp from "../cmps/MainFooterCmp";
import MainFooterCmpV2 from "../cmps/MainFooterCmpV2";
import HitCounterCmp from "../cmps/HitCounterCmp";
import ImageConnectCmp from "../cmps/ImageConnectCmp";

import HomeSection10Cmp from "../cmps/HomeSection10Cmp";

import HomeGreetingCmp from "../cmps/HomeGreetingCmp";


//import { defineComponent, computed, reactive, ref, getCurrentInstance } from 'vue'
import { defineComponent, computed, reactive, getCurrentInstance } from 'vue'
//import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head';

/*
export default defineComponent({
  setup() {
    const siteData = reactive({
      title: `My website vietnamtourism.gov.vn`,
      description: `My beautiful website`,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    })
  },
})
*/

export default defineComponent({
  //name: "HomeCmp",
  setup() {

    //const route = useRoute();

    //const AppConst = ref(AppConst)
    //const AppConst = ref('')
    const app= getCurrentInstance()
    const lang = localStorage.getItem('lang');
    const siteMeta = app.appContext.config.globalProperties.siteMeta
    const gPro = app.appContext.config.globalProperties;
    //console.log(app.appContext.config.globalProperties.siteTitle);
    const siteData = reactive({
      title: lang!='' && lang!='vi'?siteMeta['title_'+lang]:siteMeta.title,
      description: lang!='' && lang!='vi'?siteMeta['desc_'+lang]:siteMeta.desc,
      appName: siteMeta.appName,
      image: siteMeta.image,
      author: siteMeta.author,
      icon: siteMeta.icon,
      url: (lang!='' && lang!='vi'?gPro.$baseUrl+'/'+lang:gPro.$baseUrl),
      keywords: lang!='' && lang!='vi'?siteMeta['keywords_'+lang]:siteMeta.keywords,
      robots: siteMeta.robots,
      country: siteMeta.country,
      revisitAfter: siteMeta.revisitAfter,
      documentRating: siteMeta.documentRating,
      documentDistribution: siteMeta.documentDistribution,
      distribution: siteMeta.distribution,
      area: siteMeta.area,
      placename: siteMeta.placename,
      resourceType: siteMeta.resourceType,
      owner: siteMeta.owner,
      classification: siteMeta.classification,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        { name: 'copyright', content: siteMeta.copyright },
        { name: 'application-name', content: siteData.appName },
        { name: 'email', content: siteMeta.email },
        { name: 'section', itemprop: 'articleSection', content: "Tin tức - Sự kiện" },
        { name: 'keywords', content: computed(() => siteData.keywords) },
        { name: 'robots', content: siteData.robots },
        { name: 'country', content: siteData.country },
        { name: 'revisit-after', content: siteData.revisitAfter },
        { name: 'document-rating', content: siteData.documentRating },
        { name: 'document-distribution', content: siteData.documentDistribution },
        { name: 'distribution', content: siteData.distribution },
        { name: 'area', content: siteData.distribution },
        { name: 'placename', content: siteData.placename },
        { name: 'resource-type', content: siteData.resourceType },
        { name: 'owner', content: siteData.owner },
        { name: 'classification', content: siteData.classification },
        //{ name: 'description', content: 'A description of the page', id: 'desc' }, // id to replace intead of create element
        // Twitter
        { name: 'twitter:title', content: computed(() => siteData.title) },
        { name: 'twitter:description', content: computed(() => siteData.description)},
        { name: 'twitter:card', content: siteMeta.twitterCard},
        { name: 'twitter:site', content: computed(() => siteData.url)},
        { name: 'twitter:creator', content: computed(() => siteData.author)},
        // Google+ / Schema.org
        { itemprop: 'name', content: computed(() => siteData.title) },
        { itemprop: 'description', content: siteMeta.copyright },
        // Facebook / Open Graph
        //{ property: 'fb:app_id', content: '123456789' },
        { property: 'og:title', content: computed(() => siteData.title) },
        { property: 'og:site_name', content: computed(() => siteData.title) },
        { property: 'og:type', content: "article" },
        { property: 'og:image', content: siteMeta.image },
        { property: 'og:image:url', content: siteMeta.image },
        { property: 'og:url', content: computed(() => siteData.url) },
        { property: 'og:description', content: computed(() => siteData.description) },
        //<meta property="article:published_time" content="2021-12-27T09:40:54+00:00">
        //<meta property="article:modified_time" content="2022-04-15T12:46:19+00:00">
      ],
      // link tags
      link: [
        { rel: 'canonical', href: computed(() => siteData.url), id: 'canonical' },
        { rel: 'author', href: siteMeta.author, undo: false }, // undo property - not to remove the element
        { rel: 'icon', href: siteMeta.icon, sizes: '32x32', type: 'image/png' }, 
        // with shorthand
        //{ r: 'icon', h: 'path/to/icon-32.png', sz: '32x32', t: 'image/png' },
      ],
      /*
      script: [
        { type: 'text/javascript', src: 'cdn/to/script.js', async: true, body: true}, // Insert in body
        // with shorthand
        { t: 'application/ld+json', i: '{ "@context": "http://schema.org" }' },
        // ...
      ],
      style: [
        { type: 'text/css', inner: 'body { background-color: #000; color: #fff}', undo: false },
        // ...
      ]
      */
    })

    return{
      lang
    }
  },
  components: {
    /*
    "BannerSectionCmp" : defineAsyncComponent(() => import('../cmps/BannerSectionCmp')),
    "HomeSection1Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection1Cmp')),
    "HomeSection2Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection2Cmp')),
    "HomeSection3Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection3Cmp')),
    "HomeSection4Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection4Cmp')),
    "HomeSection5Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection5Cmp')),
    "HomeSection6Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection6Cmp')),
    "HomeSection7Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection7Cmp')),
    "HomeSection8Cmp" : defineAsyncComponent(() => import('../cmps/HomeSection8Cmp')),
    */
    
    //PostItem,

    MenuTopCmp,
    MenuTopCmpV2,
    MainMenuCmp,
    MainMenuCmpV2,
    BannerSectionCmp,
    BannerSectionCmpV2,
    HomeSection1Cmp,
    HomeSection2Cmp,
    HomeSection3Cmp,
    HomeSection4Cmp,
    HomeSection5Cmp,
    HomeSection6Cmp,
    HomeSection7Cmp,
    HomeSection7V2Cmp,
    HomeSection8Cmp,
    HomeSection9Cmp,
    MainFooterCmp,
    MainFooterCmpV2,
    HitCounterCmp,
    ImageConnectCmp,
    HomeSection10Cmp,
    HomeGreetingCmp,
    
  },
  data() {
    return {
      isLoading: 1,
      isLoaded: 0,
      content: {},      
    }
  },
  computed: {
    
  },
  async created() {
    //var vm = this;
    //await this.getDataFromApi(Vue, vm);
    await this.getDataFromApi();
  },
  methods: {
    /*
    getcomponent (x) {
      return () => import(`../cmps/${x}`)
      //return defineAsyncComponent(() => import(`../cmps/${x}`))
    }
    */
    //getDataFromApi(vm){
    getDataFromApi(){
     PublicService.getPublicHomeConfig()
     .then(
        (response) => {
          if(Object.prototype.hasOwnProperty.call(response, 'data')){
            this.content = response.data;
            this.isLoading = 0;

            
            //Vue.nextTick(function(){
            this.$nextTick(function(){
              //vm.initOwl();
              //Vue.initOwl();
              this.initOwl();
            });
            //}.bind(vm));
            

            
            

          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      )
      /*
      .then(() => {
              console.log(this); // Vue instance
              console.log(vm);
            
                this.initOwl();
            
            })
      */
   },
   
   initOwl(){
     //console.log(owlCarousel);
     var owl = jQuery(".owl-list");
     if(owl.length>0){
       owl.owlCarousel({
         dots: true,
         items: 1,
         loop: true,
         //autoplay: true,
         //autoplayTimeout: 5000,
         //autoplayHoverPause: true,
         video: true,
         videoWidth: false, // Default false; Type: Boolean/Number
         videoHeight: false, // Default false; Type: Boolean/Number
         nav: true,
         navText: ["<button type='button' class='btn btn-info btn-sm'>Previos</button>", "<button type='button' class='btn btn-info btn-sm'>Next</button>"],
         animateOut: 'fadeOut',
         animateIn: 'fadeIn',
         });
         owl.on('changed.owl.carousel', function(event) {
                    var item = event.item.index - 2; // Position of the current item
                    jQuery('h1').removeClass('animated fadeInUp');
                    jQuery('.owl-item').not('.cloned').eq(item).find('h1').addClass('animated fadeInUp');
                   
                    //console.log(event);
                    //console.log(item);
                    var current = event.item.index;
                    console.log(jQuery(event.target).find(".owl-item").eq(current).find(".video-item").length);
                    if (jQuery(event.target).find(".owl-item").eq(current).find(".video-item").length > 0) {
                        if (jQuery('.playing-video').length > 0) {
                            jQuery('.playing-video').each(function(index) {
                                var src = jQuery(this).attr('src');
                                src = src.replace('&autoplay=1', '', src);
                                //console.log(src);
                                console.log(index);
                                jQuery(this).attr('src', src);
                            });
                        }

                        var currentIframe = jQuery(event.target).find(".owl-item").eq(current).find("iframe");
                        console.log(currentIframe);
                        if (currentIframe.length > 0) {
                            var src = currentIframe.attr('src');
                            console.log(src);
                            currentIframe.attr('src', src + '&autoplay=1');
                            currentIframe.addClass('playing-video');
                        }
                    } else {
                        

                        if (jQuery('.playing-video').length > 0) {
                            jQuery('.playing-video').each(function(index) {
                                var src = jQuery(this).attr('src');
                                src = src.replace('&autoplay=1', '', src);
                                //console.log(src);
                                console.log(index);
                                jQuery(this).attr('src', src);
                            });
                        }
                    }

                    

                });
      }
   }
   
  },
  mounted() {
    this.initOwl(); 
    /*
    const ol = this.$refs.olCarousel
    if(ol) {
      ol.owlCarousel();
    }
    */
  },
  updated(){
    //this.initOwl();
  },
  watch: {
   
  }
});
</script>
