<template>
  <section class="section-wrapper section-9-wrapper">
    <!--
      <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
    <pre>{{ JSON.stringify(posts, null, 2) }}</pre>
    -->
    <div class="section-9-container">
        <div>
            <section id="section-9-banner" :class="((componentData.data.height).length>0?' section-9-banner-'+componentData.data.height:'')">
                <div v-if="(typeof(blockData[0])=='object')" id="section-9-slider" class="section-9-slider owl-theme owl-carousel" :style="sectionStyle">
                    <BannerItem v-for="(post, index) in blockData[0]"
              :postitem="post"
              :key="index"></BannerItem>

              </div>
            </section>
        </div>
    </div>
  </section>
</template>

<script>
import "owl.carousel";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

import BannerItem from "./BannerItem1Cmp";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      posts: [],
      parentData: [],
      blockData: [],
    };
  },
  computed: {

  },
  components: {
    BannerItem
  },
  mounted() {
      this.initOwl();
  },
  methods: {
      getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
            /*
            console.log(cfgData.data.dataList[dtCt]);
            if(typeof(cfgData.data.dataList[dtCt])=='object' && typeof(cfgData.data.dataList[dtCt].dataHeadLine)=='string'){
                vm.blockTitle.push(
                cfgData.data.dataList[dtCt].dataHeadLine.length > 0
                    ? cfgData.data.dataList[dtCt].dataHeadLine
                    : ""
                );
            }
            dtCt++;
            */

           vm.$nextTick(function () {
               vm.initOwl();
            });

          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length>0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {
      //console.log(owlCarousel);
      var owl = $("#section-9-slider");
      if (owl.length > 0) {
        owl.owlCarousel({
          dots: true,
          items: 1,
          loop: true,
          //autoplay: true,
          //autoplayTimeout: 5000,
          //autoplayHoverPause: true,
          video: true,
          videoWidth: false, // Default false; Type: Boolean/Number
          videoHeight: false, // Default false; Type: Boolean/Number
          nav: true,
          navText: [
            "<button type='button' class='btn btn-info btn-sm'>Previos</button>",
            "<button type='button' class='btn btn-info btn-sm'>Next</button>",
          ],
          animateOut: "fadeOut",
          animateIn: "fadeIn",
        });
        owl.on("changed.owl.carousel", function (event) {
          var item = event.item.index - 2; // Position of the current item
          $("h1").removeClass("animated fadeInUp");
          $(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h1")
            .addClass("animated fadeInUp");

          //console.log(event);
          //console.log(item);
          var current = event.item.index;
          /*
          console.log(
            $(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length
          );
          */
          if (
            $(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length > 0
          ) {
            if ($(".playing-video").length > 0) {
              $(".playing-video").each(function () {
                var src = $(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                $(this).attr("src", src);
              });
            }

            var currentIframe = $(event.target)
              .find(".owl-item")
              .eq(current)
              .find("iframe");
            //console.log(currentIframe);
            if (currentIframe.length > 0) {
              var src = currentIframe.attr("src");
              console.log(src);
              currentIframe.attr("src", src + "&autoplay=1");
              currentIframe.addClass("playing-video");
            }
          } else {
            if ($(".playing-video").length > 0) {
              $(".playing-video").each(function () {
                var src = $(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                $(this).attr("src", src);
              });
            }
          }
        });
      }
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
  },
  created() {
    //this.get();
    //this.getAPIDataByType(this.componentData);
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>