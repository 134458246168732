<template>
  <div class="col-md-12">
    <div class="login-container card-container">
      <div class="row">
        <div class="col-md-5 d-md-block d-sm-none d-none">
          <img class="login-side-img" src="/assets/img/login_side.jpg"/>
        </div>
        <div class="col-md-7 col-sm-12 col-12">
          <div class="login-panel">
            <div class="login-panel-inner">
              <img
                id="profile-img"
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                class="profile-img-card"
              />
              <Form @submit="handleRegister" :validation-schema="schema" autocomplete="off">
                <div v-if="!successful">
                  <div class="form-group">
                    <label for="username">{{$t('register_username')}}</label>
                    <Field name="username" type="text" class="form-control" autocomplete="off"/>
                    <ErrorMessage name="username" class="error-feedback" />
                  </div>
                  <div class="form-group">
                    <label for="email">{{$t('register_email')}}</label>
                    <Field name="email" type="email" class="form-control" autocomplete="off"/>
                    <ErrorMessage name="email" class="error-feedback" />
                  </div>
                  <div class="form-group">
                    <label for="password">{{$t('register_password')}}</label>
                    <Field name="password" type="password" class="form-control" autocomplete="off"/>
                    <ErrorMessage name="password" class="error-feedback" />
                  </div>

                  <div class="form-group">
                    <button class="btn btn-primary btn-block" :disabled="loading">
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span>
                      {{$t('register_signup')}}
                    </button>
                    <p class="or-signup"><span style="margin-right: 15px;">{{$t('or_signup_text')}}</span><a class="register-login-link" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')+'/login'">{{$t('register_login')}}</a></p>
                  </div>
                </div>
              </Form>
              <div class="form-group">
                <a class="login-back-to-home" :href="baseUrl+(typeof(lang) == 'string' && lang.length==2 && lang != this.AppConst.PAGE_LANG?'/'+lang:'')">{{$t('login_back_to_home')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

//var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
//var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

export default {
  name: "RegisterCmp",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const { t } = this.$root.$i18n
    /* eslint-disable */
    const schema = yup.object().shape({
      username: yup
        .string()
        .required(t('register_username_required'))
        .min(6, t('register_username_min'))
        .max(20, t('register_username_max')),
      email: yup
        .string()
        .required(t('register_email_required'))
        .email(t('register_email_invalid'))
        .max(70, t('register_email_max')),
      password: yup
        .string()
        .required(t('register_password_required'))
        .min(6, t('register_password_min'))
        .max(20, t('register_password_max'))
        //.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Your page URL can only contain letters, numbers and dashes.'),
        //.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/g, 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)'),
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/, t('register_password_strong')),
    });


    let baseUrl = ref('')
    const app= getCurrentInstance()
    const gPro = app.appContext.config.globalProperties;
    baseUrl = gPro.$baseUrl;

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      baseUrl,
      AppConst: this.$AppConst,
      lang: localStorage.getItem('lang'),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
