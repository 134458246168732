export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "search_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探す"])},
  "month_hit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月の訪問者"])},
  "year_hit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間訪問者"])},
  "search_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワード"])},
  "search_datatype_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データ・タイプ"])},
  "search_datatype1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュース"])},
  "search_datatype2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオ"])},
  "search_datatype3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルバム"])},
  "search_datatype4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書類"])},
  "search_datatype5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイド"])},
  "search_datatype6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
  "search_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])},
  "search_chose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した"])},
  "search_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果"])},
  "search_result_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果"])},
  "search_result_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
  "search_result_units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
  "search_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探す"])},
  "search_keyword_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索キーワードを入力してください"])},
  "search_no_result_search_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探しているものが見つかりませんでしたか? ここで別の検索結果をお試しください"])},
  "search_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントを検索"])},
  "search_docs_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探す"])},
  "select_docs_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントグループを選択"])},
  "select_docs_dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発行機関を選択してください"])},
  "select_docs_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開エリアを選択"])},
  "select_docs_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントタイプを選択"])},
  "select_docs_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発行年を選択"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ"])},
  "post_for_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグの投稿"])},
  "update_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップデート"])},
  "view_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビュー"])},
  "related_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連している"])},
  "other_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと"])},
  "other_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "other_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "other_album_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他のアルバム"])},
  "other_video_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他のビデオ"])},
  "first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初め"])},
  "last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後"])},
  "next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次"])},
  "prev_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前"])},
  "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アタッチメント"])},
  "doc_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文書番号"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
  "date_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発行日"])},
  "date_effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発効日"])},
  "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きを見る"])},
  "copy_link_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクがコピーされました"])},
  "doc_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントの名前"])},
  "doc_dept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理店発行"])},
  "doc_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付発行"])},
  "doc_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発行フィールド"])},
  "doc_publishup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発効日"])},
  "doc_publishdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
  "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントタイプ"])},
  "doc_sovb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["番号"])},
  "doc_sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歌手"])},
  "doc_publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキスト効果"])},
  "doc_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストをダウンロード"])},
  "doc_general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般情報"])},
  "doc_content_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まとめ内容"])},
  "doc_relate_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連資料"])},
  "breadcrumb_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームページ"])},
  "online_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンライン"])},
  "statistic_cat_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計学"])},
  "statistic_lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データを検索する"])},
  "statistic_year_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五"])},
  "statistic_period_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限目"])},
  "subscribe_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the travel newsletter"])},
  "subscribe_tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to receive comprehensive travel newsletters"])},
  "subscribe_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "subscribe_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "invalid_email_mess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input valid email address"])},
  "subscribe_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for subscribing!"])},
  "subscribe_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "print_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])}
}