<template>
  <div v-if="typeof postitem == 'object'">
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <PostBreadCrumbCmp :code="parentComponentData.code" :type="parentComponentData.type"></PostBreadCrumbCmp>
        </div>
      </div>
      <div class="row">
        <template v-for="(post, index) in postitem" :key="index">
          <div
            v-if="index == 0"
            class="section-default-list-album-item section-default-list-album-item-first col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-album-item-wrapper section-default-list-album-item-first-wrapper">
              <div class="row">
                <div
                  class="section-default-list-album-item-container col-xl-8 section-default-list-album-item-container-left"
                >
                  <div class="section-default-list-album-item-img-wrapper">
                    <div
                      class="section-default-list-album-item-img-container section-default-list-album-item-first-img-container image-wrapper" v-lazy-container="{ selector: 'img' }"
                    >
                      <img
                        :data-src="getImages(post.summary + post.content, post.images)"
                        class="section-default-list-album-item-img section-default-list-album-item-first-img"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="section-default-list-album-post-info-wrapper col-xl-4 section-default-list-album-item-container-right"
                >
                  <div class="section-default-list-album-post-info-container" :typeof="typeof(albumData[post.id])">
                    <div v-if="typeof(albumData[post.id])=='object' && (albumData[post.id]).length>0" class="section-default-list-album-post-info-container-subimg-list">
                      <div v-for="(pic, picindex) in albumData[post.id]" :key="picindex" class="section-default-list-album-item-other-img-wrapper">
                        <div class="section-default-list-album-item-other-img-container image-wrapper" v-lazy-container="{ selector: 'img' }">
                          <img v-if="(pic.images).length>0"
                            :data-src="getImages('', pic.images)"
                            class="section-default-list-album-item-img"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else class="section-default-list-album-post-info-container-subimg-list">
                      <div v-if="getImages(post.summary + post.content, '',2)" class="section-default-list-album-item-other-img-wrapper">
                        <div class="section-default-list-album-item-other-img-container image-wrapper" v-lazy-container="{ selector: 'img' }">
                          <img
                            :data-src="getImages(post.summary + post.content, '',2)"
                            class="section-default-list-album-item-img"
                          />
                        </div>
                      </div>
                      <div v-if="getImages(post.summary + post.content, '',3)" class="section-default-list-album-item-other-img-wrapper">
                        <div class="section-default-list-album-item-other-img-container image-wrapper" v-lazy-container="{ selector: 'img' }">
                          <img
                            :data-src="getImages(post.summary + post.content, '',3)"
                            class="section-default-list-album-item-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <a
                      class="section-default-list-album-post-info-title-link"
                      :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                    >
                      <h1
                        class="section-post-info-title section-default-list-album-post-info-title"
                      >
                        {{ displayEntity(shorten(post.title, 50)) }} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                      </h1>
                    </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="section-default-list-album-item section-default-list-album-item-second col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-album-item-wrapper section-default-list-album-item-second-wrapper">
              <div class="row">
                <div
                  class="section-default-list-album-item-container col-xl-8 section-default-list-album-item-container-left"
                >
                  <div class="section-default-list-album-item-img-wrapper">
                    <div
                      class="section-default-list-album-item-img-container section-default-list-album-item-second-img-container image-wrapper"
                    >
                      <img
                        :src="getImages(post.summary + post.content, post.images)"
                        class="section-default-list-album-item-img section-default-list-album-item-second-img"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="section-default-list-album-post-info-wrapper col-xl-4 section-default-list-album-item-container-right"
                >
                  <div class="section-default-list-album-post-info-container">
                    <div v-if="typeof(albumData[post.id])=='object' && (albumData[post.id]).length>0" class="section-default-list-album-post-info-container-subimg-list">
                      <div v-for="(pic, picindex) in albumData[post.id]" :key="picindex" class="section-default-list-album-item-other-img-wrapper">
                        <div class="section-default-list-album-item-other-img-container image-wrapper" v-lazy-container="{ selector: 'img' }">
                          <img v-if="(pic.images).length>0"
                            :data-src="getImages('', pic.images)"
                            class="section-default-list-album-item-img"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else class="section-default-list-album-post-info-container-subimg-list">
                      <div v-if="getImages(post.summary + post.content, '',2)" class="section-default-list-album-item-other-img-wrapper">
                        <div class="section-default-list-album-item-other-img-container image-wrapper" v-lazy-container="{ selector: 'img' }">
                          <img
                            :data-src="getImages(post.summary + post.content, '',2)"
                            class="section-default-list-album-item-img"
                          />
                        </div>
                      </div>
                      <div v-if="getImages(post.summary + post.content, '',3)" class="section-default-list-album-item-other-img-wrapper">
                        <div class="section-default-list-album-item-other-img-container image-wrapper" v-lazy-container="{ selector: 'img' }">
                          <img
                            :data-src="getImages(post.summary + post.content, '',3)"
                            class="section-default-list-album-item-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <a
                      class="section-default-list-album-post-info-title-link"
                      :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                    >
                      <h1
                        class="section-post-info-title section-default-list-album-post-info-title"
                      >
                        {{ displayEntity(shorten(post.title, 50)) }} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                      </h1>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

import PostBreadCrumbCmp from "./PostBreadCrumbV2Cmp";

export default {
  data() {
    return {
      isLoading: 1,
      postData: {},
      albumData: {},
      counter: this.$.vnode.key,
      perPage: parseInt(this.parentComponentData.info.perPage),
      totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  components: {
    PostBreadCrumbCmp
  },
  created(){
    this.doGetAlbum(this.postitem);
  },
  computed: {},
  onMounted() {},
  mounted() {},
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    },
    postitem() {
      this.doGetAlbum(this.postitem);
    }
  },
  methods: {
    async doGetAlbum(list){
      this.albumData = {};
      if(typeof(list) == 'object' && list.length>0){
        await Promise.allSettled(list.map((dataItem) =>
          PublicService.getaAlbumPictures(dataItem.id, {"limit":2,"offset":0}).then(response => {
            //returnData.push(response);
            this.albumData[dataItem.id] = response.data;
          })
        ));
      }
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl, pos) {
      return FunctionService.getImages(str, imgUrl, pos);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
  },
};
</script>

<style></style>
