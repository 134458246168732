<template>
  <section class="section-wrapper section-image-connect-wrapper" :style="sectionStyle">
    <!--<pre>{{ JSON.stringify(blockData[0], null, 2) }}</pre>-->
    <div class="container section-image-connect-container">
      <div class="row">
        <div class="col text-center">
          <a
            v-if="componentData.data.link.length > 0"
            class="section-image-connect-section-title-link"
            :href="componentData.data.link"
            :target="componentData.data.target == 0 ? '_self' : '_blank'"
          >
            <h1 class="section-wrapper-title section-image-connect-wrapper-title">
              {{ componentData.data.headline }}
            </h1>
          </a>
          <h1 v-else class="section-wrapper-title section-image-connect-wrapper-title">
            {{ componentData.data.headline }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div
          class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
        >
          <div>
            <div class="section-image-connect-slide">
              <div class="section-image-connect-slide-container">
                <div class="slider">
                  <div v-if="blockData[0]" id="section-image-connect-slider" class="section-image-connect-slider owl-carousel owl-theme">
                    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                    <template v-for="(post, index) in blockData[0]"
                    :key="index"
                    >
                        <div class="slider-card">
                            <div
                                class="d-flex justify-content-center align-items-center image-wrapper"
                            >
                                <a v-if="(typeof(post.link)=='string' && (post.link).length>0)" :href="post.link">
                                    <img :src="post.images" :alt="post.title" />
                                </a>
                                <img v-else :src="post.images" :alt="post.title" />
                            </div>
                        </div>
                    </template>
                    <!-- eslint-enable -->
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "owl.carousel";
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";

//import BannerItem from "./BannerItem1Cmp";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      posts: [],
      parentData: [],
      blockData: [],
    };
  },
  computed: {
    sectionStyle(){   
        let newStyle = {}
        if((this.componentData.data.bgImg).length>0){
          newStyle.backgroundImage = 'url('+this.componentData.data.bgImg+')'
          if((this.componentData.data.bgImgFixed).length>0 && this.componentData.data.bgImgFixed==1){
            newStyle.backgroundAttachment = 'fixed'
          }
        }
        else{
          if((this.componentData.data.bgColor).length>0){
            newStyle.backgroundColor = this.componentData.data.bgColor
          }
        }
        if(this.parentComponentData && typeof(this.parentComponentData.image)=='string' && (this.parentComponentData.image).length==0){
          newStyle.display = 'none';
        }
        return newStyle
    }
  },
  components: {
    //BannerItem
  },
  mounted() {
      this.initOwl();
  },
  methods: {
      getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
              vm.blockData.push(dt.data.child);
            }
            else{
              vm.blockData.push(dt.data);
            }
            /*
            console.log(cfgData.data.dataList[dtCt]);
            if(typeof(cfgData.data.dataList[dtCt])=='object' && typeof(cfgData.data.dataList[dtCt].dataHeadLine)=='string'){
                vm.blockTitle.push(
                cfgData.data.dataList[dtCt].dataHeadLine.length > 0
                    ? cfgData.data.dataList[dtCt].dataHeadLine
                    : ""
                );
            }
            dtCt++;
            */

           vm.$nextTick(function () {
               vm.initOwl();
            });

          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    isYoutube(url) {
      var rs = false;
      if (
        FunctionService.occurrences(url, "youtu.be") ||
        FunctionService.occurrences(url, "youtube")
      ) {
        rs = true;
      }
      return rs;
    },
    isVideo(videoStr) {
      var rs = false;
      if (videoStr.length>0) {
        rs = true;
      }
      return rs;
    },
    initOwl() {
      //console.log(owlCarousel);
      var owl = $("#section-image-connect-slider");
      if (owl.length > 0) {
        owl.owlCarousel({
          loop: true,
          autoHeight: false,
          autoplay: false,
          autoplayTimeout: 3000,
          autoplayHoverPause: true,
          dots: false,
          nav: true,
          navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>',
          ],
          //items: 2,
          lazyLoad: true,
          responsiveClass: true,
          margin: 20,
          responsive:{
              0:{
                  items:1,
              },
              600:{
                  items:2,
              },
              1000:{
                  items:6,
              }
          }
        });
      }
    },
    setContainerStyle: function (cfgData) {
      return FunctionService.setContainerStyle(cfgData);
    },
  },
  created() {
    //this.get();
    //this.getAPIDataByType(this.componentData);
    var vm = this;
    this.getBlockData(this.componentData, vm);
  },
};

</script>

<style>
</style>