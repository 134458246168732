<template>
    <section class="section-wrapper section-2-wrapper">
        <!--
        <pre>{{ JSON.stringify(componentData, null, 2) }}</pre>
        <pre>{{ JSON.stringify(blockData, null, 2) }}</pre>
        -->
        <!--
        <pre>{{ blockData}}</pre>
        <pre>{{ blockDataGrouped}}</pre>
        -->
        <div class="container section-2-container">
            <div class="row">
                <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                <template v-for="(posts, index1) in blockDataGrouped"
                :postitem="posts" :key="index1"
                >
                    <template v-if="Object.keys(posts).length==1">
                      <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 section-2-col-left">
                          <div v-if="(posts[0].content).length==0" class="section-2-content">
                            <a v-if="(posts[0].link).length>0" :href="posts[0].link" :target="(posts[0].target==0?'_self':'_blank')"><img :src="getImages('',posts[0].images)" /></a>
                            <img v-else :src="getImages('',posts[0].images)" />
                          </div>
                          <div v-else class="section-2-content">
                            <!--<div v-html="displayHtmlContent(post.content)"></div>-->
                            <div v-if="(posts[0].video).length>0" v-html="displayPostContent(posts[0].video)"></div>
                            <div v-else-if="(posts[0].content).length>0" v-html="displayPostContent(posts[0].content)"></div>
                          </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 section-2-col-left">
                        <div v-if="this.hasVideo==0" id="section-2-slide-col" class="col">
                          <b-carousel
                            :id="'section-2-slider'+index1"
                            v-model="slide"
                            :interval="5000"
                            controls
                            fade
                            background="#fff"
                            img-width=""
                            img-height=""
                            style=""
                          >
                            <!--<a :href="(post.link).length>0?post.link:'#'" :target="(posts[0].target==0?'_self':'_blank')">-->
                            <b-carousel-slide
                              v-for="(post, index) in posts"
                              :key="index"
                              caption=""
                              text=""
                              :img-src="getImages(post.summary+post.content, post.images)"
                              :class="index==0?'active':''"
                              :id="`section-1-carousel-item${post.id}`"
                              style=""
                              :title="displayEntity(post.title)"
                              :summary="post"
                              v-on:click="gotoLink(post.link, post.target)"
                            ></b-carousel-slide>
                            <!--</a>-->
                            <!--<ol class="carousel-indicators">
                              !-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes
                              <template v-for="(post, index) in posts"
                                :postitem="post" :key="index"
                                >
                                <li
                                  :class="(index==0)?'active':''"
                                  data-bs-target="#section-1-slider"
                                  :data-bs-slide-to="index"
                                ></li>
                              </template>
                              !-- eslint-enable --
                            </ol>-->
                          </b-carousel>
                        </div>
                        <!--<div v-else id="section-2-slide-col" class="col video-slide owl-list">-->
                        <div v-else :id="'section-2-slide-col'+index1" class="carousel slide" data-ride="carousel">
                          <!--
                          <ul class="carousel-indicators" v-for="(post, index) in posts"
                                :key="index">
                            <li :data-target="'#section-2-slide-col'+index1" :data-slide-to="index" :class="index==0?'active':''"></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                            <li data-target="#demo" data-slide-to="2"></li>
                          </ul>
                          -->
                          <div class="carousel-inner">
                            <div :class="'carousel-item'+(index==0?' active':'')" v-for="(post, index) in posts"
                                :key="index" style="position: relative;">
                                <!--<pre>{{ post}}</pre>-->
                              <div v-if="(post.video).length>0 && (post.link).length==0" v-html="displayPostContent(post.video)" class="section-2-slide-video" style=""></div>
                              <div v-else-if="(post.video).length>0 && (post.link).length>0" class="section-2-slide-video-link-wapper">
                                <a :href="post.link" :target="(post.target==0?'_self':'_blank')" class="section-2-slide-video-link"></a><div v-html="displayPostContent(post.video)" class="section-2-slide-video"></div>
                              </div>
                              <div v-else-if="(post.content).length>0" v-html="displayPostContent(post.content)"></div>
                              <div v-else :id="'section-2-slider'+index1" class="has-video-slider">
                                <a v-if="(post.link).length>0" :href="post.link" :target="(post.target==0?'_self':'_blank')"><img :src="getImages('',post.images)" class="d-block w-100"/></a>
                                <img v-else :src="getImages('',post.images)" class="d-block w-100"/>
                              </div>
                            </div>
                          </div>
                          <button class="carousel-control-prev" type="button" :data-bs-target="'#section-2-slide-col'+index1" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button class="carousel-control-next" type="button" :data-bs-target="'#section-2-slide-col'+index1" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </template>
                </template>
                <!-- eslint-enable -->
            </div>
        </div>
    </section>
</template>

<script>

import 'owl.carousel';
import PublicService from "../services/public.service";
import FunctionService from "../services/function.service";
//import BannerItem from "./BannerItemCmp";

import jQuery from "jquery";

const $ = jQuery;
window.$ = $;

export default {
  props: ["loggedIn", "componentData", "allData"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME,
      AppConst: this.$AppConst,
      parentData: [],
      parentBlockData: [],
      posts: [],
      blockData: [],
      blockDataGrouped: [],
      hasVideo: 0,
    };
  },
  created(){ 
    var vm = this;
    this.getBlockData(this.componentData, vm);
    /*
    if(typeof(this.blockData)=='object'){
      this.hasVideo = 1;
    }
    */
  },
  mounted(){
    this.initOwl();
    //alert(this.hasVideo);
  },
  computed: {
    
  },
  components: {
    //BannerItem
  },
  methods: {
    checkVideo(data){
      var rs = 0;
      data.forEach((dt)=>{
        if((dt.video).length>0){
          rs = 1;
          return rs;
        }
      });
      return rs;
    },
    checkHasVideo(data){
      var hasData = 0;
      if(typeof(data) == 'object'){
        data.forEach(postData => {
          //document.write('<pre>'+JSON.stringify(postData)+'</pre>');
          //document.write('<pre>'+JSON.stringify(postData.video)+'</pre>');
          if(typeof(postData.video)=='string' && (postData.video).length > 0){
            hasData = 1;
            return 1;
          }
        });
      }
      return hasData;
    },
    getBlockData(cfgData, vm) {
      var resp = PublicService.getAllBlockPublicDataByType(cfgData);
      /*
      console.log("********************************************");
      console.log("resp: ");
      console.log(resp);
      console.log(typeof resp);
      */
      //if(typeof(resp) == 'object' && Object.prototype.hasOwnProperty.call(resp, "data")){
      if (typeof resp == "object") {
        var allData = [];
        resp.then(function (data) {
          //var dtCt = 0;
          data.forEach((dt) => {
            //console.log(dt.data);
            allData.push(dt.data);
            if(typeof(dt.data.child)=='object'){
                vm.blockData.push(dt.data.child);
              }
              else{
                vm.blockData.push(dt.data);
              }
          });
          if(typeof(vm.blockData[0])=="object"){
            vm.groupBanner(vm.blockData[0], vm);
          }
          /*
          console.log("********************************************");
          console.log("blockData: ");
          console.log(vm.blockData);
          */
          vm.parentBlockData = typeof(vm.parentData.data)=='string'?JSON.parse(vm.parentData.data):[];
          vm.hasVideo = vm.checkHasVideo(vm.blockData[0]);
          //alert(vm.hasVideo);
          //document.write(vm.blockData);
          vm.$nextTick(function () {
            vm.initOwl();
          });
        });
        //console.log(this.blockData);
      }
      //console.log("********************************************");
    },
    groupBanner(data, vm){
      //var dataArr = [];
      var dataArr = {};
      if(typeof(data)=='object'){
        //alert(typeof(data));
        data.forEach((dt) => {
          //alert(typeof(dt));
          if(typeof(dt)=='object'){
            //alert(dt.order);
            //if(!Object.prototype.hasOwnProperty.call(dataArr, dt.order)){
            //if(dt.order in dataArr){
            if(typeof(dataArr[dt.order]) != "object"){
              dataArr[dt.order] = [];
            }
            dataArr[dt.order].push(dt);
            //dataArr[dt.order] = dt;
          }
        });
      }
      //document.write(dataArr);
      vm.blockDataGrouped = dataArr;
      //return(dataArr);
    },
    gotoLink (url, target) {
      if(url.length>0){
        if(target){
          window.open(url, '_blank');
        }
        else{
          window.location.href = url;
        }
      }
      else{
        return false;
      }
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function(str, imgUrl){
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function(str){
      return FunctionService.displayEntity(str);
    },
    shorten: function(str, maxLen, separator = ' '){
      return FunctionService.shorten(str, maxLen, separator);
    },
    genPostLink: function(type, id){
      return FunctionService.genPostLink(type, id);
    },
    initOwl() {

      this.hasVideo = this.checkHasVideo(this.blockData[0]);
      //alert(this.hasVideo);

      //console.log(owlCarousel);
      var owl = jQuery(".owl-list");
      if (owl.length > 0) {
        owl.owlCarousel({
          dots: true,
          items: 1,
          loop: true,
          //autoplay: true,
          //autoplayTimeout: 5000,
          //autoplayHoverPause: true,
          //slideSpeed: 300,
          video: true,
          videoWidth: false, // Default false; Type: Boolean/Number
          videoHeight: false, // Default false; Type: Boolean/Number
          nav: true,
          navText: [
            "<button type='button' class='btn btn-info btn-sm'>Previos</button>",
            "<button type='button' class='btn btn-info btn-sm'>Next</button>",
          ],
          animateOut: "fadeOut",
          animateIn: "fadeIn",
        });

        if(!this.hasVideo){
          //alert(this.parentBlockData.data.slideInterval)
          
          if(typeof(this.parentBlockData.data.slideInterval)=='string' && typeof(parseInt(this.parentBlockData.data.slideInterval)=='number')){
            owl.data('owl.carousel').options.autoplay = true;
            owl.data('owl.carousel').options.slideSpeed = true;
            owl.trigger( 'refresh.owl.carousel' );
          }
        }

        owl.on("changed.owl.carousel", function (event) {
          var item = event.item.index - 2; // Position of the current item
          jQuery("h1").removeClass("animated fadeInUp");
          jQuery(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h1")
            .addClass("animated fadeInUp");

          //console.log(event);
          //console.log(item);
          var current = event.item.index;
          /*
          console.log(
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length
          );
          */
          if (
            jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find(".video-item").length > 0
          ) {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }

            var currentIframe = jQuery(event.target)
              .find(".owl-item")
              .eq(current)
              .find("iframe");
            //console.log(currentIframe);
            if (currentIframe.length > 0) {
              var src = currentIframe.attr("src");
              console.log(src);
              currentIframe.attr("src", src + "&autoplay=1");
              currentIframe.addClass("playing-video");
            }
          } else {
            if (jQuery(".playing-video").length > 0) {
              jQuery(".playing-video").each(function () {
                var src = jQuery(this).attr("src");
                src = src.replace("&autoplay=1", "", src);
                //console.log(src);
                //console.log(index);
                jQuery(this).attr("src", src);
              });
            }
          }
        });
      }
    },
  },
};

/*
export default {
  props: ["loggedIn"],
  data() {
    return {
      brand: process.env.VUE_APP_APPNAME
    };
  },
  components: {
    BannerItem
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
*/
</script>

<style>
</style>