<template>
  <div v-if="typeof postitem == 'object'">
    <!--<pre>{{ JSON.stringify(componentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div class="search-content-wrapper">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="search-result-wrapper">
            <div class="loader-wrapper">
              <div class="loader-container">
                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
            </div>
            <div class="row">
              <template v-for="(post, index) in postitem" :key="index">
                <div
                  class="section-default-list-news-item col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12"
                >
                  <div class="section-default-list-news-item-wrapper">
                    <div class="section-default-list-news-item-container">
                      <div
                        class="section-default-list-news-item-inner"
                      >
                        <div
                          class="section-default-list-news-item-img-container image-wrapper" v-lazy-container="{ selector: 'img' }"
                        >
                          <img
                            :data-src="getImages(post.summary + post.content, post.images)"
                            class="section-default-list-news-item-img"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="section-default-list-news-post-info-wrapper">
                      <div class="section-default-list-news-post-info-container">
                        <a
                          class="section-default-list-news-post-info-title-link"
                          :href="genPostLink(post.type, post.id)" :alt="displayEntity(post.title)" :title="displayEntity(post.title)"
                        >
                          <h1 class="section-post-info-title section-default-list-news-post-info-title">
                            {{ displayShortenEntityContent(post.title, 70) }} <span v-if="post.type != 4 && post.type != 5 && post.type != 6 && (post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                          </h1>
                        </a>
                        <div>
                          <p class="section-default-list-news-post-info-excerpt">
                            {{ displayShortenEntityContent(post.summary, 150) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- eslint-enable -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";

export default {
  data() {
    return {
      AppConst: this.$AppConst,
      postData: {},
      postitem: [],
      search_type: typeof(this.$route.query.search_type)=='string' && (this.$route.query.search_type).length>0?this.$route.query.search_type:1,
      groupcode: typeof(this.$route.query.groupcode)=='string' && (this.$route.query.groupcode).length>0?this.$route.query.groupcode:"",
      searchText: typeof(this.$route.query.txtkey)=='string' && (this.$route.query.txtkey).length>0?this.$route.query.txtkey:"",
      counter: this.$.vnode.key,
      perPage: typeof(this.componentData)=='object' && Object.prototype.hasOwnProperty.call(this.componentData, "data") && parseInt(this.componentData.data.listLimit)?parseInt(this.componentData.data.listLimit):15,
      totalItem: 0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
      isLoading: 0,
      callType: 1,
    };
  },
  props: ["pageType", "parentComponentData", "componentData"],
  created(){
    //alert(this.$route.query.txtkey);
    if(typeof(this.$route.query.txtkey)=='string' && (this.$route.query.txtkey).length>0){
      this.searchText = this.$route.query.txtkey;
    }
    this.searchPost();
  },
  computed: {},
  onMounted() {},
  mounted() {},
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    },
    isLoading(state){
      if(document.getElementsByClassName('loader-wrapper').length>0){
        if(state==1){
          document.getElementsByClassName('loader-wrapper')[0].style.visibility = 'visible';
        }
        else{
          document.getElementsByClassName('loader-wrapper')[0].style.visibility = 'hidden';
        }
      }
    },
  },
  methods: {
    searchPost(){
      var param = {'limit': this.perPage, 'offset': this.currentPage, 'callType': this.callType};
      //var param = {'limit': this.perPage, 'offset': this.currentPage};
      console.log(param);
      this.isLoading = 1;
      PublicService.getNewestResult(param).then(
                (response) => {
                  //console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    if (Object.prototype.hasOwnProperty.call(response.data, "child")) {
                      this.postitem = response.data.child;
                      this.totalItem = parseInt(response.data.info.total);
                      this.isLoading = 0;
                    }
                    else{
                      this.postitem = [];
                      this.totalItem = 0;
                      this.isLoading = 0;
                    }
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    submit(){
      //this.$router.push("/search?txtkey="+this.searchText); 
      var param = {'search_type': this.search_type, 'groupcode': this.groupcode, 'txtkey': this.searchText};
      var paramStr = this.buildParam(param);
      console.log('paramStr:');
      console.log(paramStr);
      this.$router.push((localStorage.getItem('lang')  && localStorage.getItem('lang')!=this.AppConst.PAGE_LANG? '/' + localStorage.getItem('lang') : "")+"/search?"+paramStr); 
      this.searchPost();
    },
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
       this.currentPage = page;
       this.searchPost();
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    buildParam: function (param) {
      return FunctionService.buildParam(param);
    },
  },
};
</script>

<style></style>
