<template>
  <div v-if="typeof(postitem) == 'object' && Object.keys(postitem).length>0 && typeof(mediaPost) == 'object' && mediaPost.length>0">
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <!--<pre>{{ JSON.stringify(mediaPost, null, 2) }}</pre>-->
    <div class="post-media-section">
      <div v-if="typeof(mediaPost) == 'object' && mediaPost.length>=0" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content-media-wrapper post-media-content-wrapper">
                 <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
                <template v-for="(post, index) in mediaPost" :key="index">
                  <div class="post-media-item">
                    <div v-if="post.type == 0">
                      <div v-if="post.hasOwnProperty('mainresource') && typeof(post.mainresource) == 'string' && (post.mainresource).length>0 && ((post.audio).length==0 || (post.audio).length>0)">
                        <audio controls controlsList="nodownload">
                          <source v-if="(post.mainresource).indexOf('.ogg') !== -1" :src="getAudio(post.mainresource)" type="audio/ogg">
                          <source v-if="(post.mainresource).indexOf('.mp3') !== -1" :src="getAudio(post.mainresource)" type="audio/mpeg">
                        Your browser does not support the audio element.
                        </audio>
                      </div>
                      <div v-else-if="(post.audio).length>0">
                        <audio controls controlsList="nodownload">
                          <source v-if="(post.audio).indexOf('.ogg') !== -1" :src="getAudio(post.audio)" type="audio/ogg">
                          <source v-if="(post.audio).indexOf('.mp3') !== -1" :src="getAudio(post.audio)" type="audio/mpeg">
                        Your browser does not support the audio element.
                        </audio>
                      </div>
                    </div>
                    <!--<a class="post-media-item-link" :href="genPostLink(post.type, post.id)" :title="displayEntity(post.title)">- {{displayEntity(post.title)}} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span></a>-->
                  </div>
                </template>
            </div>
        </div>
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>

<script>
import PublicService from "../../services/public.service";
import FunctionService from "../../services/function.service";



export default {
  data() {
    return {
      mediaPost: [],
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  async created(){
    //var param = {'type':this.postitem.type};
    var param = {'id':this.postitem.id};
    this.getMediaPostByExtID(param);
  },
  computed: {},
  onMounted() {
    
  },
  mounted() {},
  watch:{
 
  },
  methods: {
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    getMediaPostByExtID(param) {
      PublicService.getMediaPostByExtID(param).then(
                (response) => {
                  //alert(response);
                  console.log(response);
                  if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    this.mediaPost = response.data;
                  }
                },
                (error) => {
                  this.content =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    displayPostContent: function (data) {
      return FunctionService.displayPostContent(data);
    },
    displayPostTags: function (tags, tagsView) {
      return FunctionService.displayPostTags(tags, tagsView);
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    getAudio: function (audioUrl) {
      return FunctionService.getAudio(audioUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
  },
};
</script>

<style></style>
