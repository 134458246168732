<template>
  <div v-if="typeof postitem == 'object'">
    <!--<pre>{{ JSON.stringify(parentComponentData, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!--<pre>{{ JSON.stringify(postitem, null, 2) }}</pre>-->
    <!-- eslint-disable vue/no-v-for-template-key, vue/no-useless-template-attributes -->
    <div class="post-list-item-v2">
      <div class="row">
        <template v-for="(post, index) in postitem" :key="index">
          <div
            v-if="index == 0"
            class="section-default-list-news-item section-default-list-news-item-first col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-news-item-wrapper row">
              <div class="section-default-list-news-item-container col section-default-list-news-item-container-left">
                <div
                  class="section-default-list-news-item-img-wrapper"
                >
                  <div
                    class="section-default-list-news-item-img-container section-default-list-news-item-first-img-container image-wrapper" v-lazy-container="{ selector: 'img' }"
                  >
                    <img
                      :data-src="getImages(post.summary + post.content, post.images)"
                      :class="imageClass('section-default-list-news-item-img section-default-list-news-item-first-img', post.imagesRatio)"
                    />
                  </div>
                </div>
              </div>
              <div class="section-default-list-news-post-info-wrapper col-xl-3 section-default-list-news-item-container-right">
                <div class="section-default-list-news-post-info-container">
                  <a
                    class="section-default-list-news-post-info-title-link"
                    :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                  >
                    <h1 class="section-post-info-title section-default-list-news-post-info-title">
                      {{ displayEntity(shorten(post.title, 50)) }} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                    </h1>
                  </a>
                  <div>
                    <p class="section-default-list-news-post-info-excerpt">
                      {{ displayShortenEntityContent(post.summary, 220) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="section-default-list-news-item section-default-list-news-item-second col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12"
          >
            <div class="section-default-list-news-item-wrapper">
              <div class="section-default-list-news-item-container">
                <div
                  class="section-default-list-news-item-inner section-default-list-news-item-first"
                >
                  <div
                    class="section-default-list-news-item-img-container section-default-list-news-item-second-img-container image-wrapper" v-lazy-container="{ selector: 'img' }"
                  >
                    <img
                      :data-src="getImages(post.summary + post.content, post.images)"
                      :class="imageClass('section-default-list-news-item-img section-default-list-news-item-second-img', post.imagesRatio)"
                    />
                  </div>
                </div>
              </div>
              <div class="section-default-list-news-post-info-wrapper">
                <div class="section-default-list-news-post-info-container">
                  <a
                    class="section-default-list-news-post-info-title-link"
                    :href="genPostLink(pageType, post.id)" :title="displayEntity(post.title)"
                  >
                    <h1 class="section-post-info-title section-default-list-news-post-info-title">
                      {{ displayEntity(shorten(post.title, 70)) }} <span v-if="(post.publishup).length>0 && typeof(parseInt(post.publishup))=='number'" class="post-publish-date">({{formatTime(post.publishup)}})</span>
                    </h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- eslint-enable -->
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="typeof(totalItem)=='number' && Math.ceil(totalItem/perPage)>1" class="content-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              @page-click="handlePageChange"
            ></b-pagination>
            <!--<b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import PublicService from "../services/public.service";
import FunctionService from "../../services/function.service";

export default {
  data() {
    return {
      postData: {},
      counter: this.$.vnode.key,
      perPage: parseInt(this.parentComponentData.info.perPage),
      totalItem: typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0,
      currentPage: isNaN(this.$route.query.page)?1:parseInt(this.$route.query.page),
    };
  },
  props: ["postitem", "pageType", "parentComponentData"],
  created(){
    
  },
  computed: {},
  onMounted() {},
  mounted() {},
  watch:{
    parentComponentData(){
      this.totalItem = typeof(this.parentComponentData.info.total)=='string'?parseInt(this.parentComponentData.info.total):0;
    }
  },
  methods: {
    handlePageChange(event, page){
      console.log(page);
      var param = this.$router.currentRoute.value.query;
      var paramStr = '';
      if(Object.keys(param).length>0){
        if(typeof(param.page)=='string'){
          delete param['page'];
        }
        paramStr = FunctionService.buildParam(param);
        if(paramStr.length>0){
          paramStr = '&'+paramStr;
        }
      }
      this.$router.push("?page="+page+paramStr);
      //this.$router.go(this.$router.currentRoute)
       this.$emit('content-page-clicked', page);
    },
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    removeHTML: function (data) {
      return FunctionService.removeHTML(data);
    },
    displayHtmlContent: function (data) {
      return FunctionService.displayHtmlContent(data);
    },
    getImages: function (str, imgUrl) {
      return FunctionService.getImages(str, imgUrl);
    },
    displayEntity: function (str) {
      return FunctionService.displayEntity(str);
    },
    shorten: function (str, maxLen, separator = " ") {
      return FunctionService.shorten(str, maxLen, separator);
    },
    paddZero: function (number) {
      return FunctionService.paddZero(number);
    },
    postTypeRoute: function (type) {
      return FunctionService.postTypeRoute(type);
    },
    genPostLink: function (type, id) {
      return FunctionService.genPostLink(type, id);
    },
    displayShortenEntityContent: function (content, limit) {
      return FunctionService.displayShortenEntityContent(content, limit);
    },
    formatTime: function (time, locale) {
      return FunctionService.formatTime(time, locale);
    },
    imageClass(cClass, ratio){
      var rClass = cClass;
      if(!isNaN(ratio) && ratio!=null){
        if(ratio>=1.2){
          rClass += ' img-transition-1';
        }
        else if(ratio>=1.5){
          rClass += ' img-transition-0-5';
        }
        else if(ratio>=1 && ratio<1.2){
          rClass += ' img-transition-0-5';
        }
        else if(ratio>=0.5 && ratio<1){
          rClass += ' img-transition-2';
        }
        else if(ratio<0.5){
          rClass += ' img-transition-3';
        }
      }
      else{
        rClass += ' img-transition-0-5';
      }
      return rClass;
    }
  },
};
</script>

<style></style>
